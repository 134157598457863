import React from 'react'

const Avatar = () => {
  return (
    <svg
      enableBackground="new 0 0 156 156"
      viewBox="0 0 156 156"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m16.5 125.9c-10.4-13.2-16.5-29.9-16.5-47.9 0-43.1 34.9-78 78-78s78 34.9 78 78c0 18-6.1 34.7-16.5 47.9z"
        fill="#1356a8"
      />
      <path
        d="m139.5 125.9c-14.3 18.4-36.6 30.1-61.5 30.1s-47.2-11.7-61.5-30.1c18.4-9.8 39.2-15.4 61.5-15.4s43.1 5.6 61.5 15.4z"
        fill="#ddd"
      />
      <path
        d="m45.5 78c0 26.4 14.5 47.7 32.5 47.7v-95.4c-18 0-32.5 21.3-32.5 47.7z"
        fill="#c60c31"
      />
      <path
        d="m110.5 78c0-26.4-14.5-47.7-32.5-47.7v95.3c18 .1 32.5-21.2 32.5-47.6z"
        fill="#fafafa"
      />
    </svg>
  )
}

export default Avatar
