import * as types from './constants'

const initialState = {
  message: null,
  status: null,
}

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_REQUEST_ERROR:
      return action
    case types.HIDE_REQUEST_ERROR:
      return initialState
    default:
      return state
  }
}
