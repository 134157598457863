import classNames from 'classnames'
import React, { Component } from 'react'

export interface FieldProps {
  className?: string
  htmlFor?: string
  label?: string
  labelClassName?: string
}

export type FieldState = any

export default class Field extends Component<FieldProps, FieldState> {
  render() {
    const { children, label, htmlFor, labelClassName, className, ...props } =
      this.props
    return (
      <div className={classNames(`field`, className)} {...props}>
        {children}
      </div>
    )
  }
}
