import React, { Component, Fragment, SyntheticEvent } from 'react'
import Combokeys from 'combokeys'
import { v4 as uuidv4 } from 'uuid';
import config from '../../../../config'
import style from './OcrMultiple.module.scss'
import './style.scss'
import Input from '../../../Form/Input'
import Effects from '../../../Effects'
import Field from '../../../Form/field'
import ButtonSubmit from '../../../Button/ButtonSubmit'
import { isStudioMode } from 'env'
import { RecognizeStatsItem } from 'components/BboxedImage/BboxImage'
import Form from 'components/Form'
import FormWrapper from 'components/Form/FormWrapper/FormWrapper'
import CropManager from 'components/CropManager/CropManager'
import { TableComplex } from './TableComplex/TableComplex'


const bodyClass = ['ocr-multiple-bad']

const cellNamePattern = /^table_id-\d+_row-\d+_col-\d+$/;

const sortByPageNum = (fields: RecognizeStatsItem[]): RecognizeStatsItem[] => {
  if (!fields.some((field) => field.page_num)) return fields

  const fieldsMap = {};
  fields.forEach((field) => {
    if (!fieldsMap[field.page_num]) {
      fieldsMap[field.page_num] = [field];
    } else {
      fieldsMap[field.page_num].push(field);
    }
  });
  const fieldsByPage = [];
  Object.keys(fieldsMap).forEach((key) => (fieldsByPage[Number(key) - 1] = fieldsMap[key]));

  return fieldsByPage.flat()
}
export interface OcrMultipleProps {
  tasks: any
  task: any
  t: any
  markTask: any
  skipTask: any
  testLabel?: React.ReactNode
  fullDescriptionPopup?: React.ReactNode
  caption?: React.ReactNode
  description?: React.ReactNode
  resolvedTasks: any[]
}

export interface ITaskResultValues {
  [label: string]: {
    value: string
    edited?: boolean
  }
}
interface OcrMultipleState {
  hoveredItemName: null | string,
  taskResultValues: ITaskResultValues,
  txtareaError: boolean,
  isTitleHidden: boolean,
  currentTime: number,
  fields: RecognizeStatsItem[]
  tables: RecognizeStatsItem[]
}


class OcrMultiple extends Component<OcrMultipleProps, any> {
  combokeys: Combokeys.Combokeys
  form: Component<HTMLFormElement>

  constructor(props) {
    super(props)

    this.state = {
      hoveredItemName: null,
      taskResultValues: {},
      txtareaError: false,
      isTitleHidden: true,
      currentTime: 0,
      taskInputTextParsed: {},
      fields: [],
      tables: []
    }

    this.combokeys = new Combokeys(document.documentElement)
  }

  componentDidMount() {
    document.body.classList.add(...bodyClass)
    this.setComboKeys()
    this.setInitialData()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.task.id !== this.props.task.id) {
      this.setInitialData()

      this.setState({ value: nextProps.task.userData || '' })

      const textarea: HTMLTextAreaElement | null = document.querySelector(
        '.actions-inner textarea',
      )

      if (textarea) {
        textarea.focus()
      }
    }

    if (nextProps.task.project_id !== this.props.task.project_id) {
      this.setState({ isTitleHidden: false })
    }
  }

  componentDidUpdate(prevProps) {
    const { id: prevId } = prevProps.task
    const { id: nextId } = this.props.task

    if (nextId !== prevId) {
      if (this.props.resolvedTasks.length >= 3 && !this.state.isTitleHidden) {
        this.toggleTitle(true)
      }
      this.setInitialData()
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(...bodyClass)
    this.combokeys.detach()
  }

  setComboKeys() {
    const { rotate } = config.hotKeys

    this.combokeys.bind('enter', () => {
      this.handleSubmitForm()
    })

    this.combokeys.bind('n', () => {
      this.handleNoText()
    })

    this.combokeys.bind('s', () => {
      this.handleSkip()
    })
  }

  setInitialData() {
    const taskInputTextParsed = JSON.parse(this.props.task.input.text)
    for (const key in taskInputTextParsed) {
      if (taskInputTextParsed.hasOwnProperty(key)) {
        taskInputTextParsed[key] = { ...taskInputTextParsed[key], id: uuidv4() }
      }
    }
    const rawValues = Object.values(taskInputTextParsed) as RecognizeStatsItem[];
    const taskResultValues = {}

    rawValues.forEach((v) => {
      taskResultValues[v.label] = { value: v.value }
    })

    const { fields, tables } = rawValues.reduce((result, item) => {
      if (cellNamePattern.test(item?.label)) {
        result.tables.push(item)
      } else {
        result.fields.push(item)
      }
      return result
    }, { fields: [], tables: [] });

    this.setState({
      taskResultValues,
      fields: sortByPageNum(fields),
      tables: sortByPageNum(tables)
    })
  }

  handleKeyDown(event) {
    if (event.which === 13) {
      event.preventDefault()
      this.handleSubmitForm()
    }
  }

  handleChange(event, label, isEdited = true) {
    const { value } = event.target
    const { taskResultValues } = this.state

    taskResultValues[label].value = value
    taskResultValues[label].edited = isEdited

    this.setState({ taskResultValues })
  }

  handleSubmitForm(event?: SyntheticEvent) {
    if (event) {
      event.preventDefault()
    }
    const payload = this.state.taskResultValues
    const payloadKeys = Object.keys(payload)
    const notEmptyKeys: any[] = []

    payloadKeys.forEach((key) => {
      if (payload[key] !== '') {
        notEmptyKeys.push(key)
      }
    })

    if (!notEmptyKeys.length) {
      this.setState({ txtareaError: true })
      return
    }

    this.handleMark(payload)
  }

  handleMark(payload) {
    this.props.markTask(payload, payload)
  }

  handleNoText() {
    const payload = this.state.taskResultValues
    const payloadKeys = Object.keys(payload)
    const notEmptyKeys: any[] = []

    payloadKeys.forEach((key) => {
      if (payload[key] !== '') {
        notEmptyKeys.push(key)
      }
    })

    if (notEmptyKeys.length) {
      this.setState({ txtareaError: true })
      return
    }

    this.handleMark(payload)
  }

  handleSkip() {
    this.props.skipTask()
  }

  toggleTitle(isTitleHidden) {
    this.setState({ isTitleHidden })
  }

  generateTitle() {
    const { caption, description } = this.props
    const { isTitleHidden } = this.state

    if (isTitleHidden) {
      return (
        <div className="title title__hidden">
          <div className="title-line" />
          <span
            className="title-toggler down"
            onClick={() => {
              this.toggleTitle(false)
            }}
          />
        </div>
      )
    }

    return (
      <div className="title">
        {caption}
        {description}
        <span
          className="title-toggler up"
          onClick={() => {
            this.toggleTitle(true)
          }}
        />
      </div>
    )
  }

  render() {
    const { t, tasks, testLabel, fullDescriptionPopup } = this.props
    const { value } = this.state
    const title = this.generateTitle()
    const { currentTime } = this.state

    const skipButton =
      tasks.onboarding_status || isStudioMode ? (
        ''
      ) : (
        <span
          role="button"
          tabIndex={0}
          className="skip"
          onClick={() => this.handleSkip()}
        >
          {t('task.button.skip')}
        </span>
      )

    const { task } = this.props


    const onHover = (hoveredItemName: string | null) => {
      this.setState({ hoveredItemName })
    }


    return (
      <Fragment>
        <div className="annotation row">
          <div className="image-wrapper">
            <div className="image col-4 col-sm-4 col-xs-2">
              <div className="image-value ocr">
                {this.state.fields.length && (
                  <CropManager
                    taskResultValues={this.state.taskResultValues}
                    hoveredItemName={this.state.hoveredItemName}
                    crop={this.props.task.input.source[0]}
                    items={this.state.fields.filter((item) => item.coords.length > 0)}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="actions-wrapper">
            <div className="actions-outer">
              <div className="actions col-2" >
                <div className="actions-inner">
                  <FormWrapper>
                    <Form
                      className="textarea-form"
                      onSubmit={this.handleSubmitForm.bind(this)}
                      ref={(c) => {
                        this.form = c
                      }}
                    >
                      <div className={style.fieldsContainer}>
                        <Field className="textarea-wrapper">
                          <Effects
                            transition="shakeShort"
                            transitionFinish={() => {
                              this.setState({
                                txtareaError: false,
                              })
                            }}
                            init={this.state.txtareaError}
                          >
                            {this.state.fields.map((v: any, num) => (
                              <Input
                                id={v.id}
                                onHover={onHover}
                                key={v.label}
                                autoFocus={num === 0}
                                name={v.label}
                                isLowConfidence={v.confidence_is_low}
                                type={
                                  v.label === 'date_of_registration'
                                    ? 'date'
                                    : 'text'
                                }
                                label={v.label}
                                value={this.state.taskResultValues[v.label].value}
                                page_num={v.page_num}
                                onChange={(e) => {
                                  this.handleChange(e, v.label)
                                }}
                                onKeyDown={this.handleKeyDown.bind(this)}
                              />
                            ))}
                            <div>
                              {this.state.tables.length ?
                                <TableComplex
                                  buttonLabel={t('task.table')}
                                  valuesToEdit={this.state.taskResultValues}
                                  handleChange={(e, label, isEdited) => {
                                    this.handleChange(e, label, isEdited)
                                  }}
                                  onHover={onHover}
                                  hoveredItemName={this.state.hoveredItemName}
                                  crop={this.props.task.input.source[0]}
                                  items={this.state.tables.filter((item) => item.coords.length > 0)} /> : null
                              }
                            </div>
                          </Effects>
                        </Field>
                      </div>
                      <Field className="buttons field-buttons">
                        <ButtonSubmit className="btn">
                          {t('task.button.complete')}
                        </ButtonSubmit>
                        <div className="second-actions">
                          <span
                            role="button"
                            tabIndex={0}
                            className="skip"
                            onClick={(e) => {
                              e.preventDefault()
                              this.handleNoText()
                            }}
                          >
                            {t('task.button.no_text')}
                          </span>
                          {skipButton}
                        </div>
                      </Field>
                    </Form>
                  </FormWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
        {fullDescriptionPopup}
      </Fragment >
    )
  }
}

export default OcrMultiple
