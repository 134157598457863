export const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST'
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS'
export const GET_TASKS_ERROR = 'GET_TASKS_ERROR'
export const RESET_TASKS = 'RESET_TASKS'
export const MARK_TASK_REQUEST = 'MARK_TASK_REQUEST'
export const MARK_TASK_SUCCESS = 'MARK_TASK_SUCCESS'
export const MARK_TASK_ERROR = 'MARK_TASK_ERROR'
export const FAIL_LOAD_TASK = 'FAIL_LOAD_TASK'
export const LOAD_PREV_TASK = 'LOAD_PREV_TASK'
export const POINTS_NORMALIZER_REQUEST = 'POINTS_NORMALIZER_REQUEST'
export const POINTS_NORMALIZER_FAILED = 'POINTS_NORMALIZER_FAILED'
export const POINTS_NORMALIZER_SUCCESS = 'POINTS_NORMALIZER_SUCCESS'
