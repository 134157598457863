import * as types from './constants'
import * as api from './api'

const initialState = {
  isAuth: api.isLoggedIn(),
  activeTabName: null, // config.authorizationTabs - login|signup|recovery
  login: {
    isLoginRequest: false,
    error: {},
    status: null,
  },
  signup: {
    isSignupRequest: false,
    error: {},
    status: null,
  },
  createPassword: {
    error: {},
  },
  recovery: {
    isRecoveryRequest: false,
    ismEmailSended: false,
    email: null,
    error: {},
  },
}

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_ME:
      return { ...initialState, isAuth: false }

    case types.SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.isAuth,
        login: initialState.login,
        signup: initialState.signup,
        activeTabName: initialState.activeTabName,
      }

    case types.SIGNUP_REQUEST:
      return {
        ...state,
        signup: { ...initialState.signup, isSignupRequest: true },
      }

    case types.SIGNUP_SUCCESS:
      return {
        ...state,
        signup: { ...initialState.signup },
      }

    case types.SIGNUP_ERROR:
      return {
        ...state,
        signup: {
          error: action.error,
          status: action.status,
          isSignupRequest: false,
        },
      }

    case types.CREATE_PASSWORD_ERROR:
      return {
        ...state,
        createPassword: { ...state.createPassword, error: action.error },
      }

    case types.RECOVERY_REQUEST:
      return {
        ...state,
        recovery: {
          ...state.recovery,
          isRecoveryRequest: true,
        },
      }

    case types.RECOVERY_SUCCESS:
      return {
        ...state,
        recovery: {
          ...state.recovery,
          ismEmailSended: true,
          email: action.email,
          isRecoveryRequest: false,
        },
      }

    case types.RECOVERY_ERROR:
      return {
        ...state,
        recovery: {
          ...state.recovery,
          error: action.error,
          isRecoveryRequest: false,
        },
      }

    case types.LOGIN_REQUEST:
      return {
        ...state,
        login: { ...initialState.login, isLoginRequest: true },
      }

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: action.isAuth,
        login: initialState.login,
        signup: initialState.signup,
        activeTabName: initialState.activeTabName,
      }

    case types.LOGIN_ERROR:
      return {
        ...state,
        login: {
          error: action.error,
          status: action.status,
          isLoginRequest: false,
        },
      }

    case types.CLEAR_LOGIN_ERROR:
      return {
        ...state,
        login: { ...initialState.login },
      }

    case types.SET_ACTIVE_TAB_NAME:
      return {
        ...state,
        activeTabName: action.name,
      }

    case types.CLEAR_ACTIVE_TAB_NAME:
      return {
        ...state,
        activeTabName: initialState.activeTabName,
      }

    default:
      return state
  }
}
