const config = {
  api: {
    web: (() => {
      switch (process.env.APP_ENV) {
        case 'staging':
          return 'https://handl.handl.dbrain.io/api/v1/'
        case 'local':
          return 'https://handl.handl.dbrain.io/api/v1/'
        default:
          return (
            process.env.REACT_APP_PRODUCTION_API ||
            'https://handl.handl.dbrain.io/api/v1'
          )
      }
    })(),
    ico: 'https://ico.api.dbrainio.com/api/v1',

    // endpoint to normalize tasks points
    points: 'https://9jbmz5boxe.execute-api.eu-west-1.amazonaws.com',
  },
  copy: 'Icon8 Inc.',
  outer: {
    landing: '//handl.ai',
    demo: '//try.handl.ai',
  },
  routes: {
    default: '/',
    mocks: {
      aabb: '/mock/aabb',
      bbox_extended: '/mock/bbox_extended',
      label: '/mock/label',
      ocr: '/mock/ocr',
      image_compare: '/mock/image_compare',
      ocr_multiple: '/mock/ocr_multiple',
      segmentation: '/mock/segmentation',
      multilabel: '/mock/multilabel',
      multilabel_group: '/mock/multilabel_group',
      image_labeling_multiple: '/mock/image_labeling_multiple',
      text_label: '/mock/text_label',
      text_categorization: '/mock/text_categorization',
      text_categorization_group: '/mock/text_categorization_group',
    },

    label: '/label',
    log: '/recent_history',
    profile: '/profile',
    signup: '/signup',
    login: '/login',
    recovery: '/recovery',
    createPassword: '/reset_password',
    terms: '/terms',
    faq: '/faq',
    about: '/about',
    contacts: '/contacts',
    welcome: '/welcome',
    score: '/score',
    notasks: '/notasks',
    error: '/error',
    verification: '/verification',
  },
  emails: {
    help: 'help@handl.ai',
    hello: 'hello@handl.ai',
  },
  links: {
    telegram: {
      bot: 'https://t.me/dbrainbot',
      chat: {
        ru: 'https://t.me/handlchatru',
        en: 'http://t.me/dbrainchat',
      },
    },
    social: {
      telegram: '//t.me/joinchat/BSBzPk1gyw5grXyvBslXdg',
      facebook: '//fb.me/handldata',
      twitter: '//twitter.com/handldata',
      blog: '//blog.handl.ai',
    },
    TOS: 'https://s3-eu-west-1.amazonaws.com/dbrain-public/ToS/Dbrain_ToU_181219_clean.pdf',
  },
  reCAPTCHA: '6LfE-EAUAAAAAMAS_JEWVz7YeJeO2ksEjL9ws9my',
  languages: ['en', 'ru'],
  defaultLang: 'en',
  raven: {
    dsn: 'https://8e84021f0e4b4c60bede31085181e015@sentry.io/1186445',
  },
  authorizationTabs: {
    login: 'login',
    signup: 'signup',
    recovery: 'recovery',
  },

  hotKeys: {
    labels: {
      //  Hotkey bindings
      key: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      // Generating of buttons
      view: [
        {
          type: 'button',
          text: {
            osx: '1',
            win: '1',
          },
          size: '1',
        },
        {
          type: 'text',
          text: 'mdash',
        },
        {
          type: 'button',
          text: {
            osx: '9',
            win: '9',
          },
          size: '1',
        },
      ],
      // Key of locale json file
      value: 'labels',
    },
    complete: {
      key: 'space',
      view: [
        {
          type: 'button',
          text: {
            osx: 'space',
            win: 'Space',
          },
          size: '4',
        },
      ],
      value: 'complete',
    },
    no_objects: {
      key: 'n',
      view: [
        {
          type: 'button',
          text: {
            osx: 'N',
            win: 'N',
          },
          size: '1',
        },
      ],
      value: 'no_objects',
    },
    skip: {
      key: 's',
      view: [
        {
          type: 'button',
          text: {
            osx: 'S',
            win: 'S',
          },
          size: '1',
        },
      ],
      value: 'skip',
    },
    fullscreen: {
      key: 'f',
      view: [
        {
          type: 'button',
          text: {
            osx: 'F',
            win: 'F',
          },
          size: '1',
        },
      ],
      value: 'fullscreen',
    },
    rotate: {
      key: 'r',
      view: [
        {
          type: 'button',
          text: {
            osx: 'R',
            win: 'R',
          },
          size: '1',
        },
      ],
      value: 'rotate',
    },
    undo: {
      key: {
        osx: 'cmd+z',
        win: 'ctrl+z',
      },
      view: [
        {
          type: 'button',
          text: {
            osx: 'cmd',
            win: 'Ctrl',
          },
          size: '3',
        },
        {
          type: 'button',
          text: {
            osx: 'Z',
            win: 'Z',
          },
          size: '1',
        },
      ],
      value: 'undo',
    },
    copy_bbox: {
      key: 'alt',
      view: [
        {
          type: 'button',
          text: {
            osx: 'option',
            win: 'Alt',
          },
          size: '3',
        },
        {
          type: 'text',
          text: 'text_drag',
        },
      ],
      value: 'copy_bbox',
    },
    instruction: {
      key: 'i',
      view: [
        {
          type: 'button',
          text: {
            osx: 'I',
            win: 'I',
          },
          size: '1',
        },
      ],
      value: 'instruction',
    },
    guide: {
      key: 'g',
      view: [
        {
          type: 'button',
          text: {
            osx: 'G',
            win: 'G',
          },
          size: '1',
        },
      ],
      value: 'guide',
    },
  },
}

export default config
