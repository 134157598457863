export const parseRequestError = (error) => {
  const e = {
    message: 'Unknown error',
    status: null,
    id: null,
  }

  if (error && error.response) {
    e.message = error.response.data.message || error.response.data.error
    e.id = error.response.data.id || e.id
    e.status = error.response.status || e.status
  }

  return { ...e }
}

export const err2str = (error) =>
  error.response
    ? error.response.data.message || error.response.data.error
    : error.toString()

export const offset = (el: Element) => {
  const rect = el.getBoundingClientRect()
  const scrollLeft =
    window.pageXOffset || document.documentElement.scrollLeft || 0
  const scrollTop =
    window.pageYOffset || document.documentElement.scrollTop || 0
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export const idx2color = (idx) => {
  const strokeOpacity = 1
  const fillOpacity = 0.25

  return [
    '255, 48, 63', // #FF303F
    '19, 158, 239', // #139EEF
    '249, 144, 24', // #F99018
    '18, 237, 186', // #12EDBA
    '255, 68, 192', // #FF44C0
    '33, 47, 255', // #212FFF
    '252, 204, 32', // #FCCC20
    '188, 25, 252', // #BC19FC
    '224, 74, 2', // #E04A02
    '20, 232, 20', // #14E814
    '251, 171, 132', // #FBAB84
    '2, 197, 224', // #02C5E0
    '181, 0, 13', // #B5000D
    '90, 19, 91', // #5A135B
    '229, 107, 111', // #E56B6F
    '46, 196, 182', // #2EC4B6
    '255, 99, 146', // #FF6392
    '58, 134, 255', // #3A86FF
    '215, 122, 97', // #D77A61
    '175, 252, 65', // #AFFC41
    '246, 148, 193', // #F694C1
    '109, 157, 197', // #6D9DC5
    '246, 189, 96', // #F6BD60
    '228, 193, 249', // #E4C1F9
    '243, 114, 44', // #F3722C
    '96, 211, 148', // #60D394
    '213, 185, 178', // #D5B9B2
    '88, 174, 170', // #58AEAA
    '219, 58, 52', // #DB3A34
    '118, 120, 237', // #7678ED
    '254, 197, 187', // #FEC5BB
    '153, 194, 36', // #99C224
    '236, 8, 104', // #EC0868
    '160, 196, 255', // #A0C4FF
    '176, 137, 104', // #B08968
    '49, 87, 44', // #31572C
    '220, 171, 223', // #DCABDF
    '65, 103, 153', // #416799
    '242, 166, 90', // #F2A65A
    '179, 146, 172', // #B392AC
    '233, 217, 133', // #E9D985
    '64, 145, 108', // #40916C
    '193, 176, 152', // #C1B098
    '190, 227, 219', // #BEE3DB
    '202, 46, 85', // #CA2E55
    '72, 12, 168', // #480CA8
    '232, 153, 141', // #E8998D
    '0, 95, 115', // #005F73
    '233, 138, 21', // #E98A15
    '94, 84, 142', // #5E548E
  ].map((color) => ({
    stroke: `rgba(${color}, ${strokeOpacity})`,
    fill: `rgba(${color}, ${fillOpacity})`,
    strokeWidth: 2,
    fontSize: 16,
  }))[idx]
}

export const path2svg = (x0: number, y0: number, path, isClosed: boolean) => {
  let minX
  let minY
  let maxX
  let maxY
  const points = path.map((point, i) => {
    let { x, y } = point
    x += x0
    y += y0
    if (i === 0) {
      minX = x
      maxX = x
      minY = y
      maxY = y
      return `M${x} ${y}`
    }
    minX = Math.min(minX, x)
    minY = Math.min(minY, y)
    maxX = Math.max(maxX, x)
    maxY = Math.max(maxY, y)
    return `L${x} ${y}`
  })
  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
    path: `${points.join(' ')}${isClosed ? '' : ' Z'}`,
  }
}

export const getX = (e) =>
  (e.clientX || (e.touches && e.touches.length && e.touches[0].clientX) || 0) +
  window.scrollX

export const getY = (e) =>
  (e.clientY || (e.touches && e.touches.length && e.touches[0].clientY) || 0) +
  window.scrollY

export const isTouchDevice = () =>
  'ontouchstart' in window || navigator.maxTouchPoints

export const getDeviceType = () => {
  let type = 'desktop'
  if (window.innerWidth <= 600) {
    type = 'mobile'
  } else if (window.innerWidth <= 768) {
    type = 'tablet'
  }
  return type
}

export const setNoScroll = (noScroll) => {
  if (noScroll) {
    document.body.classList.add('no-scroll')
  } else {
    document.body.classList.remove('no-scroll')
  }
}

export const taskPathToArray = (array, factor = [1, 1]) =>
  array.map((point, num) => {
    const n = num % 2 ? factor[0] : factor[1]

    return [point.rx * n, point.ry * n]
  })

export const denormalizeArray = (array, size) =>
  array.map((item) => ({
    rx: item[0],
    ry: item[1],
    x: item[0] * size[0],
    y: item[1] * size[1],
  }))

export const isEqualsObjKeys = (obj1, obj2) => {
  if (!obj1 || !obj2) return false

  const o1Keys = Object.keys(obj1)
  const o2Keys = Object.keys(obj2)

  if (o1Keys.length !== o2Keys.length) return false

  return o1Keys.toString() === o2Keys.toString()
}

export const capitalize = (str) =>
  str
    .split(/\s+/)
    .map((w) => w[0].toUpperCase() + w.slice(1))
    .join(' ')

export interface normalizeTimeDateResult {
  prefix?: string
  day?: string
  month?: string
  monthNum?: number
  hours?: number
  minutes?: string
  year?: number
}

export const normalizeTimeDate = (date): normalizeTimeDateResult => {
  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ]
  const user = new Date(date)
  const now = new Date()
  const result: normalizeTimeDateResult = {}
  const leadingZero = (number) => `0${String(number)}`.slice(-2)

  if (
    now.getFullYear() === user.getFullYear() &&
    now.getMonth() === user.getMonth() &&
    now.getDate() - 1 === user.getDate()
  ) {
    result.prefix = 'yesterday'
  }

  if (now.toDateString() === user.toDateString()) {
    result.prefix = 'today'
  }

  result.day = leadingZero(user.getDate())
  result.month = months[user.getMonth()]
  result.monthNum = user.getMonth() + 1
  result.hours = user.getHours()
  result.minutes = leadingZero(user.getMinutes())
  result.year = user.getFullYear()
  return result
}

export const replaceAll = (target, searchArray, replacement) => {
  let result = target

  searchArray.forEach((item) => {
    result = result.replace(new RegExp(item, 'g'), replacement)
  })

  return result
}

export const isOsx = () => navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i)

export const getSelectedNode = () => {
  if (document.selection) {
    return document.selection.createRange().parentElement()
  }

  const selection = window.getSelection()

  if (selection && selection.rangeCount > 0) {
    return selection.getRangeAt(0).startContainer.parentNode
  }

  return false
}

export const clearHightlightedText = () => {
  const selection = window.getSelection()

  if (selection) {
    if (selection.empty) {
      selection.empty()
    } else if (selection.removeAllRanges) {
      selection.removeAllRanges()
    }
  } else if (document.selection) {
    document.selection.empty()
  }
}

const prefetchSourceItem = (src) =>
  new Promise((resolve) => {
    const image = new Image()

    image.onload = (data) => {
      resolve({ src, data })
    }
    image.src = src
  })

export const prefetchSource = (sourceArray, type) =>
  new Promise((resolve, reject) => {
    const fetchArray: any[] = []

    if (type === 'text') {
      resolve(sourceArray)
    }

    sourceArray.forEach((img) => {
      fetchArray.push(prefetchSourceItem(img))
    })

    Promise.all(fetchArray)
      .then((data) => resolve(data))
      .catch((err) => reject(err))
  })

export const selectorSupported = (selector) => {
  // Copy-paste from Modernizr. Checking pseudoselector support
  // It used to check IE's remove default buttons in inputs at Windows

  var support,
    sheet,
    doc = document,
    root = doc.documentElement,
    head = root.getElementsByTagName('head')[0],
    impl = doc.implementation || {
      hasFeature: function () {
        return false
      },
    },
    link = doc.createElement('style')
  link.setAttribute('type', 'text/css')
  ;(head || root).insertBefore(link, (head || root).firstChild)

  // TODO: check second part
  // sheet = link.sheet || link.styleSheet
  sheet = link.sheet

  if (!(sheet && selector)) return false

  support = impl.hasFeature('CSS2', '')
    ? function (selector) {
        try {
          sheet.insertRule(selector + '{ }', 0)
          sheet.deleteRule(sheet.cssRules.length - 1)
        } catch (e) {
          return false
        }
        return true
      }
    : function (selector) {
        sheet.cssText = selector + ' { }'
        return (
          sheet.cssText.length !== 0 &&
          !/unknown/i.test(sheet.cssText) &&
          sheet.cssText.indexOf(selector) === 0
        )
      }

  return support(selector)
}

export const isBrowser = typeof document === 'object'

export const getNativeScrollbarSize = () => {
  const t = document.createElement('div')

  t.style.cssText =
    'width: 100px; height: 100px; overflow: scroll; position: absolute; top: -9999px;'

  document.body.appendChild(t)

  const width = t.offsetWidth - t.clientWidth

  document.body.removeChild(t)

  return width === 0 ? 30 : width + 15
}

export const requestAnimationFrame = (fn) => {
  if (isBrowser) {
    return (
      window.requestAnimationFrame(fn) ||
      window.setImmediate(fn) ||
      (function (c) {
        return setTimeout(c, 0)
      })(fn)
    )
  }
}
