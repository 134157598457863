import * as api from '../Authorization/api'
import * as types from './constants'

export const confirmTerms = (user) => (dispatch) => {
  dispatch({
    type: types.UPDATE_TOS,
  })

  return api
    .changeMe(user)
    .then(() => {
      dispatch({
        type: types.UPDATE_TOS_FINISH,
      })
    })
    .catch(() => {
      dispatch({
        type: types.UPDATE_TOS_ERROR,
      })
    })
}
