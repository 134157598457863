import React from 'react'
import { render } from 'react-dom'
import { Route, BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import 'normalize.css'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { App } from './components'
import { refreshToken } from './components/Authorization/api'
import i18n from './i18n'
import config from './config'
import './style.scss'
import { createBrowserHistory } from 'history'
import createRootReducer from './reducers'
import { compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import 'react-image-lightbox/style.css'
import '@dbrainio/shared-ui/dist/index.css'
const pjson = require('../package.json')
console.log('version: ' + pjson.version)

export const history = createBrowserHistory()

const store: any = createStore(
  createRootReducer(history),
  compose(
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      thunk,
    ),
  ),
)

setInterval(refreshToken, 3600 * 1000)

if (!config.languages.includes(i18n.language)) {
  i18n.changeLanguage(config.defaultLang)
}

render(
  <Provider store={store}>
    <BrowserRouter>
      <Route path="/" component={App} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)
