import React, { PureComponent } from 'react'
import showdown from 'showdown'

import './style.scss'

export interface TextProps {
  text?: string
  classList?: string[]
  className?: string
}

class Text extends PureComponent<TextProps> {
  public static defaultProps = {
    text: '',
    classList: [],
    className: '',
  }

  render() {
    const { text, classList, className } = this.props

    if (!text) return ''

    const md = new showdown.Converter()
    const html = { __html: `${md.makeHtml(text)}` }

    return (
      <p
        className={[...classList, className].join(' ')}
        dangerouslySetInnerHTML={html}
      />
    )
  }
}

export default Text
