import React, { Component } from 'react'
import Combokeys from 'combokeys'
import config from '../../../../config'

import Text from '../../components/Text/Text'
import TaskInputLabelsButtons from '../../components/TaskInputLabelsButtons/TaskInputLabelsButtons'
import Effects from '../../../Effects'
import classNames from 'classnames'
import style from './Label.module.css'
import MagnifyImage from 'components/MagnifyImage/MagnifyImage'
import { isStudioMode } from 'env'

export interface LabelProps {
  tasks: any
  t: any
  task: any
  markTask: any
  skipTask: any
  testLabel?: React.ReactNode
  fullDescriptionPopup?: React.ReactNode
  caption?: React.ReactNode
  description?: React.ReactNode
  isFirstDescriptionShow: boolean
}

class Label extends Component<LabelProps, any> {
  combokeys: Combokeys.Combokeys
  compareWithGroup: boolean

  constructor(props) {
    super(props)

    this.state = {
      currentSliderImage: 0,
      hoverSliderImage: null,
      watchImagesEror: false,
      watchedImages: [0],
      labelsActive: [],
    }

    this.combokeys = new Combokeys(document.documentElement)
    this.compareWithGroup = false
  }

  componentDidMount() {
    this.bindHotkeys()
  }

  componentDidUpdate(prevProps, prevState) {
    const { task: prevTask } = prevProps
    const { task: nextTask } = this.props

    const { labelsActive: prevLabelsActive } = prevState
    const { labelsActive: nextLabelsActive } = this.state

    if (prevTask.id !== nextTask.id && nextTask.id) {
      this.clearActiveTask()
    }

    if (
      nextLabelsActive.length &&
      prevLabelsActive.length !== nextLabelsActive.length
    ) {
      this.handleMark(nextLabelsActive[0])
    }
  }

  componentWillUnmount() {
    this.combokeys.detach()
  }

  bindHotkeys() {
    const { skipTask } = this.props
    const { skip } = config.hotKeys

    this.combokeys.bind(skip.key, () => {
      skipTask()
    })
  }

  clearActiveTask() {
    this.setState({
      currentSliderImage: 0,
      watchedImages: [0],
      hoverSliderImage: null,
      labelsActive: [],
    })
  }

  changeActiveSlide(currentSliderImage) {
    this.setState({ currentSliderImage })
  }

  overNavButton(hoverSliderImage) {
    this.setState((prev) => ({
      hoverSliderImage,
      watchedImages: prev.watchedImages.includes(hoverSliderImage)
        ? prev.watchedImages
        : [...prev.watchedImages, hoverSliderImage],
    }))
  }

  leaveNavButton() {
    this.setState({ hoverSliderImage: null })
  }

  generateInput() {
    const { currentSliderImage, hoverSliderImage } = this.state
    const { task } = this.props
    const isMultipleImageSource = task.input.source.length > 1

    if (task.input.type === 'text') {
      return (
        <div className="image col-4 col-sm-4 col-xs-2">
          {task.input.source.map((s) => (
            <Text className="text-value" text={s} key={s} />
          ))}
        </div>
      )
    }

    if (isMultipleImageSource) {
      const activeSlide = hoverSliderImage || currentSliderImage

      return (
        <div className="image col-4 col-sm-4 col-xs-2">
          <div className="core-wrapper">
            <div className="core-middle">
              <div className="core-inner">
                <div
                  className={'svg_container'}
                  style={{
                    width: 'auto',
                    height: 'auto',
                  }}
                >
                  {task.input.source.map((s, n) => {
                    return (
                      <img
                        key={`${s}-img`}
                        ref={(c) => {
                          this[`imageRef-${n}`] = c
                        }}
                        src={s}
                        className={classNames({
                          active: n === activeSlide,
                          hover: n === hoverSliderImage,
                        })}
                        style={this.state.imageFullscreenSize}
                        alt=""
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="image-slider-nav">
            <Effects
              transition="shakeShort"
              transitionFinish={() => {
                this.setState({ watchImagesEror: false })
              }}
              init={this.state.watchImagesEror}
            >
              <div>
                {task.input.source.map((s, n) => {
                  return (
                    <button
                      key={`${s}-btn`}
                      onClick={(e) => {
                        e.preventDefault()
                        this.changeActiveSlide(n)
                      }}
                      onMouseEnter={() => {
                        this.overNavButton(n)
                      }}
                      onMouseLeave={() => {
                        this.leaveNavButton()
                      }}
                    >
                      <span>{n + 1}</span>
                    </button>
                  )
                })}
              </div>
            </Effects>
          </div>
        </div>
      )
    }

    return (
      <div className="image col-4 col-sm-4 col-xs-2">
        {task.input.source.map((s) => (
          <MagnifyImage src={s} imageClassName="image-value" key={`${s}-img`} />
        ))}
      </div>
    )
  }

  handleLabelClick(value) {
    const { watchedImages } = this.state
    const { task } = this.props
    const multipleImages = task.input.source

    if (value) {
      if (
        multipleImages.length > 1 &&
        watchedImages.length !== multipleImages.length
      ) {
        this.setState({ watchImagesEror: true })
      } else {
        this.setState((prev) => {
          const labelsActive = Object.assign([], prev.labelsActive)

          labelsActive.push(value)

          return { labelsActive }
        })
      }
    }
  }

  handleMark(payload) {
    const { markTask } = this.props

    markTask(payload)
  }

  render() {
    const {
      t,
      tasks,
      task,
      skipTask,
      testLabel,
      caption,
      description,
      fullDescriptionPopup,
    } = this.props

    const skipButton =
      tasks.onboarding_status || isStudioMode ? (
        ''
      ) : (
        <span
          role="button"
          tabIndex={0}
          className="skip"
          onClick={() => skipTask()}
        >
          {t('task.button.skip')}
        </span>
      )

    return (
      <div className={style.label}>
        <div className={`annotation row`}>
          <div className="image-wrapper">{this.generateInput()}</div>
          <div className="actions-wrapper">
            <div className="actions-outer">
              <div className="actions col-2 col-sm-4 col-xs-2">
                <div className="actions-inner">
                  {testLabel}
                  {caption}
                  {description}
                  <div className="gap-back sm-hidden" />
                  <div className="buttons-outer">
                    <div className="buttons">
                      <TaskInputLabelsButtons
                        id={task.id}
                        groups={task.output.groups}
                        labels={task.output.labels}
                        labelClickHandler={this.handleLabelClick.bind(this)}
                        labelsActive={this.state.labelsActive}
                      />
                    </div>
                  </div>
                  {isStudioMode ? null : (
                    <div className="second-actions">{skipButton}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {fullDescriptionPopup}
      </div>
    )
  }
}

export default Label
