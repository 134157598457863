import { FC, SyntheticEvent, useState } from 'react'
import style from './TableComplex.module.scss'

import CropManager from 'components/CropManager/CropManager'
import { RecognizeStatsItem } from 'components/BboxedImage/BboxImage'
import Popup from 'components/Popup/Popup'
import { Table } from './Table'
import { ITaskResultValues } from '../OcrMultiple'

interface ITableProps {
    crop?: string
    hoveredItemName?: string
    items?: RecognizeStatsItem[]
    onHover: (item: string | null) => void
    valuesToEdit: ITaskResultValues
    handleChange: (e: SyntheticEvent, label: string, isEdited: boolean) => void
    buttonLabel: string
}

export const TableComplex: FC<ITableProps> = ({
    crop,
    hoveredItemName,
    items,
    onHover,
    valuesToEdit,
    handleChange,
    buttonLabel
}) => {
    const [showTable, setShowTable] = useState(false)
    const id = Math.random() * 1000

    const actions = {
        hidePopup: () => {
            setShowTable(false)
        },
    }

    const handleShowTable = (event) => {
        setShowTable(true)
    }
    return (
        <>
            <div onClick={handleShowTable} className={style.tableButton}>
                {buttonLabel}
            </div>
            <Popup
                id={`${id}`}
                popup={{ isActive: showTable, id: `${id}` }}
                actions={actions}
                handleClose={() => {
                    setShowTable(false)
                }}
                className={style.tablePopup}
            >
                <div className={style.tablePopupContainer}>
                    <div className={style.tablePopupTop}>
                        <CropManager
                            crop={crop}
                            hoveredItemName={hoveredItemName}
                            items={items}
                        />
                    </div>
                    <div className={style.tablePopupBottom}>
                        <Table
                            onHover={onHover}
                            table={items}
                            valuesToEdit={valuesToEdit}
                            handleChange={handleChange}
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}
