import React, { Component, CSSProperties, SyntheticEvent } from 'react'
import Combokeys from 'combokeys'
import { v4 as uuidv4 } from 'uuid'
import config from '../../../../../config'
import 'rc-slider/assets/index.css'
import Slider from 'rc-slider'
import {
  offset,
  getX,
  getY,
  setNoScroll,
  isOsx,
} from '../../../../../utils/common'
import TaskSourceControls from '../../../components/TaskSourceControls/TaskSourceControls'
import FullscreenIcon from '../../../../Icons/FullscreenIcon/FullscreenIcon'
import ZoomInIcon from '../../../../Icons/ZoomInIcon/ZoomInIcon'
import ZoomOutIcon from '../../../../Icons/ZoomOutIcon/ZoomOutIcon'
import FullscreenCloseIcon from '../../../../Icons/FullscreenCloseIcon/FullscreenCloseIcon'
import debounce from 'lodash/debounce'
import Button from '../../../../Button'
import Scroller from '../../../../Scroller'
import Tooltip from '../../../../Tooltip'

export interface AabbRect {
  height: number
  idx: number
  label: string
  new: boolean
  rheight?: number
  rwidth?: number
  rx?: number
  ry?: number
  showControls?: boolean
  showDelete: boolean
  style: CSSProperties
  width: number
  x: number
  y: number
  createdAt?: number
}

export interface AabbCoreProps {
  coreref?: any
  container: string
  minBoxSide: number
  imageUrl?: string
  rects?: AabbRect[] | null
  label?: string
  t: any
  idx?: number
  rectStyle: any
  clearPaths?: () => void
  onPathsUpdate?: any
  imageRef: any
  coreMiddleRef: any
  mouseDownHandler: any
  innerFullscreenSize: any
  handleButtonToggleFullscreen: any
  isFullscreen?: boolean
  imageFullscreenSize: any
  fullscreenOffsetLeft: number
  fullscreenOffsetTop: number
  zoomRatioPercentage: number
  changeZoomRatio: any
}

export interface AabbCoreState {
  rects: { [key: string]: AabbRect } | {}
  currentRectId: null | string
  copyKeyPressed: boolean
  isFullscreenTooltipShown: boolean
}

export default class Core extends Component<AabbCoreProps, AabbCoreState> {
  maxZoom: number
  minZoom: number
  zoomStep: number
  combokeys: Combokeys.Combokeys
  _onResize: any
  _mouseup: any
  _mousemove: any

  constructor(props) {
    super(props)

    this.state = {
      rects: this.props.rects || {},

      currentRectId: null,
      copyKeyPressed: false,
      isFullscreenTooltipShown: false,
    }

    this.maxZoom = 2
    this.minZoom = 0.5
    this.zoomStep = 0.1

    this._onMouseMove = this._onMouseMove.bind(this)
    this._onMouseUp = this._onMouseUp.bind(this)
    this.combokeys = new Combokeys(document.documentElement)
  }

  componentWillMount() {
    this._onResize = debounce(this.setResolution.bind(this), 250)
    window.addEventListener('resize', this._onResize)
    document.addEventListener('mousemove', this._onMouseMove)
    document.addEventListener('mouseup', this._onMouseUp)
    document.addEventListener('touchmove', this._onMouseMove)
    document.addEventListener('touchend', this._onMouseUp)
  }

  componentDidMount() {
    this.setResolution()
    this.setCombokeys()
  }

  componentWillReceiveProps(nextProps) {
    const equal =
      JSON.stringify(Object.keys(nextProps.rects)) ===
      JSON.stringify(Object.keys(this.props.rects))
    if (!equal) {
      this._mouseup = null
      this.setState({ rects: nextProps.rects })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onResize)
    document.removeEventListener('mousemove', this._onMouseMove)
    document.removeEventListener('mouseup', this._onMouseUp)
    document.removeEventListener('touchmove', this._onMouseMove)
    document.removeEventListener('touchend', this._onMouseUp)
    this.combokeys.detach()
  }

  setResolution() {
    const el = this.props.imageRef.current
    const width = el.offsetWidth
    const height = el.offsetHeight
    const { rects } = this.state

    Object.keys(rects).forEach((rectId) => {
      if (Object.prototype.hasOwnProperty.call(rects, rectId)) {
        const rect = rects[rectId]
        rect.x = rect.rx * width
        rect.y = rect.ry * height
        rect.width = rect.rwidth * width
        rect.height = rect.rheight * height
      }
    })

    this.setState({ rects })
  }

  setCombokeys() {
    const { fullscreen, copy_bbox: copyBbox, undo } = config.hotKeys
    const os = isOsx() ? 'osx' : 'win'

    this.combokeys.bind('esc', () => {
      this.handleEscKeyPress()
    })

    this.combokeys.bind(undo.key[os], () => {
      this.undo()
    })

    this.combokeys.bind(
      copyBbox.key,
      () => {
        this.handleCopyKeyPressed(true)
      },
      'keydown',
    )

    this.combokeys.bind(
      copyBbox.key,
      () => {
        this.handleCopyKeyPressed(false)
      },
      'keyup',
    )

    this.combokeys.bind(fullscreen.key, () => {
      this.props.handleButtonToggleFullscreen(1, this.setResolution.bind(this))
    })
  }

  handleEscKeyPress() {
    const { currentRectId, rects } = this.state

    delete rects[currentRectId]
    if (currentRectId) this.setState({ rects, currentRectId: null })
  }

  handleCopyKeyPressed(copyKeyPressed) {
    this.setState({ copyKeyPressed })
  }

  _onMouseMove(e) {
    if (this._mousemove) {
      this._mousemove(e)
    }
  }

  _onMouseUp(e) {
    if (this._mouseup) {
      this._mouseup(e)
    }
  }

  el(): Element | null {
    return document.querySelector(`.${this.props.container}`)
  }

  cloneRect(rectId, e) {
    const { rects } = this.state
    const o = Object.assign({}, rects[rectId])
    const currentRectId = uuidv4()
    const copyKeyPressed = false

    e.persist()

    rects[currentRectId] = o

    this.setState({ rects, currentRectId, copyKeyPressed }, () => {
      this.moveRect(currentRectId, e)
    })
  }

  moveRect(rectId, e) {
    const { rects, copyKeyPressed } = this.state
    const { label } = this.props
    const movingRect = rects[rectId]

    if (!label || movingRect.label !== label) {
      return
    }

    e.stopPropagation()
    e.preventDefault()

    this.props.mouseDownHandler(true)

    if (copyKeyPressed) {
      this.cloneRect(rectId, e)
      return
    }

    if (!movingRect.showControls) {
      this.switchRectControls(rectId, true)
    }

    setNoScroll(true)
    movingRect.showDelete = false
    const startX = getX(e)
    const startY = getY(e)
    const x0 = movingRect.x
    const y0 = movingRect.y

    this.setState({ currentRectId: rectId })

    this._mousemove = (moveEvent) => {
      moveEvent.preventDefault()
      moveEvent.stopPropagation()
      const x = getX(moveEvent)
      const y = getY(moveEvent)
      movingRect.x = x0 + x - startX
      movingRect.y = y0 + y - startY
      this._fixMovingRect(movingRect, false)
      rects[rectId] = movingRect
      this.setState({ rects })
      this.props.onPathsUpdate && this.props.onPathsUpdate(rects, null)
    }

    this._mouseup = () => {
      this.setState({ currentRectId: null })
      movingRect.showDelete = true
      setNoScroll(false)
      this._mousemove = null
      this.props.mouseDownHandler(false)
    }
  }

  _fixRect(rect, fixSize) {
    const el = this.el()

    if (el) {
      rect.rx = rect.x / el.clientWidth
      rect.ry = rect.y / el.clientHeight
    }

    if (fixSize) {
      const { minBoxSide } = this.props
      const { isFullscreen, zoomRatioPercentage } = this.props
      if (isFullscreen) {
        rect.width = Math.max(minBoxSide, rect.width)
        rect.height = Math.max(minBoxSide, rect.height)
      } else {
        rect.width = Math.max(4, rect.width)
        rect.height = Math.max(4, rect.height)
      }
    }
    if (el) {
      rect.rwidth = rect.width / el.clientWidth
      rect.rheight = rect.height / el.clientHeight
    }
  }

  _fixMovingRect(rect, fixSize) {
    const el = this.el()
    if (!!el) {
      rect.x = Math.max(rect.x, 1)
      rect.x = this.el() && Math.min(rect.x, el.clientWidth - rect.width - 1)
      rect.y = Math.max(rect.y, 1)
      rect.y = Math.min(rect.y, el.clientHeight - rect.height - 1)
      this._fixRect(rect, fixSize)
    }
  }

  _fixResizingRect(rect, fixSize) {
    const el = this.el()
    if (!!el) {
      const right = Math.min(rect.x + rect.width, el.clientWidth - 1)
      const bottom = Math.min(rect.y + rect.height, el.clientHeight - 1)
      rect.x = Math.max(rect.x, 1)
      rect.width = right - rect.x
      rect.y = Math.max(rect.y, 1)
      rect.height = bottom - rect.y
      this._fixRect(rect, fixSize)
    }
  }

  resizeRect(rectId?: any, downEvent?: any, resize?: any) {
    const { minBoxSide, label, rectStyle, idx } = this.props
    const { rects } = this.state
    let rect = rects[rectId]

    this.props.mouseDownHandler(true)

    if (!label || (rect && rect.label !== label)) {
      return
    }

    downEvent.stopPropagation()
    downEvent.preventDefault()

    if (rect && !rect.showControls) {
      this.switchRectControls(rectId, true)
    }
    setNoScroll(true)

    const startX = getX(downEvent)
    const startY = getY(downEvent)

    if (!rectId) {
      const el = this.el()
      if (!el) return

      const { top, left } = offset(el)
      rect = {
        label,
        style: rectStyle,
        showDelete: false,
        new: true,
        idx,
        x: startX - left,
        y: startY - top,
        width: 0,
        height: 0,
      }

      rectId = uuidv4()

      this._fixResizingRect(rect, false)
      rects[rectId] = rect
      this.setState({ rects })
    }

    rect.showDelete = false
    const prevWidth = rect.width
    const prevHeight = rect.height
    const x0 = rect.x
    const y0 = rect.y
    const r = resize || {
      top: true,
      left: true,
      bottom: true,
      right: true,
    }

    this.setState({ currentRectId: rectId })

    this._mousemove = (moveEvent) => {
      moveEvent.preventDefault()
      moveEvent.stopPropagation()

      const { currentRectId } = this.state

      if (!currentRectId) {
        this.props.mouseDownHandler(false)
        return
      }

      this.props.mouseDownHandler(true)

      if (rect && !rect.showControls) {
        this.switchRectControls(rectId, true)
      }

      const x = getX(moveEvent)
      const y = getY(moveEvent)
      const _minBoxSide = rect.new ? 0 : minBoxSide

      if (r.left && prevWidth + (startX - x) > _minBoxSide) {
        rect.x = x0 - (startX - x)
        rect.width = Math.max(prevWidth + (startX - x), 0)
      }
      if (r.top && prevHeight + (startY - y) > _minBoxSide) {
        rect.y = y0 - (startY - y)
        rect.height = Math.max(prevHeight + (startY - y), 0)
      }
      if (r.right && x - startX + prevWidth > _minBoxSide) {
        rect.width = Math.max(x - startX + prevWidth, 0)
      }
      if (r.bottom && y - startY + prevHeight > _minBoxSide) {
        rect.height = Math.max(y - startY + prevHeight, 0)
      }

      this._fixResizingRect(rect, !rect.new)
      rects[rectId] = rect
      this.setState({ rects })
    }

    this._mouseup = () => {
      const currentRectId = null
      rect.showDelete = true
      rect.new = false
      rect.createdAt = +new Date()
      this._fixResizingRect(rect, true)
      this.setState({ rects, currentRectId })
      setNoScroll(false)
      this._mousemove = null
      this.props.mouseDownHandler(false)
      this.props.onPathsUpdate(rects, null)
    }
  }

  deleteRect(rectId, e) {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    const { rects } = this.state

    delete rects[rectId]
    this.setState({ rects })
    this.props.mouseDownHandler(false)
    this.props.onPathsUpdate(rects, null)
  }

  switchRectControls(rectId, isVisible) {
    const { rects } = this.state
    rects[rectId].showControls =
      typeof isVisible === 'undefined' ? !rects[rectId].showControls : isVisible
    if (rects[rectId].showControls) {
      Object.keys(rects).forEach((rId) => {
        if (
          Object.prototype.hasOwnProperty.call(rects, rId) &&
          rId !== rectId
        ) {
          rects[rId].showControls = false
        }
      })
    }
    this.setState({ rects })
  }

  undo(e?: SyntheticEvent) {
    const { rects } = this.state
    const currentRectId = null

    let newest = 0
    let newestUuid

    if (e) e.preventDefault()

    Object.keys(rects).forEach((i) => {
      const current = rects[i].createdAt

      if (current > newest) {
        newest = current
        newestUuid = i
      }
    })

    delete rects[newestUuid]

    this.setState({ rects, currentRectId })
  }

  clear(e) {
    const rects = {}
    const currentRectId = null

    e.preventDefault()

    this.setState({ rects, currentRectId })
    this.props.clearPaths && this.props.clearPaths()
  }

  handleSvgAreaMouseup() {
    this.props.mouseDownHandler(false)
  }

  buttonToggleFullscreenHover(isFullscreenTooltipShown) {
    this.setState({ isFullscreenTooltipShown })
  }

  handleZoomButtonClick(type, callback) {
    const { zoomRatioPercentage, changeZoomRatio } = this.props

    const zoomRatio =
      type === 'up'
        ? zoomRatioPercentage + this.zoomStep
        : zoomRatioPercentage - this.zoomStep

    changeZoomRatio(parseFloat(zoomRatio.toFixed(1)), callback)
  }

  handleZoomSliderChange(value, callback) {
    const { changeZoomRatio } = this.props
    changeZoomRatio(parseFloat((value / 100).toFixed(1)), callback)
  }

  renderRectControls(rect, rectId) {
    const xResize = Math.min(10, rect.width / 10)
    const yResize = Math.min(10, rect.height / 10)
    const xyResize = Math.min(xResize, yResize)
    const delSize = Math.min(9, rect.width / 3, rect.height / 3)
    const crossRadius = delSize * (2 / 3)
    const crossLineSize = Math.sqrt((crossRadius * crossRadius) / 2)
    let del: any = ''
    if (rect.showDelete) {
      del = (
        <g>
          <circle
            className="rect_circle_delete"
            cx={rect.x + rect.width / 2}
            cy={rect.y + rect.height / 2}
            r={delSize}
            style={{ fill: rect.style.stroke }}
          />
          <line
            x1={rect.x + rect.width / 2 - crossLineSize}
            y1={rect.y + rect.height / 2 - crossLineSize}
            x2={rect.x + rect.width / 2 + crossLineSize}
            y2={rect.y + rect.height / 2 + crossLineSize}
            style={{ stroke: 'white', strokeWidth: 2 }}
          />
          <line
            x1={rect.x + rect.width / 2 + crossLineSize}
            y1={rect.y + rect.height / 2 - crossLineSize}
            x2={rect.x + rect.width / 2 - crossLineSize}
            y2={rect.y + rect.height / 2 + crossLineSize}
            style={{ stroke: 'white', strokeWidth: 2 }}
          />
          <circle
            className="rect_circle_delete transparent"
            onMouseDown={this.deleteRect.bind(this, rectId)}
            onTouchStart={this.deleteRect.bind(this, rectId)}
            cx={rect.x + rect.width / 2}
            cy={rect.y + rect.height / 2}
            r={delSize}
          />
        </g>
      )
    }
    if (rect.label !== this.props.label) {
      return <g>{del}</g>
    }
    return (
      <g>
        <rect
          onMouseDown={(e) => this.resizeRect(rectId, e, { top: true })}
          onTouchStart={(e) => this.resizeRect(rectId, e, { top: true })}
          x={rect.x}
          y={rect.y - yResize}
          width={rect.width}
          height={2 * yResize}
          className="n-resize transparent"
        />
        <rect
          onMouseDown={(e) => this.resizeRect(rectId, e, { right: true })}
          onTouchStart={(e) => this.resizeRect(rectId, e, { right: true })}
          x={rect.x + rect.width - xResize}
          y={rect.y}
          width={2 * xResize}
          height={rect.height}
          className="e-resize transparent"
        />
        <rect
          onMouseDown={(e) => this.resizeRect(rectId, e, { bottom: true })}
          onTouchStart={(e) => this.resizeRect(rectId, e, { bottom: true })}
          x={rect.x}
          y={rect.y + rect.height - yResize}
          width={rect.width}
          height={2 * yResize}
          className="s-resize transparent"
        />
        <rect
          onMouseDown={(e) => this.resizeRect(rectId, e, { left: true })}
          onTouchStart={(e) => this.resizeRect(rectId, e, { left: true })}
          x={rect.x - xResize}
          y={rect.y}
          width={2 * xResize}
          height={rect.height}
          className="w-resize transparent"
        />

        <circle
          onMouseDown={(e) =>
            this.resizeRect(rectId, e, { top: true, left: true })
          }
          onTouchStart={(e) =>
            this.resizeRect(rectId, e, { top: true, left: true })
          }
          className="nw-resize transparent"
          cx={rect.x}
          cy={rect.y}
          r={xyResize}
        />
        <circle
          onMouseDown={(e) =>
            this.resizeRect(rectId, e, {
              bottom: true,
              right: true,
            })
          }
          onTouchStart={(e) =>
            this.resizeRect(rectId, e, {
              bottom: true,
              right: true,
            })
          }
          className="se-resize transparent"
          cx={rect.x + rect.width}
          cy={rect.y + rect.height}
          r={xyResize}
        />
        <circle
          onMouseDown={(e) =>
            this.resizeRect(rectId, e, { bottom: true, left: true })
          }
          onTouchStart={(e) =>
            this.resizeRect(rectId, e, { bottom: true, left: true })
          }
          className="sw-resize transparent"
          cx={rect.x}
          cy={rect.y + rect.height}
          r={xyResize}
        />

        {del}
      </g>
    )
  }

  renderRect(rect: AabbRect, key: any, rectId: any) {
    if (rect) {
      const current = rect.label === this.props.label
      const { strokeWidth, fontSize, stroke } = rect.style

      if (typeof strokeWidth === 'string' || typeof fontSize === 'string') {
        throw new Error('Something wrong with strokeWidth or fontSize')
      }

      return (
        <g
          onMouseEnter={this.switchRectControls.bind(this, rectId, true)}
          onMouseLeave={this.switchRectControls.bind(this, rectId, false)}
          onMouseDown={this.moveRect.bind(this, rectId)}
          onTouchStart={this.moveRect.bind(this, rectId)}
          key={key}
          className={current ? 'current' : ''}
        >
          <rect
            x={rect.x}
            y={rect.y}
            width={rect.width}
            height={rect.height}
            className="rect"
            style={rect.style}
          />
          {this.props.label === rect.label && (
            <text
              x={rect.x}
              y={rect.y - 4}
              fill={'#fafafa'}
              fontSize={fontSize}
              style={{
                display: rect.showDelete ? 'block' : 'none',
                fill: rect.style.stroke,
              }}
            >
              {rect.label}
            </text>
          )}
          <text
            x={rect.x + rect.width / 2}
            y={rect.y - strokeWidth + rect.height / 2 + fontSize / 2}
            fill={stroke}
            fontSize={fontSize}
            style={{ display: rect.showDelete ? 'block' : 'none' }}
          >
            <tspan textAnchor="middle">{rect.idx + 1}</tspan>
          </text>

          {rect.showControls ? this.renderRectControls(rect, rectId) : ''}
        </g>
      )
    }
    return ''
  }

  render() {
    const { imageUrl, label, container, t, zoomRatioPercentage } = this.props

    const { rects } = this.state

    const rectsElements: any[] = []

    Object.keys(rects).forEach((id) => {
      if (rects[id].label !== label) {
        rectsElements.push(this.renderRect(rects[id], id, id))
      }
    })
    Object.keys(rects).forEach((id) => {
      if (rects[id].label === label && !rects[id].showControls) {
        rectsElements.push(this.renderRect(rects[id], id, id))
      }
    })
    Object.keys(rects).forEach((id) => {
      if (rects[id].label === label && rects[id].showControls) {
        rectsElements.push(this.renderRect(rects[id], id, id))
      }
    })

    const outerSize = {
      width: document.body.offsetWidth,
      height: document.body.offsetHeight,
    }

    return (
      <div className="core-middle" ref={this.props.coreMiddleRef}>
        <Button
          className="btn btn-dark-gray core-button-fullscreenClose"
          onClick={() => {
            this.props.handleButtonToggleFullscreen(
              1,
              this.setResolution.bind(this),
            )
          }}
        >
          <FullscreenCloseIcon />
        </Button>

        <div className="core-zoom">
          <div className="core-slider">
            <Slider
              value={zoomRatioPercentage * 100}
              vertical
              min={50}
              max={200}
              step={10}
              onChange={(value) =>
                this.handleZoomSliderChange(
                  value,
                  this.setResolution.bind(this),
                )
              }
              defaultValue={100}
            />
          </div>
          <Button
            className="btn btn-dark-gray core-button-zoom-in"
            onClick={() => {
              this.handleZoomButtonClick('up', this.setResolution.bind(this))
            }}
            disabled={zoomRatioPercentage >= this.maxZoom}
          >
            <ZoomInIcon />
          </Button>

          <Button
            className="btn btn-dark-gray core-button-zoom-out"
            onClick={() => {
              this.handleZoomButtonClick('down', this.setResolution.bind(this))
            }}
            disabled={zoomRatioPercentage <= this.minZoom}
          >
            <ZoomOutIcon />
          </Button>

          <span className="core-zoom-percentage">
            {/* {`${parseInt(zoomRatioPercentage * 100, 10)}%`} */}
            {`${zoomRatioPercentage * 100}%`}
          </span>
        </div>

        <div className="core-inner">
          <Scroller
            disabled={!this.props.isFullscreen}
            outerSize={outerSize}
            innerSize={this.props.innerFullscreenSize}
            className="core-scroller"
            fullscreenOffsetLeft={this.props.fullscreenOffsetLeft}
            fullscreenOffsetTop={this.props.fullscreenOffsetTop}
            scrollHeight={8}
          >
            <div
              className={container}
              ref={this.props.coreref}
              style={this.props.imageFullscreenSize}
            >
              <img
                src={imageUrl}
                alt=""
                ref={this.props.imageRef}
                onLoad={this.setResolution.bind(this)}
                className="image-value image-value-with-bottoms"
              />
              <svg
                className="svg-area"
                onMouseDown={this.resizeRect.bind(this, null)}
                onMouseUp={this.handleSvgAreaMouseup.bind(this)}
                onTouchStart={this.resizeRect.bind(this, null)}
              >
                {rectsElements}
              </svg>

              <div className="svg-button-fullscreen">
                <Tooltip
                  text={t('task.button.fullscreen')}
                  position="bottom-right"
                  show={this.state.isFullscreenTooltipShown}
                >
                  <span
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                      this.props.handleButtonToggleFullscreen(
                        1,
                        this.setResolution.bind(this),
                      )
                    }}
                    onMouseEnter={() => {
                      this.buttonToggleFullscreenHover(true)
                    }}
                    onMouseLeave={() => {
                      this.buttonToggleFullscreenHover(false)
                    }}
                  >
                    <FullscreenIcon />
                  </span>
                </Tooltip>
              </div>
            </div>
          </Scroller>

          <TaskSourceControls
            buttonList={[
              {
                position: 'right',
                callback: this.undo.bind(this),
                content: t('task.button.undo'),
              },
              {
                position: 'right',
                callback: this.clear.bind(this),
                content: t('task.button.clear'),
              },
            ]}
          />
        </div>
      </div>
    )
  }
}
