import * as types from './constants'

const initialState = {
  isMobile: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_IS_MOBILE:
      return { ...state, isMobile: action.isMobile }
    default:
      return state
  }
}
