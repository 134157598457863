import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const isEngVersion = !window.location.hostname.includes('dbrain')
const defaultLang = isEngVersion ? 'en' : 'ru'

i18n.use(initReactI18next).init({
  fallbackLng: defaultLang,
  debug: false,
  detection: {
    // order and from where user language should be detected
    order: [
      'querystring',
      'cookie',
      'localStorage',
      'navigator',
      // 'htmlTag'
    ],

    // keys or params to lookup language from
    lookupQuerystring: 'lang',
    lookupCookie: 'lang',
    lookupLocalStorage: 'lang',

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 5,
    // cookieDomain: '.handl.ai',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,
  },
  resources: {
    ru: {
      translation: require('./locales/ru.json'),
    },
    en: {
      translation: require('./locales/en.json'),
    },
  },
  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    nsMode: 'default',
  },
})

export default i18n
