import React from 'react'

export interface ButtonProps {
  id?: string
  className?: string
  href?: string
  children: React.ReactNode
  onClick?: (e: any) => void
  onMouseEnter?: () => {}
  onMouseLeave?: () => void
  disabled?: boolean
}

export type ButtonState = any

export default class Button extends React.Component<ButtonProps, ButtonState> {
  render() {
    const { href, children, onClick, className, ...props } = this.props
    const Element = href ? 'a' : 'button'
    const role = Element === 'a' ? 'button' : null

    return (
      <Element
        className={`${className}`}
        href={href}
        onClick={onClick}
        role={role || ''}
        {...props}
      >
        {children}
      </Element>
    )
  }
}
