import React from 'react'

const genClassName = (postfix?: string) => {
  const cl = 'tooltip'
  return postfix ? `${cl}-${postfix}` : `${cl}`
}

export interface ToolTipProps {
  show: boolean
  children: React.ReactNode
  position?: string
  text: React.ReactNode
  className?: string
}

export default class Tooltip extends React.PureComponent<ToolTipProps> {
  tip: any

  render() {
    const { position = 'bottom', show, children, text, className } = this.props
    const visibilityClassName = show ? genClassName('visible') : ''

    return (
      <i
        className={`${genClassName()} ${genClassName(
          position,
        )} ${visibilityClassName} ${className}`}
        ref={(c) => {
          this.tip = c
        }}
      >
        {children}

        <div className="tooltip-content">{text}</div>
      </i>
    )
  }
}
