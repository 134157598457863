import * as types from './constants'

const initialState = {
  me: null,
  statsFetchProcess: false,
}

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_ME:
      return initialState

    case types.SIGNUP_SUCCESS:
    case types.UPDATE_ME: {
      const { me } = action
      return {
        ...state,
        me,
      }
    }

    case types.FETCH_STAT_PROCESS:
      return {
        ...state,
        statsFetchProcess: true,
      }

    case types.FETCH_STAT_SUCCESS:
      return {
        ...state,
        stat: action.stat,
        statsFetchProcess: false,
      }

    case types.FETCH_STAT_FAILED:
      return { ...state, statsFetchProcess: false }

    default:
      return state
  }
}
