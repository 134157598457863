import { Component, ReactNode } from 'react';
import styles from './FormWrapper.module.scss'

interface FormWrapperProps {
    children: ReactNode;
}

class FormWrapper extends Component<FormWrapperProps> {

    render() {
        const { children } = this.props;
        return (
            <div className={styles.formWrapper}>
                {children}
            </div>
        );
    }
}

export default FormWrapper;
