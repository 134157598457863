import React from 'react'

const LogoDbrain = () => (
    <svg width="135" viewBox="0 0 270 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M121.526 7.54369C122.773 7.54369 123.761 7.19388 124.476 6.50947C125.191 5.82506 125.556 4.91252 125.571 3.77184C125.571 2.63116 125.206 1.71862 124.491 1.03421C123.776 0.349805 122.788 0 121.541 0C120.401 0 119.488 0.349805 118.804 1.03421C118.12 1.71862 117.77 2.63116 117.77 3.77184C117.77 4.91252 118.105 5.82506 118.789 6.50947C119.458 7.19388 120.386 7.54369 121.526 7.54369Z" fill="#FAFAFA" />
        <path d="M118.242 35.5131H125.054V10.8136H118.242V35.5131Z" fill="#FAFAFA" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1075 0.243163C18.2319 0.243163 22.5352 1.94657 26.0174 5.3534C29.4996 8.76023 31.2331 12.9427 31.2331 17.8705C31.2331 22.8134 29.4996 26.9807 26.0174 30.3875C22.5352 33.7943 18.2319 35.4978 13.1075 35.4978H0V0.243163H13.1075ZM7.57257 7.07202V28.7297H13.1075C16.0271 28.7297 18.5209 27.6803 20.5889 25.5814C22.6569 23.4826 23.6909 20.9275 23.6909 17.9009C23.6909 14.8743 22.6873 12.3191 20.6649 10.2203C18.6425 8.12145 16.1335 7.07202 13.1227 7.07202H7.57257Z" fill="#FAFAFA" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M59.4863 14.053C61.828 16.5321 62.9989 19.5739 62.9989 23.1632C62.9989 26.7525 61.828 29.7944 59.4711 32.2734C57.1142 34.7525 54.3467 35.9996 51.1686 35.9996C49.7545 35.9996 48.4316 35.7107 47.2151 35.1327C45.9834 34.5548 45.1167 33.9768 44.6149 33.3989L43.8698 32.4864V35.513H37.0575V0.243167H43.885V13.8401C43.9458 13.7488 44.0523 13.6119 44.1739 13.475C44.3107 13.323 44.5997 13.0492 45.0406 12.6538C45.4816 12.2583 45.9834 11.8933 46.5004 11.5739C47.0174 11.2545 47.7169 10.9655 48.5532 10.707C49.4048 10.4484 50.2715 10.3268 51.1686 10.3268C54.3771 10.3268 57.1446 11.5587 59.4863 14.053ZM54.1946 28.0605C55.3655 26.783 55.9585 25.1556 55.9585 23.1632C55.9585 21.186 55.3655 19.5587 54.1946 18.2811C53.0238 17.0035 51.5944 16.38 49.9218 16.38C48.2339 16.38 46.8197 17.0187 45.6489 18.2811C44.478 19.5587 43.885 21.186 43.885 23.1632C43.885 25.1404 44.478 26.783 45.6489 28.0605C46.8197 29.3381 48.2491 29.9769 49.9218 29.9769C51.5944 29.9769 53.0238 29.3381 54.1946 28.0605Z" fill="#FAFAFA" />
        <path d="M82.918 17.3789L82.9332 17.3839H82.918V17.3789Z" fill="#FAFAFA" />
        <path d="M82.918 17.3789C82.4161 17.2148 81.661 17.1252 80.6675 17.1102C78.8732 17.1102 77.459 17.6729 76.425 18.8136C75.391 19.9391 74.8588 21.5664 74.8588 23.6805V35.5131H68.0769V10.8136H74.8588V13.8402C75.0565 13.6121 75.3302 13.3079 75.6951 12.9429C76.0601 12.5779 76.8508 12.0912 78.0673 11.4828C79.2837 10.8745 80.5762 10.5703 81.9144 10.5703H82.918V17.3789Z" fill="#FAFAFA" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M101.013 11.2092C102.23 11.8024 103.097 12.3803 103.614 12.9279L104.374 13.8404V10.8899H111.156V35.5133H104.374V32.4867C104.313 32.578 104.207 32.7148 104.07 32.8669C103.933 33.019 103.629 33.308 103.188 33.7034C102.732 34.0989 102.245 34.4639 101.728 34.7833C101.211 35.1027 100.527 35.3916 99.6905 35.635C98.8542 35.8783 97.9874 36 97.0903 36C93.897 36 91.1143 34.7529 88.7726 32.2738C86.4157 29.7947 85.2449 26.7529 85.2449 23.1636C85.2449 19.5742 86.4157 16.5324 88.7726 14.0533C91.1295 11.5743 93.897 10.3271 97.0903 10.3271C98.4893 10.3271 99.797 10.6161 101.013 11.2092ZM102.61 28.0609C103.796 26.7833 104.374 25.1559 104.374 23.1636C104.374 21.1864 103.781 19.559 102.61 18.2815C101.424 17.0039 100.01 16.3803 98.3372 16.3803C96.6645 16.3803 95.2352 17.0191 94.0643 18.2815C92.8783 19.559 92.3004 21.1864 92.3004 23.1636C92.3004 25.1407 92.8935 26.7833 94.0643 28.0609C95.2504 29.3384 96.6645 29.9772 98.3372 29.9772C99.9946 29.9772 101.424 29.3384 102.61 28.0609Z" fill="#FAFAFA" />
        <path d="M138.678 13.8405C138.876 13.5819 139.149 13.2473 139.514 12.8671C139.879 12.4869 140.67 11.9546 141.886 11.3006C143.103 10.6466 144.395 10.312 145.734 10.312C148.547 10.312 150.858 11.2854 152.637 13.2321C154.416 15.1789 155.298 17.81 155.298 21.1256V35.483H148.516V21.8861C148.516 20.3804 148.075 19.1637 147.178 18.2359C146.281 17.3081 145.14 16.8519 143.726 16.8519C142.191 16.8519 140.959 17.3538 140.046 18.3576C139.134 19.3614 138.678 20.791 138.678 22.6465V35.483H131.896V10.8139H138.678V13.8405Z" fill="#FAFAFA" />
        <path d="M165.668 35.4962H169.505V19.3791H189.558V35.4962H193.394V0.239989H189.558V15.8535H169.505V0.239989H165.668V35.4962Z" fill="#FAFAFA" />
        <path d="M204.184 35.4962H208.021V0.239989H204.184V35.4962Z" fill="#FAFAFA" />
        <path d="M229.819 35.4962H225.982V3.81598H214.626V0.239989H241.124V3.81598H229.819V35.4962Z" fill="#FAFAFA" />
        <path d="M247.747 35.4962H269.999V31.9202H251.583V0.239989H247.747V35.4962Z" fill="#FAFAFA" />
    </svg>

)

export default LogoDbrain
