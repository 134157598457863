import React from 'react'

const ZoomInIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 10V8H8V0H10V8H18V10H10V18H8V10H0Z" fill="currentColor" />
    </svg>
  )
}

export default ZoomInIcon
