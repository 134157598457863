import React from 'react'
import './style.scss'

export interface EffectProps {
  className?: string
  transition?: string
  transitionFinish?: string
  init?: boolean
}

export default class Effect extends React.Component<any, any> {
  el: any
  componentDidMount() {
    this.el.addEventListener('animationend', () => {
      this.transitionFinish()
    })
  }

  componentWillUnmount() {
    this.el.removeEventListener('animationend', () => {
      this.transitionFinish()
    })
  }

  transitionFinish() {
    this.el.classList.remove(`_effect-${this.props.transition}`)
    this.props.transitionFinish(true)
  }

  render() {
    const { init, className, transition = 'shakeShort' } = this.props
    const transitionClassName = init ? `_effect-${transition}` : ''
    const additionalClassName = className ? className : ''

    return (
      <i
        ref={(c) => {
          this.el = c
        }}
        className={`_effect ${transitionClassName} ${additionalClassName}`}
      >
        {this.props.children}
      </i>
    )
  }
}
