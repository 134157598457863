import { useEffect, useState } from 'react'
import style from './CropManager.module.scss'
import './PdfViewer.scss'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import BboxImage, { RecognizeStatsItem } from 'components/BboxedImage/BboxImage'
import { ITaskResultValues } from 'components/Task/types/OcrMultiple/OcrMultiple'

export interface CropManagerProps {
    crop: string
    hoveredItemName: string | null
    items: RecognizeStatsItem[]
    taskResultValues?: ITaskResultValues
}
interface IPayloadByPage {
    items: RecognizeStatsItem[]
    initHeight: number
    initWidth: number
    id: number
}
const getPayloadByPage = (
    flatedItems: RecognizeStatsItem[],
): IPayloadByPage[] => {
    const itemsMap = {}

    flatedItems.forEach((field) => {
        if (!itemsMap[field.page_num]) {
            itemsMap[field.page_num] = {
                items: [field],
                initHeight: field.page_height,
                initWidth: field.page_width,
                id: Math.random(),
            }
        } else {
            itemsMap[field.page_num].items.push(field)
        }
    })
    const itemsByPage: IPayloadByPage[] = []
    Object.keys(itemsMap).forEach(
        (key) => (itemsByPage[Number(key) - 1] = itemsMap[key]),
    )

    return itemsByPage
}

interface IBboxedPage {
    payloadByPage: IPayloadByPage
    pageIndex: number
    hoveredItemName: string | null
    taskResultValues?: ITaskResultValues
}

function BboxedPage({
    payloadByPage,
    pageIndex: index,
    hoveredItemName,
    taskResultValues
}: IBboxedPage) {
    const [isPageLoaded, setIsPageLoaded] = useState(false)

    return (
        <>
            {payloadByPage ? (
                <div key={payloadByPage.id}>
                    <BboxImage
                        key={`bboximage_${payloadByPage.id}`}
                        hoveredItemName={hoveredItemName}
                        crop={''}
                        initHeight={payloadByPage.initHeight}
                        initWidth={payloadByPage.initWidth}
                        items={payloadByPage.items}
                        isPageLoaded={isPageLoaded}
                        taskResultValues={taskResultValues}
                    >
                        <Page
                            key={`page_${payloadByPage.id}`}
                            pageNumber={index + 1}
                            className={style.page}
                            onLoadSuccess={() => {
                                setIsPageLoaded(true)
                            }}
                        />
                    </BboxImage>
                </div>
            ) : (
                <Page
                    key={`page_${index}`}
                    pageNumber={index + 1}
                    className={style.page}
                    onLoadSuccess={() => {
                        setIsPageLoaded(true)
                    }}
                />
            )}
        </>
    )
}

export default function CropManager(props: CropManagerProps) {
    const [numPagesArr, setNumPagesArr] = useState<Array<number>>()
    const [pdfMode, setPdfMode] = useState(false)
    const [payloadByPage, setPayloadByPage] = useState<IPayloadByPage[]>([])

    useEffect(() => {
        setPdfMode(false)
    }, [props.crop])

    function onDocumentLoadSuccess(page: PDFDocumentProxy): void {
        const numPagesArr = Array.from(new Array(page?.numPages))
        setNumPagesArr(
            numPagesArr.map(() => {
                return Math.random()
            }),
        )
        setPayloadByPage(getPayloadByPage(props.items))
    }

    return (
        <>
            {!pdfMode && (
                <BboxImage
                    taskResultValues={props.taskResultValues}
                    key={'BboxImage'}
                    hoveredItemName={props.hoveredItemName}
                    crop={props.crop}
                    items={props.items}
                    onImgError={() => {
                        setPdfMode(true)
                    }}
                />
            )}
            {pdfMode && (
                <Document
                    file={props.crop}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={style.document}
                >
                    {numPagesArr?.map((el, index) => {
                        return (
                            <div className={style.docPage} key={el}>
                                {payloadByPage.length && (
                                    <BboxedPage
                                        payloadByPage={payloadByPage[index]}
                                        pageIndex={index}
                                        hoveredItemName={props.hoveredItemName}
                                        taskResultValues={props.taskResultValues}
                                    />
                                )}
                            </div>
                        )
                    })}
                </Document>
            )}
        </>
    )
}
