import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import history from '../../browserHistory'
import config from '../../config'
import * as authorizationActions from '../Authorization/actions'
import './style.scss'
import Input from '../Form/Input'
import * as validation from '../Form/validate'
import ButtonSubmit from '../Button/ButtonSubmit'
import Form from '../Form'
import Field from '../Form/field'

const bodyClass = 'create-password-page'

export interface CreatePasswordProps {
  t: any
  actions: any
  authorization: any
  i18n: any
}

class CreatePassword extends Component<CreatePasswordProps> {
  form: HTMLFormElement
  email: any
  confirm: any

  componentDidMount() {
    document.body.classList.add(bodyClass)
  }
  componentWillUnmount() {
    document.body.classList.remove(bodyClass)
  }
  handleSubmit(event) {
    event.preventDefault()
    let searchParams = document.location.search.split('?')[1]
    if (!searchParams) {
      history.push(config.routes.default)
      return
    }

    const splitted = searchParams.split('&')
    const form = this.form.getValues()
    delete form.confirm

    form.user_id = splitted
      .filter((item) => item.includes('user'))[0]
      .split('=')[1]
    form.code = splitted
      .filter((item) => item.includes('code'))[0]
      .split('=')[1]

    this.props.actions.createPassword(form)
  }
  render() {
    const { t, i18n, authorization } = this.props
    const errorMessage = authorization.createPassword.error.message ? (
      <div className="field-error field-error-form">
        {authorization.createPassword.error.message}
      </div>
    ) : (
      ''
    )

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-1 sm-hidden" />
          <div className="create-password-page col-3 col-sm-4 col-xs-2">
            <h1>{t('create_password.title')}</h1>

            <Form
              ref={(c) => {
                this.form = c
              }}
              onSubmit={this.handleSubmit.bind(this)}
            >
              {errorMessage}
              <Field>
                <Input
                  autoFocus
                  name="password"
                  type="password"
                  placeholder={t('create_password.new_pass')}
                  validations={[validation.required, validation.gt]}
                  ref={(c) => {
                    this.email = c
                  }}
                  minLength="5"
                  lang={i18n.language}
                />
              </Field>

              <Field>
                <Input
                  type="password"
                  name="confirm"
                  placeholder={t('create_password.confirm')}
                  validations={[validation.required, validation.isEqual]}
                  ref={(c) => {
                    this.confirm = c
                  }}
                  lang={i18n.language}
                />
              </Field>

              <div className="buttons buttons-pull-right">
                <ButtonSubmit className="btn btn-login">
                  {t('create_password.complete')}
                </ButtonSubmit>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(authorizationActions, dispatch),
})
export default withTranslation()(
  connect((state) => state, mapDispatchToProps)(CreatePassword),
)
