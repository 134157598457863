import React from 'react'

const ZoomOutIcon = () => {
  return (
    <svg
      width="20"
      height="4"
      viewBox="0 0 20 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 3V1H19V3H1Z" fill="currentColor" />
    </svg>
  )
}

export default ZoomOutIcon
