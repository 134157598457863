import Button from '../Button'
import Form from '../Form'
// import Checkbox from '../Form/Checkbox'
import Field from '../Form/field'
import Input from '../Form/Input'
import React, { Component } from 'react'
import * as validation from '../Form/validate'

import { Trans } from 'react-i18next'
import config from '../../config'
import '../User/style.scss'
import { Checkbox, CheckboxTheme } from '@dbrainio/shared-ui'
import style from './SignUp.module.css'

export interface SignUpProps {
  t: any
  actions: any
  authorization: any
}

export interface SignUpState {
  termsAccepted?: boolean
}

export default class SignUp extends Component<SignUpProps, SignUpState> {
  form: HTMLFormElement
  email: any
  password: any
  confirm: any

  constructor(props) {
    super(props)

    this.state = {
      termsAccepted: false,
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    this.form.validateAll()
    const errorLength = this.form.getChildContext()._errors.length
    if (errorLength) {
      return
    }

    const user = this.form.getValues()

    delete user.confirm

    user.email = user.email.toLowerCase()

    this.props.actions.signUp(user)
  }

  updateTermsStatus(termsAccepted: boolean) {
    this.setState({ termsAccepted })
  }

  render() {
    const { authorization, actions, t } = this.props
    const errorMessage = authorization.signup.error.message ? (
      <div className="authorization-form-error field-error">
        <Trans i18nKey={`login.error.${authorization.signup.error.id}`}>
          {authorization.signup.error.message}
        </Trans>
      </div>
    ) : (
      ''
    )

    return (
      <div className="authorization-form-wrapper">
        <div className="gap-1" />

        <div className="heading-3-type-1 font-medium">
          {t('login.create.title')}
        </div>

        <div className="gap-1" />

        <Form
          ref={(c) => {
            this.form = c
          }}
          className={
            authorization.signup.isSignupRequest ? 'form-state-loading' : ''
          }
          onSubmit={this.handleSubmit.bind(this)}
        >
          {errorMessage}

          <Field>
            <Input
              placeholder={t('login.email')}
              type="text"
              name="email"
              validations={[validation.required, validation.email]}
              ref={(c) => {
                this.email = c
              }}
            />
          </Field>

          <Field>
            <Input
              type="password"
              name="password"
              placeholder={t('login.password')}
              validations={[validation.required, validation.gt]}
              ref={(c) => {
                this.password = c
              }}
              minLength="5"
            />
          </Field>

          <Field>
            <Input
              type="password"
              name="confirm"
              placeholder={t('login.password_confirm')}
              validations={[validation.required, validation.isEqual]}
              ref={(c) => {
                this.confirm = c
              }}
            />
          </Field>

          <Field className={style.checkboxContainer}>
            <Checkbox
              theme={CheckboxTheme.Black}
              isChecked={this.state.termsAccepted}
              onChange={this.updateTermsStatus.bind(this)}
            />

            <label htmlFor="isTermsConfirmed">
              <span>
                {t('login.create.terms_pre')}

                <a
                  href={config.links.TOS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('login.create.terms')}
                </a>
              </span>
            </label>
          </Field>

          <div className="buttons buttons-pull-right">
            <span
              className="link"
              onClick={() => {
                actions.openAuthSidebar(config.authorizationTabs.login)
              }}
            >
              {t('login.log_in')}
            </span>

            <Button
              className="btn"
              // disabled={!this.state.termsAccepted}
            >
              {t('login.sign_up')}
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}
