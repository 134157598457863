import axios from 'axios'
import config from '../../config'
import { StatusCode, getStatusText } from '../../status_codes'

import * as api from '../Authorization/api'
import * as types from './constants'
import { SHOW_REQUEST_ERROR } from '../Error/constants'
import { parseRequestError } from '../../utils/common'

const { CancelToken } = axios
let fetchMeSource: any = null

export const fetchMe = () => (dispatch) => {
  if (fetchMeSource) {
    fetchMeSource.cancel()
  }
  fetchMeSource = CancelToken.source()
  return api
    .me({ cancelToken: fetchMeSource.token })
    .then((data) => {
      const me = data.data.user

      if (data.data.created_at) {
        me.createdAt = data.data.created_at
      }

      fetchMeSource = null
      dispatch({
        type: types.UPDATE_ME,
        me,
      })
    })
    .catch((error) => {
      fetchMeSource = null
      if (axios.isCancel(error)) {
        return true
      }

      const re = parseRequestError(error)
      const st = getStatusText(re.status, re.id)

      if (
        st === StatusCode.WrongCredentials &&
        (document.location.pathname.includes(config.routes.createPassword) ||
          document.location.pathname.includes(config.routes.default))
      ) {
        return true
      }

      dispatch({
        type: SHOW_REQUEST_ERROR,
        ...re,
      })
      return true
    })
}

export const changeMe = (user) => (dispatch) =>
  api.changeMe(user).then((data) => {
    const me = data.data.user

    if (data.data.created_at) {
      me.createdAt = data.data.created_at
    }

    dispatch({
      type: types.UPDATE_ME,
      me,
    })
  })

export const getUserStat = (userId) => (dispatch) => {
  dispatch({
    type: types.FETCH_STAT_PROCESS,
  })

  return api
    .getUserStat(userId)
    .then((data) => {
      if (data.status === 200 && data.data.stats !== undefined) {
        dispatch({
          type: types.FETCH_STAT_SUCCESS,
          stat: data.data.stats,
        })
        return
      }

      throw new Error('')
    })
    .catch(() => {
      dispatch({
        type: types.FETCH_STAT_FAILED,
      })
    })
}
