import * as types from './constants'

const initialState = {
  isActive: false,
  enabled: false,
  help: null,
}

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case types.HELP_ENABLE:
      return { ...state, enabled: true }
    case types.HELP_DISABLE:
      return { ...state, enabled: false }
    case types.HELP_TOGGLE:
      return { ...state, isActive: !state.isActive }
    case types.HELP_HIDE:
      return { ...state, isActive: false }
    case types.HELP_SET_CONTENT:
      return { ...state, help: action.help }
    default:
      return state
  }
}
