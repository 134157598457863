import * as types from './constants'

export const helpEnable = () => ({
  type: types.HELP_ENABLE,
})

export const helpDisable = () => ({
  type: types.HELP_DISABLE,
})

export const helpToggle = () => ({
  type: types.HELP_TOGGLE,
})

export const helpHide = () => ({
  type: types.HELP_HIDE,
})

export const helpSetContent = (help) => ({
  type: types.HELP_SET_CONTENT,
  help,
})
