// исправляет направление полигона по часовой стрелке
export const fixPolygonDirection = (
  path: any,
  clockwise: any,
  pointMapper?: any,
) => {
  // https://ru.wikipedia.org/wiki/%D0%A4%D0%BE%D1%80%D0%BC%D1%83%D0%BB%D0%B0_%D0%BF%D0%BB%D0%BE%D1%89%D0%B0%D0%B4%D0%B8_%D0%93%D0%B0%D1%83%D1%81%D1%81%D0%B0
  let polygonArea = 0
  const pm = pointMapper || ((p) => p)

  path.forEach((item, i) => {
    const idx = i + 1
    if (idx < path.length) {
      polygonArea += pm(item)[0] * pm(path[i + 1])[1]
    } else {
      polygonArea += pm(item)[0] * pm(path[0])[1]
    }
    if (i !== 0) {
      polygonArea -= pm(item)[0] * pm(path[i - 1])[1]
    } else {
      polygonArea -= pm(item)[0] * pm(path[path.length - 1])[1]
    }
  })

  if (clockwise === polygonArea < 0) {
    // изменение направления
    const _path = [...path].reverse()
    _path.pop()
    return [path[0]].concat(_path)
  }

  return path
}

export const compileAssignmentLabels = (groups, labels) => {
  const result: any[] = []

  if (!groups.length) {
    return [
      {
        labels,
        groupNum: 0,
        group: null,
      },
    ]
  }

  groups.forEach((group, groupNum) => {
    result.push({ group: group.text, groupNum, labels: [] })
  })

  labels.forEach((label) => {
    result[label.group].labels.push(label)
  })

  return result
}
