import React, { Children, ReactNode, cloneElement, useEffect, useRef, useState } from 'react'
import Bbox from '../Bbox/Bbox'
import style from './BboxImage.module.scss'
import MagnifyImage from '../MagnifyImage/MagnifyImage'
import { ITaskResultValues } from 'components/Task/types/OcrMultiple/OcrMultiple'

export interface RecognizeStatsItem {
  id?: string
  label: string
  value: string
  coords: number[][]
  confidence: number
  confidence_is_low: boolean
  page_num?: number
  page_height?: number
  page_width?: number
  edited?: boolean
}

export interface BboxImageProps {
  crop?: string
  hoveredItemName: string | null
  items?: RecognizeStatsItem[],
  initHeight?: number,
  initWidth?: number,
  onImgError?: () => void,
  children?: ReactNode,
  isPageLoaded?: Boolean
  taskResultValues?: ITaskResultValues
}

function useForceUpdate() {
  const [, setValue] = useState(0) // integer state
  return () => setValue((value) => ++value) // update the state to force render
}

export default function BboxImage(props: BboxImageProps) {
  const imageRef = useRef<HTMLImageElement>(null)
  const pageRef = useRef<any>(null)
  const [pageWithRef, setPageWithRef] = useState(null)
  const isPdf = Boolean(pageRef)

  useEffect(() => {
    if (props.children) {
      const child = Children.only(props.children);
      // @ts-ignore
      const pageWithRef = cloneElement(child, { ref: pageRef });
      setPageWithRef(pageWithRef)
    }
  }, [])

  const containerClientHeight = isPdf
    ? pageRef.current?.pageElement?.current?.clientHeight
    : imageRef.current.clientHeight

  const containerClientWidth = isPdf
    ? pageRef.current?.pageElement?.current?.clientWidth
    : imageRef.current.clientWidth

  const imgHeight = isPdf
    ? props.initHeight
    : imageRef.current.naturalHeight

  const imgWidth = isPdf
    ? props.initWidth
    : imageRef.current.naturalWidth

  const current = imageRef.current || pageRef.current
  const hasWidth = !isNaN(imgWidth)

  return (
    <div className={style.root}>
      {current &&
        props.items?.map((item: RecognizeStatsItem, index: number) =>
          item.coords.map((box: number[], subIdx: number) => {
            return hasWidth &&
              <Bbox
                key={item.value + index + subIdx}
                isActive={props.hoveredItemName === item.id}
                coordinates={box.flat()}
                containerClientHeight={containerClientHeight}
                containerClientWidth={containerClientWidth}
                imgHeight={imgHeight}
                imgWidth={imgWidth}
              />
          }),
        )}
      {
        props.crop && <>
          <img
            ref={imageRef}
            src={props.crop}
            alt=''
            className={style.image1}
            onError={props.onImgError}
          />
          <MagnifyImage
            src={props.crop}
            imageClassName={style.image}
          />
        </>
      }
      {
        props.children && <div>
          {pageWithRef}
        </div>
      }
    </div>
  )
}
