import axios from 'axios'
import Raven from 'raven-js'
import config from '../../config'

export function getTask() {
  const { mocks } = config.routes
  const type = Object.keys(mocks).filter(
    (key) => mocks[key] === document.location.pathname,
  )[0]

  const url = type ? `/taskmocks/${type}.json` : `${config.api.web}/assignments`

  return axios.get(url, {
    validateStatus: (status) => status !== 401 || status < 500,
  })
}

export function mark(
  task: any,
  payload: any,
  skip: boolean | undefined,
  time: any,
) {
  const { id, type } = task

  const data: any = {
    id,
    type,
    cancel: skip || false,
    time_taken: time,
  }

  if (payload !== null && typeof payload !== 'undefined') {
    data.payload = payload
  }

  return axios.post(`${config.api.web}/assignments`, data)
}

export function normalizePoints(points) {
  return axios.post(`${config.api.points}/dev/clean-poly`, { points })
}

export function fail(task) {
  Raven.captureMessage('Assignment image failure', {
    level: 'warning',
    extra: { task },
  })

  return axios.post(`${config.api.web}/assignments`, {
    failure: true,
    id: task.id,
    type: task.type,
  })
}

export function collectUniqIdxes(paths) {
  // @ts-ignore
  return [...new Set(Object.keys(paths).map((key) => paths[key].idx))]
}
