import React, { Component } from 'react'
import Combokeys from 'combokeys'
import { v4 as uuidv4 } from 'uuid'
import { idx2color } from '../../../../utils/common'
import config from '../../../../config'
import TaskInputLabelsButtons from '../../components/TaskInputLabelsButtons/TaskInputLabelsButtons'
import Core from './Core/AabbCore'
import './style.scss'
import Effects from '../../../Effects'
import Bttn from '../../../Bttn'
import { ITask, ITasks } from '../../../../@types/commonTypes'
import { isStudioMode } from 'env'

const fix = (x) => Math.min(Math.max(0, x), 1)
const maxInnerFullscreenSize = 90000

export interface AabbProps {
  t: any
  markTask: (payload: any, rects: any) => void
  skipTask: () => void
  tasks: ITasks
  task: ITask
  testLabel?: React.ReactNode
  caption?: React.ReactNode
  description?: React.ReactNode
  fullDescriptionPopup?: React.ReactNode
  isFirstDescriptionShow: boolean
}

class Aabb extends Component<AabbProps, any> {
  imageRef: any
  coreMiddleRef: any
  combokeys: any

  constructor(props: AabbProps) {
    super(props)
    this.state = {
      rects: this.makeRects(props.task.input.text),
      idx: null,
      category: null,
      marksError: false,
      coreError: false,
      isFullscreen: false,
      isMouseDown: false,
      innerFullscreenSize: {
        width: maxInnerFullscreenSize,
        height: maxInnerFullscreenSize,
      },
      imageFullscreenSize: { width: 'auto', height: 'auto' },
      fullscreenOffsetLeft: 0,
      fullscreenOffsetTop: 0,
      zoomRatioPercentage: 1,
    }

    this.imageRef = React.createRef()
    this.coreMiddleRef = React.createRef()
    this.combokeys = new Combokeys(document.documentElement)
  }

  getCategoryIdx(category): number {
    const { labels } = this.props.task.output
    for (let i = 0; i < labels.length; i++) {
      if (labels[i].value === category) {
        return i
      }
    }
    return 0
  }

  makeRects(text?: string | null) {
    const res = {}
    const rects = JSON.parse((text && text.length ? text : ['{}'])[0])
    const categories = Object.keys(rects)
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i]
      const rs = rects[category]
      for (let j = 0; j < rs.length; j++) {
        res[uuidv4()] = this.makeRect(category, rs[j])
      }
    }
    return res
  }

  makeRect(category, rect) {
    const idx = this.getCategoryIdx(category)
    return {
      createdAt: new Date().getTime(),
      height: 0,
      idx: idx,
      label: category,
      new: false,
      rheight: rect[2][1] - rect[0][1],
      rwidth: rect[2][0] - rect[0][0],
      rx: rect[0][0],
      ry: rect[0][1],
      showControls: false,
      showDelete: true,
      style: idx2color(idx),
      width: 0,
      x: 0,
      y: 0,
    }
  }

  componentDidMount() {
    const { labels } = this.props.task.output

    this.setComboKeys()

    if (labels.length === 1) {
      this.handleChooseCategory(labels[0].value, 0)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { labels } = nextProps.task.output

    if (this.props.task.id !== nextProps.task.id) {
      this.setRects(
        nextProps.task.userData || this.makeRects(nextProps.task.input.text),
      )
      this.changeZoomRatio(this.state.zoomRatioPercentage)
    }

    if (this.state.isFullscreen) {
      this.handleZoomChange(this.state.zoomRatioPercentage)
    }

    if (labels.length === 1) {
      this.setState({ idx: 0, category: labels[0].value })
    }

    if (nextProps.isFirstDescriptionShow) {
      this.setState({ isFullscreen: false })
    }
  }

  componentWillUnmount() {
    this.combokeys.detach()
  }

  setComboKeys() {
    const { complete, no_objects: noObjects, skip } = config.hotKeys

    this.combokeys.bind(complete.key, () => {
      this.handleMark('complete')
    })

    this.combokeys.bind(noObjects.key, () => {
      this.handleMark('noobjects', {})
    })

    this.combokeys.bind(skip.key, () => {
      this.handleSkip()
    })
  }

  setRects(rects) {
    this.setState({ rects: rects || {}, category: null, idx: null })
  }

  handleChooseCategory(newCategory, newIndex) {
    const { idx, category } = this.state
    const o: {
      idx?: any
      category?: any
    } = {}
    const newIdx = parseInt(newIndex, 10)

    if (newIdx === idx && newCategory === category) {
      o.idx = null
      o.category = null
    } else {
      o.idx = newIdx
      o.category = newCategory
    }

    this.setState({ ...o })
  }

  handleMark(type: string, _rects?: any) {
    const rects = _rects || this.state.rects
    const { category } = this.state
    const { task } = this.props
    const payload = {}

    if (type === 'complete' && Object.keys(rects).length === 0) {
      const errorType = category ? 'codeError' : 'marksError'

      this.setState({ [errorType]: true })
      return
    }

    if (type === 'noobjects' && Object.keys(this.state.rects).length !== 0) {
      this.setState({ marksError: true })

      return
    }

    task.output.labels.forEach((item) => {
      payload[item.value] = []
    })

    Object.keys(rects).forEach((key) => {
      const r = rects[key]
      const { label } = r
      const x0 = fix(r.rx)
      const y0 = fix(r.ry)
      const x1 = fix(r.rx + r.rwidth)
      const y1 = fix(r.ry + r.rheight)
      const coordinates = [
        [x0, y0],
        [x1, y0],
        [x1, y1],
        [x0, y1],
        [x0, y0],
      ]
      payload[label].push(coordinates)
    })
    this.props.markTask(payload, rects)
  }

  handleSkip() {
    this.props.skipTask()
  }

  handlePathsUpdate(rects) {
    this.setState({ rects })
  }

  clearPaths() {
    const rects = {}
    this.setState({ rects })
  }

  mouseDownHandler(isMouseDown) {
    if (isMouseDown !== this.state.isMouseDown) {
      this.setState({ isMouseDown })
    }
  }

  handleZoomChange(ratio: number, callback?: any) {
    const innerFullscreenSize: {
      width?: any
      height?: any
    } = {}
    const imageFullscreenSize: {
      width?: any
      height?: any
    } = {}

    let fullscreenOffsetLeft = 0
    let fullscreenOffsetTop = 0

    if (this.state.isFullscreen) {
      const { offsetWidth, offsetHeight } = document.body
      const { current } = this.imageRef

      const currentWidth = (current.naturalWidth || current.offsetWidth) * ratio
      const currentHeight =
        (current.naturalHeight || current.offsetHeight) * ratio

      innerFullscreenSize.width = offsetWidth + currentWidth
      innerFullscreenSize.height = offsetHeight + currentHeight
      fullscreenOffsetLeft = currentWidth / 2
      fullscreenOffsetTop = currentHeight / 2
      imageFullscreenSize.width = currentWidth
      imageFullscreenSize.height = currentHeight
    } else {
      innerFullscreenSize.width = maxInnerFullscreenSize
      innerFullscreenSize.height = maxInnerFullscreenSize
    }

    this.setState(
      {
        innerFullscreenSize,
        imageFullscreenSize,
      },
      () => {
        if (callback) callback()

        this.setState({
          fullscreenOffsetLeft,
          fullscreenOffsetTop,
        })
      },
    )
  }

  handleButtonToggleFullscreen(zoomRatioPercentage, fullscreenCallback) {
    this.setState(
      (prev) => ({
        isFullscreen: !prev.isFullscreen,
      }),
      () => {
        this.handleZoomChange(zoomRatioPercentage, fullscreenCallback)
      },
    )
  }

  changeZoomRatio(zoomRatioPercentage: any, callback?: any) {
    this.setState({ zoomRatioPercentage }, () => {
      this.handleZoomChange(zoomRatioPercentage, callback)
    })
  }

  generateExistedLabels() {
    const { rects } = this.state
    const rectsKeys = Object.keys(rects)
    const values: any = []

    rectsKeys.forEach((k) => {
      values.push(rects[k].label)
    })

    // @ts-ignore
    return [...new Set(values)]
  }

  render() {
    const { rects, category, isFullscreen, isMouseDown } = this.state
    const {
      t,
      task,
      tasks,
      testLabel,
      caption,
      description,
      fullDescriptionPopup,
    } = this.props

    const outerButtonDownClassName =
      isFullscreen && isMouseDown && category ? 'hide' : ''

    const skipButton =
      tasks.onboarding_status || isStudioMode ? (
        ''
      ) : (
        <span
          role="button"
          tabIndex={0}
          className="skip"
          onClick={() => this.handleSkip()}
        >
          {t('task.button.skip')}
        </span>
      )

    const bboxes = (
      <div className="core-wrapper">
        <Effects
          transition="shakeShort"
          transitionFinish={() => {
            this.setState({ coreError: false })
          }}
          init={this.state.coreError}
        >
          <Core
            container="svg_container"
            imageUrl={task.input.source[0]}
            imageRef={this.imageRef}
            coreMiddleRef={this.coreMiddleRef}
            minBoxSide={16}
            onPathsUpdate={this.handlePathsUpdate.bind(this)}
            clearPaths={this.clearPaths.bind(this)}
            t={t}
            label={category}
            idx={this.state.idx}
            rectStyle={this.state.idx === null ? {} : idx2color(this.state.idx)}
            rects={rects}
            isFullscreen={isFullscreen}
            innerFullscreenSize={this.state.innerFullscreenSize}
            imageFullscreenSize={this.state.imageFullscreenSize}
            mouseDownHandler={this.mouseDownHandler.bind(this)}
            handleButtonToggleFullscreen={this.handleButtonToggleFullscreen.bind(
              this,
            )}
            fullscreenOffsetLeft={this.state.fullscreenOffsetLeft}
            fullscreenOffsetTop={this.state.fullscreenOffsetTop}
            zoomRatioPercentage={this.state.zoomRatioPercentage}
            changeZoomRatio={this.changeZoomRatio.bind(this)}
          />
        </Effects>
      </div>
    )

    const annotationFullscreenClassName = isFullscreen
      ? 'annotation-fullscreen'
      : ''

    const existedLabels = this.generateExistedLabels()

    return (
      <React.Fragment>
        <div className={`annotation row ${annotationFullscreenClassName}`}>
          <div className="image-wrapper">
            <div className="image col-4 col-sm-4 col-xs-2">{bboxes}</div>
          </div>

          <div className="actions-wrapper">
            <div className="actions-outer">
              <div className="actions col-2 col-sm-4 col-xs-2">
                <div className="actions-inner">
                  {testLabel}
                  {caption}
                  {description}
                  <div className="gap-back sm-hidden" />

                  <div className={`buttons-outer ${outerButtonDownClassName}`}>
                    <TaskInputLabelsButtons
                      id={this.props.task.id}
                      groups={this.props.task.output.groups}
                      labels={this.props.task.output.labels}
                      labelsExisted={existedLabels}
                      labelsActive={[this.state.category]}
                      labelClickHandler={this.handleChooseCategory.bind(this)}
                      error={this.state.marksError}
                      errorEffectFinished={() => {
                        this.setState({
                          marksError: false,
                        })
                      }}
                    />

                    <div className="buttons">
                      <Bttn
                        type="white-on-gray"
                        onClick={(e) => {
                          e.preventDefault()
                          this.handleMark('complete')
                        }}
                      >
                        {t('task.button.complete')}
                      </Bttn>

                      <Bttn
                        type="bordered"
                        onClick={(e) => {
                          e.preventDefault()
                          this.handleMark('noobjects', {})
                        }}
                      >
                        {t('task.button.no_objects')}
                      </Bttn>
                    </div>
                    <div className="second-actions">{skipButton}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {fullDescriptionPopup}
      </React.Fragment>
    )
  }
}

export default Aabb
