import React from 'react'
import './bttn.scss'

export interface BttnProps {
  children: React.ReactNode
  className?: string
  target?: string
  rel?: string
  type?: string
  disabled?: boolean
  href?: string
  size?: string
  onClick?: (e: any) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export type BttnState = any

export default class Bttn extends React.Component<BttnProps, BttnState> {
  static classNamePrefix = 'bttn'

  static classNameGenerator(type, value) {
    if (!value) return ''

    if (typeof value === 'boolean') {
      return `${Bttn.classNamePrefix}-is-${type}`
    } else {
      return `${Bttn.classNamePrefix}-${type}__${value}`
    }
  }

  generateClassNames() {
    const {
      className: additionalClassName,
      disabled,
      size = 'middle',
      type = 'default',
    } = this.props

    return [
      Bttn.classNamePrefix,
      Bttn.classNameGenerator('type', type),
      Bttn.classNameGenerator('size', size),
      Bttn.classNameGenerator('disabled', disabled),
      additionalClassName,
    ].join(' ')
  }

  handleOnClick(e) {
    const { disabled, onClick } = this.props

    if (disabled) {
      e.preventDefault()
      return null
    }

    onClick && onClick(e)
  }

  render() {
    const {
      children,
      type = 'default',
      size = 'middle',
      href = '#',
      className,
      disabled,
      onClick,
      ...props
    } = this.props

    return (
      <a
        className={this.generateClassNames()}
        onClick={(e) => {
          this.handleOnClick(e)
        }}
        // disabled={disabled}
        href={href}
        {...props}
      >
        {children}
      </a>
    )
  }
}
