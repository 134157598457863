import React, { Component } from 'react'
import './right-sidebar.scss'

const bodyClassName = 'overflow-hidden'

export interface RightSidebarProps {
  content: React.ReactNode
  handleClose?: () => void
  isActive?: boolean
  className?: string
}

export type RightSidebarState = any

export default class RightSidebar extends Component<
  RightSidebarProps,
  RightSidebarState
> {
  constructor(props) {
    super(props)

    this.state = {
      isActive: props.isActive || false,
    }

    this.close = this.close.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { isActive } = this.props
    const { isActive: nextIsActive } = nextProps
    if (isActive !== nextIsActive) {
      this.setState({
        isActive: nextIsActive,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isActive && !prevProps.isActive) {
      document.body.classList.add(bodyClassName)
    } else if (!this.props.isActive && prevProps.isActive) {
      document.body.classList.remove(bodyClassName)
    }
  }

  handleClickWrapper(e) {
    if (e.target === e.currentTarget) {
      this.close()
    }
  }

  close() {
    if (typeof this.props.handleClose === 'function') {
      this.props.handleClose()
    }
  }

  render() {
    const { content, handleClose, className } = this.props
    return (
      <div
        className={`
				right-sidebar
				right-sidebar-${className}
				${this.state.isActive ? 'right-sidebar-state-active' : ''}
				`}
      >
        <div
          className="sidebar-override"
          onClick={this.handleClickWrapper.bind(this)}
        />
        <div className="sidebar-inner">
          <div className="sidebar-content">{content}</div>
          {handleClose ? (
            <span
              data-testid="closeSidebarButton"
              role="button"
              tabIndex={0}
              className="icon icon-close"
              onClick={this.close}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}
