const lg = '(max-width: 1200px)'
const md = '(max-width: 892px)'
const sm = '(max-width: 768px)'
const xs = '(max-width: 576px)'

const is = (mq: any) => {
  return window.matchMedia(mq).matches
}

export { lg, md, sm, xs, is }
