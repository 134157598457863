import React, { Component } from 'react'
import Combokeys from 'combokeys'
import { compileAssignmentLabels } from '../../../../utils/tasks'
import config from '../../../../config'
import Effects from '../../../Effects'
import Bttn from '../../../Bttn'

const groupTimeoutCounter = 2000

export interface TaskInputLabelsButtonsProps {
  id: string
  groups: any[]
  labels: any[]
  labelsActive?: any[]
  labelsExisted?: any[]
  labelClickHandler?: (value: any, num: any) => void
  error?: boolean
  errorEffectFinished?: () => void
}

class TaskInputLabelsButtons extends Component<
  TaskInputLabelsButtonsProps,
  any
> {
  public static defaultProps = {
    labelsExisted: [],
    labelsActive: [],
    labelClickHandler: () => {},

    error: false,
    errorEffectFinished: () => {},
  }

  groupTimeout: number | null
  combokeys: Combokeys.Combokeys

  constructor(props) {
    super(props)

    this.state = {
      activeGroup: { num: null, group: null },
    }

    this.groupTimeout = null
    this.combokeys = new Combokeys(document.documentElement)
  }

  componentDidMount() {
    this.bindHotkeys()
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeGroup: nextActiveGroup } = this.state
    const { activeGroup: prevActiveGroup } = prevState
    const { groups } = this.props

    if (prevProps.id !== this.props.id) {
      this.clearActiveGroup()
      this.unbindHotkeys()
      this.bindHotkeys()
    }

    const isGrouped = groups.length > 1

    if (isGrouped) {
      if (
        prevActiveGroup.num === null &&
        prevActiveGroup.group === null &&
        nextActiveGroup.num !== prevActiveGroup.num &&
        nextActiveGroup.group !== prevActiveGroup.group
      ) {
        if (this.groupTimeout) {
          clearTimeout(this.groupTimeout)
        }

        this.groupTimeout = window.setTimeout(() => {
          this.clearActiveGroup()
        }, groupTimeoutCounter)

        this.unbindGroupHotKeys()
      }

      if (nextActiveGroup.group === null && nextActiveGroup.num === null) {
        this.bindGroupHotkeys()
      }
    }
  }

  componentWillUnmount() {
    this.combokeys.detach()
  }

  unbindHotkeys() {
    const { labels } = config.hotKeys

    labels.key.forEach((key) => {
      this.combokeys.unbind(key)
    })
  }

  unbindGroupHotKeys() {
    const { key: keys } = config.hotKeys.labels

    keys.forEach((key) => {
      this.combokeys.unbind(key)
    })

    this.bindGroupLabelHotkeys()
  }

  bindHotkeys() {
    const { groups } = this.props
    const isGrouped = groups.length > 1

    if (isGrouped) {
      this.bindGroupHotkeys()
    } else {
      this.bindLabelHotkeys()
    }
  }

  bindLabelHotkeys() {
    const { labels } = this.props
    const { key: keys } = config.hotKeys.labels

    labels.forEach((label, num) => {
      if (keys[num]) {
        this.combokeys.bind(keys[num], () => {
          this.hotkeyClick(
            label.value,
            label.group !== undefined ? label.group : num,
          )
        })
      }
    })
  }

  bindGroupLabelHotkeys() {
    const { activeGroup } = this.state
    const { labels } = this.props
    const { key: keys } = config.hotKeys.labels
    const groupLabels = labels.filter(
      (label) => label.group === activeGroup.num,
    )

    groupLabels.forEach((label, num) => {
      if (keys[num]) {
        this.combokeys.bind(keys[num], () => {
          this.hotkeyClick(
            label.value,
            label.group !== undefined ? label.group : num,
          )
        })
      }
    })
  }

  bindGroupHotkeys() {
    const { groups } = this.props
    const { key: keys } = config.hotKeys.labels

    groups.forEach((group, num) => {
      this.combokeys.bind(keys[num], () => {
        this.hotkeyGroupClick(group.text, num)
      })
    })
  }

  clearActiveGroup() {
    this.setState({ activeGroup: { group: null, num: null } })
  }

  hotkeyGroupClick(group, num) {
    const activeGroup = { num, group }

    this.setState({ activeGroup })
  }

  hotkeyClick(value, num) {
    const { labelClickHandler } = this.props
    const activeGroup = { num: null, group: null }

    labelClickHandler && labelClickHandler(value, num)
    this.setState({ activeGroup })
  }

  render() {
    const {
      error,
      errorEffectFinished,
      labelsExisted,
      labelsActive,
      groups,
      labels,
    } = this.props

    const { activeGroup } = this.state

    const normalizedLabels = compileAssignmentLabels(groups, labels)
    const isGrouped = normalizedLabels.length > 1

    return (
      <Effects
        transition="shakeShort"
        transitionFinish={() => {
          errorEffectFinished && errorEffectFinished()
        }}
        init={error}
      >
        {normalizedLabels.map((group) => {
          const groupClassList = [
            'buttons',
            'marks',
            activeGroup.group === group.group ? 'grouped__active' : '',
            isGrouped ? 'grouped' : '',
          ].join(' ')

          const header = isGrouped ? (
            <p className="buttons-title text-4-type-1">{group.group}</p>
          ) : (
            ''
          )

          return (
            <div className={groupClassList} key={`group-${group.group}`}>
              {header}

              {group.labels.map((label, labelNum) => {
                const key = `${group.text}-${label.value}`
                const labelGroupNumber = label.group + 1
                const labelNormalNumber = labelNum + 1

                const labelClassList = [
                  'rainbow',
                  labelsExisted && labelsExisted.includes(label.value)
                    ? 'exists'
                    : '',
                  labelsActive && labelsActive.includes(label.value)
                    ? 'pressed'
                    : '',
                ].join(' ')

                const labelPrefix = isGrouped
                  ? `${labelGroupNumber}.${labelNormalNumber}.`
                  : `${labelNormalNumber}.`

                const currentValue = label.value
                const currentNumber = isGrouped ? group.groupNum : labelNum

                return (
                  <Bttn
                    key={key}
                    className={labelClassList}
                    onClick={(e) => {
                      e.preventDefault()
                      this.hotkeyClick(currentValue, currentNumber)
                    }}
                  >
                    {`${labelPrefix} ${label.text}`}
                  </Bttn>
                )
              })}
            </div>
          )
        })}
      </Effects>
    )
  }
}

export default TaskInputLabelsButtons
