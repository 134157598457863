import React, { Component } from 'react'
import './grid.scss'

export interface GridProps {}
export interface GridState {
  active: boolean
}

export default class Grid extends Component<GridProps, GridState> {
  constructor(props) {
    super(props)
    this.toggleActive = this.toggleActive.bind(this)
    this.state = { active: false }
  }

  toggleActive() {
    this.setState((prevState) => ({
      active: !prevState.active,
    }))
  }

  componentDidMount() {
    document.onkeydown = (e) => {
      if (e.shiftKey && e.altKey && e.keyCode === 'G'.charCodeAt(0)) {
        this.toggleActive()
        return false
      }
    }
  }

  render() {
    const active = this.state.active ? 'state-active' : ''
    return (
      <div className={active + ' grid'}>
        <div className="grid-container row">
          <div className="grid-col col-1 sm-hidden" />
          <div className="grid-col col-1 col-sm-1 xs-hidden" />
          <div className="grid-col col-1 col-sm-1 col-xs-1" />
          <div className="grid-col col-1 col-sm-1 col-xs-1" />
          <div className="grid-col col-1 col-sm-1 xs-hidden" />
          <div className="grid-col col-1 sm-hidden" />
        </div>
      </div>
    )
  }
}
