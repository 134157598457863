import React from 'react'
import { Loader } from '@dbrainio/shared-ui'
import style from './MagnifyImage.module.css'
import Lightbox from 'react-image-lightbox'
import classnames from 'classnames'
import MagnifyIcon from 'components/MagnifyIcon/MagnifyIcon'
import ReactImageMagnify from 'react-image-magnify'
export interface MagnifyImageProps {
  src: string
  isMagnifier?: boolean
  className?: string
  imageClassName?: string
}

export interface MagnifyImageState {
  isLoaded: boolean
  isLightBox: boolean
}

class MagnifyImage extends React.PureComponent<
  MagnifyImageProps,
  MagnifyImageState
> {
  img: HTMLImageElement

  constructor(props: MagnifyImageProps) {
    super(props)

    this.img = new Image()
  }

  state: MagnifyImageState = {
    isLoaded: false,
    isLightBox: false,
  }

  componentDidMount() {
    const { src } = this.props
    this.img.addEventListener('load', this.handleLoad)
    this.img.src = src
  }

  componentWillUnmount() {
    this.img.removeEventListener('onload', this.handleLoad)
  }

  handleLoad = () => {
    this.setState({
      isLoaded: true,
    })
  }

  renderImage = () => {
    const { src, imageClassName, isMagnifier } = this.props
    return (
      <div
        className={style.imageContainer}
        onClick={() => this.setState({ isLightBox: true })}
      >
        <div className={style.icon}>
          <MagnifyIcon />
        </div>

        <ReactImageMagnify
          imageClassName={classnames(style.image, imageClassName)}
          style={{
            height: 'auto',
          }}
          enlargedImageContainerDimensions={{
            width: 400,
            height: 300,
          }}
          enlargedImageContainerStyle={{
            zIndex: 1000,
            backgroundColor: '#fff',
          }}
          {...{
            smallImage: {
              isFluidWidth: true,
              width: 500,
              src,
            },
            largeImage: {
              src,
              width: this.img.naturalWidth * 1.3,
              height: this.img.naturalHeight * 1.3,
            },
          }}
        />
      </div>
    )
  }

  render() {
    const { isLoaded } = this.state
    const { src, className } = this.props

    return (
      <div className={classnames(style.root, className)}>
        {this.state.isLightBox && (
          <Lightbox
            mainSrc={src}
            onCloseRequest={() => this.setState({ isLightBox: false })}
          />
        )}
        {isLoaded ? (
          this.renderImage()
        ) : (
          <div className={style.loader}>
            <Loader color="#111111" size={16} />
          </div>
        )}
      </div>
    )
  }
}

export default MagnifyImage
