import validator from 'validator'

const errors = {
  en: {
    required: 'This field is required.',
    email: 'Email is not valid.',
    password: 'Use only alphanumeric characters.',
    currency: 'Amount is not valid.',
    minAmount: 'Min amount :length.',
    gt: 'Min length is :length symbols.',
    lt: 'The value exceeded :length symbols.',
    etherium:
      'Wallet address should start with "0x" prefix and consist of "abcdef0123456789" characters and have length 40.',
    equal: 'Passwords are not equal.',
  },
  ru: {
    required: 'Поле обязательно к заполнению.',
    email: 'Электронная почта недействительна.',
    password: 'Пароль содержит некорректные символы.',
    currency: 'Сумма не действительна.',
    minAmount: 'Минимальная сумма :length.',
    gt: 'Минимальная длина :length символов.',
    lt: 'Превышено значение в :length символов.',
    etherium:
      'Адрес кошелька должен начинаться с префикса «0x» и состоять из символов «abcdef0123456789» и иметь длину 40.',
    equal: 'Пароли не совпадают.',
  },
  zh: {
    required: '此项为必填项。',
    email: '电子邮件无效。',
    password: 'Use only alphanumeric characters.',
    currency: '金额无效。',
    gt: '至少:length个符号',
    lt: 'The value exceeded :length symbols.',
    etherium:
      '钱包地址应以“0x”开始，包含“abcdef0123456789”中的任意字符，长度为40个字符。',
    equal: '密码不一致。',
  },
}

/* eslint-disable */

export function required(value, props) {
  if (
    !value ||
    (value && !value.toString().trim().length) ||
    (props.isCheckable && !props.checked)
  ) {
    const lang = props.lang || 'en' || 'zh'
    return errors[lang].required
  }
}

export function email(value, props) {
  if (!validator.isEmail(value.toString().trim())) {
    const lang = props.lang || 'en'
    return errors[lang].email
  }
}

export function password(value, props) {
  if (validator.isMultibyte(value.toString().trim())) {
    const lang = props.lang || 'en'
    return errors[lang].password
  }
}

export function currency(value, props) {
  if (!validator.isCurrency(value.toString().trim())) {
    const lang = props.lang || 'en'
    return errors[lang].currency
  }
}

export function lt(value, props) {
  if (!value.toString().trim().length > props.maxLength) {
    const lang = props.lang || 'en'
    return errors[lang].lt.replace(':length', props.maxLength)
  }
}

export function gt(value, props) {
  if (value.toString().trim().length < parseInt(props.minLength, 10)) {
    const lang = props.lang || 'en'
    return errors[lang].gt.replace(':length', props.minLength)
  }
}

export function minAmount(value, props) {
  if (
    Number(value.toString().trim()) < parseInt(props['data-min-amount'], 10)
  ) {
    const lang = props.lang || 'en'
    return errors[lang].minAmount.replace(':length', props['data-min-amount'])
  }
}

export function ethereum(value, props) {
  const val = value.toString().trim()
  if (!val) {
    return
  }
  if (!/^0x[a-fA-F0-9]{40}$/.test(val)) {
    const lang = props.lang || 'en'
    return errors[lang].etherium
  }
}

export function isEqual(value, props, components) {
  const bothUsed = components.password[0].isUsed && components.confirm[0].isUsed
  const bothChanged =
    components.password[0].isChanged && components.confirm[0].isChanged

  if (
    bothChanged &&
    bothUsed &&
    components.password[0].value !== components.confirm[0].value
  ) {
    const lang = props.lang || 'en'
    return errors[lang].equal
  }
}
