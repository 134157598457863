import * as types from './constants'

export const errorShow = (message, status) => ({
  type: types.SHOW_REQUEST_ERROR,
  message,
  status,
})

export const errorHide = () => ({
  type: types.HIDE_REQUEST_ERROR,
})
