import * as types from './constants'

export interface TaskInitialState {
  resolvedTasks: any[]
  assignments: any[]
  onboarding_status: any
  isTasksLoading: boolean
  isMarkTaskLoading: boolean
  expiration_timeout: number
}
const initialState: TaskInitialState = {
  resolvedTasks: [],
  assignments: [],
  onboarding_status: null,
  isTasksLoading: false,
  isMarkTaskLoading: false,
  expiration_timeout: 0,
}

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case types.GET_TASKS_REQUEST:
      return { ...state, isTasksLoading: true }

    case types.GET_TASKS_SUCCESS: {
      const assignments = action.assignments.filter((item) => {
        let newItem = true
        if (
          state.resolvedTasks.filter((task: any) => task.id === item.id).length
        ) {
          newItem = false
        } else {
          state.assignments.forEach((task: any) => {
            if (task.id === item.id) {
              newItem = false
            }
          })
        }
        return newItem
      })

      return {
        ...state,
        isMock: action.isMock,
        expiration_timeout: action.expiration_timeout,
        assignments: state.assignments.concat(assignments),
        onboarding_status: action.onboarding_status,
        isTasksLoading: false,
      }
    }
    case types.GET_TASKS_ERROR:
      return {
        ...state,
        isTasksLoading: true,
      }

    case types.MARK_TASK_REQUEST: {
      let onboardingStatus = initialState.onboarding_status
      if (state.onboarding_status) {
        onboardingStatus = {
          ...state.onboarding_status,
          assignments_completed:
            state.onboarding_status.assignments_completed + 1,
        }
      }
      const resolvedTask = { ...action.task }
      resolvedTask.userData = action.userData
      return {
        ...state,
        onboarding_status: onboardingStatus,
        resolvedTasks: state.resolvedTasks.concat(resolvedTask),
        assignments: state.assignments.filter(
          (task) => task.id !== action.task.id,
        ),
        isMarkTaskLoading: true,
      }
    }

    case types.LOAD_PREV_TASK: {
      const resolvedTasks = [...state.resolvedTasks]
      const prevTask = resolvedTasks.pop()
      return {
        ...state,
        resolvedTasks,
        assignments: [prevTask].concat([...state.assignments]),
      }
    }

    case types.MARK_TASK_SUCCESS: {
      return {
        ...state,
        isMarkTaskLoading: false,
      }
    }

    case types.FAIL_LOAD_TASK: {
      const assignments = [...state.assignments]
      state.assignments.forEach((task, i) => {
        if (task.id === action.id) {
          assignments.splice(i, 1)
        }
      })
      return {
        ...state,
        assignments,
      }
    }

    case types.POINTS_NORMALIZER_SUCCESS:
      return {
        ...state,
        normalPaths: action.normalizedPaths,
      }

    case types.POINTS_NORMALIZER_FAILED:
    case types.POINTS_NORMALIZER_REQUEST:
      return { ...state }

    case types.RESET_TASKS:
      return initialState

    default:
      return state
  }
}
