import Button from '../Button'
import Form from '../Form'
import Field from '../Form/field'
import Input from '../Form/Input'
import React, { Component } from 'react'
import { Trans } from 'react-i18next'
import config from '../../config'
import * as validation from '../Form/validate'
import './style.scss'

export default class Recovery extends Component<any, any> {
  form: HTMLFormElement
  email: HTMLElement

  handleSubmit(event) {
    event.preventDefault()
    this.form.validateAll()
    const errorLength = this.form.getChildContext()._errors.length
    if (errorLength) {
      return
    }
    const form = this.form.getValues()
    this.props.actions.sendRecoveryEmail(form)
  }

  renderForm() {
    const { authorization, t } = this.props
    const errorMessage = authorization.recovery.error.message ? (
      <div className="authorization-form-error field-error">
        <Trans i18nKey={`login.error.${authorization.recovery.error.id}`}>
          {authorization.recovery.error.message}
        </Trans>
      </div>
    ) : (
      ''
    )

    return (
      <React.Fragment>
        <div className="gap-1" />

        <div className="heading-3-type-1 font-medium">
          {t('login.recovery.title')}
        </div>

        <span
          dangerouslySetInnerHTML={{
            __html: t('login.recovery.description'),
          }}
        />

        <div className="gap-1" />

        <Form
          ref={(c) => {
            this.form = c
          }}
          className={
            authorization.recovery.isRecoveryRequest ? 'form-state-loading' : ''
          }
          onSubmit={this.handleSubmit.bind(this)}
        >
          {errorMessage}

          <Field>
            <Input
              data-testid="emailVerifyInput"
              name="email"
              type="text"
              placeholder={t('login.email')}
              validations={[validation.required, validation.email]}
              ref={(c) => {
                this.email = c
              }}
            />
          </Field>

          <div className="buttons buttons-pull-right">
            <Button data-testid="emailVerifySubmit" className="btn btn-login">
              {t('login.continue')}
            </Button>
          </div>
        </Form>
      </React.Fragment>
    )
  }

  renderSuccess() {
    const { authorization, actions, t } = this.props
    const { email } = authorization.recovery
    const emailText = `${t('login.recovery_success.description')} ${email}`

    return (
      <React.Fragment>
        <div className="gap-back" />
        <div className="text-2-type-1 font-medium">
          {t('login.recovery_success.title')}
        </div>
        <div
          className="text-4-type-2 font-regular"
          dangerouslySetInnerHTML={{ __html: emailText }}
        />
        <div className="gap-back" />
        <div className="buttons">
          <span
            data-testid="emailVerifySuccess"
            className="btn"
            onClick={() => {
              actions.openAuthSidebar(config.authorizationTabs.login)
            }}
          >
            {t('login.recovery_success.button')}
          </span>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { authorization } = this.props
    const { ismEmailSended } = authorization.recovery
    return (
      <div className="authorization-form-wrapper">
        {ismEmailSended ? this.renderSuccess() : this.renderForm()}
      </div>
    )
  }
}
