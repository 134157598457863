import * as types from './constants'

const initialState = {
  process: false,
  completed: false,
  error: false,
}

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_TOS: {
      return {
        ...state,
        error: false,
        process: true,
        completed: false,
      }
    }

    case types.UPDATE_TOS_FINISH: {
      return {
        ...state,
        completed: true,
        error: false,
        process: false,
      }
    }

    case types.UPDATE_TOS_ERROR: {
      return {
        ...state,
        error: true,
        completed: false,
        process: false,
      }
    }

    default:
      return state
  }
}
