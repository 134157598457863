import React, { PureComponent } from 'react'

import './style.scss'

export interface DropdownMenuProps {
  isOpen: boolean
  children: any
  className?: string
}

class DropdownMenu extends PureComponent<any, any> {
  public static defaultProps = {
    className: '',
  }

  render() {
    const { isOpen, children, className, ...props } = this.props

    const openClassname = isOpen ? 'dropdown-open' : ''

    return (
      <div
        className={['dropdown', className, openClassname].join(' ')}
        {...props}
      >
        {this.props.children}
      </div>
    )
  }
}

export default DropdownMenu
