import React, { Component, Fragment } from 'react'
import Combokeys from 'combokeys'
import { v4 as uuidv4 } from 'uuid'
import {
  getSelectedNode,
  clearHightlightedText,
} from '../../../../utils/common'
import TaskInputLabelsButtons from '../../../Task/components/TaskInputLabelsButtons/TaskInputLabelsButtons'

import TextCategorizationFragment from './fragment'
import './style.scss'
import Bttn from '../../../Bttn'
import { ITask, ITasks } from '../../../../@types/commonTypes'
import { isStudioMode } from 'env'
// import { TextAnnotator, TokenAnnotator } from 'react-text-annotate'

export interface TextCategorizationProps {
  tasks: ITasks
  t: any
  task: ITask
  markTask: (payload: any, rects: any, flag: boolean) => void
  skipTask: () => void
  testLabel?: React.ReactNode
  fullDescriptionPopup?: React.ReactNode
  caption?: React.ReactNode
  description?: React.ReactNode
}

export interface RangeListItem {
  category: string
  selectionIdx: string
  id: string
  range?: {
    start: number
    finish: number
  }
}

export interface TextCategorizationState {
  activeCategory: string | null
  value: {
    start: number
    end: number
    tag: string
  }[]
  activeGroup: string | null
  rangeList: RangeListItem[]
  selectionId: string | null
  marksError: boolean
  mouseDown: boolean
}

// const rainbow = [
//   '#ff303f',
//   '#139eef',
//   '#f99018',
//   '#12edba',
//   '#ff44c0',
//   '#212fff',
//   '#fccc20',
//   '#bc19fc',
//   '#14e814',
//   '#e04a02',
//   '#02c5e0',
//   '#fbab84',
//   '#5a135b',
//   '#b6000d',
//   '#99c224',
// ]

class TextCategorization extends Component<
  TextCategorizationProps,
  TextCategorizationState
> {
  combokeys: Combokeys.Combokeys
  groupTimeout: number | null
  textContainer: HTMLDivElement | null
  textItem: HTMLElement | null

  constructor(props) {
    super(props)

    this.state = {
      rangeList: [],
      value: [],
      selectionId: null,
      activeCategory: null,
      activeGroup: null,
      marksError: false,
      mouseDown: false,
    }

    this.combokeys = new Combokeys(document.documentElement)
    this.groupTimeout = null
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onMouseDown.bind(this))
    document.addEventListener('mouseup', this.onMouseUp.bind(this))
  }

  componentWillReceiveProps(next) {
    if (this.props.task.id !== next.task.id) {
      this.setState({
        activeCategory: null,
        activeGroup: null,
        rangeList: [],
      })
    }
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.activeCategory && this.state.activeCategory) {
      this.handleSelection()
    }

    if (prevState.rangeList.length !== this.state.rangeList.length) {
      this.updateRangeList()
    }
  }

  componentWillUnmount() {
    this.combokeys.detach()
  }

  onMouseDown(e) {
    const { activeCategory } = this.state
    const isTarget =
      e.target === this.textItem || e.target === this.textContainer

    if (!activeCategory && isTarget) {
      this.setState({ marksError: true })
      return
    }

    if (activeCategory && isTarget) {
      this.generateNewRange()
    }
  }

  onMouseUp() {
    const { activeCategory, selectionId } = this.state
    const selection = window.getSelection()

    if (selection && activeCategory && selectionId && selection.rangeCount) {
      this.updateRangeList()
    }
  }

  onFragmentRemove(id) {
    this.setState((prev) => {
      const rangeList = Object.assign([], prev.rangeList)

      rangeList.forEach((r, num) => {
        if (r.id === id) {
          rangeList.splice(num, 1)
        }
      })

      return {
        rangeList,
      }
    })
  }

  updateRangeList() {
    const { selectionId } = this.state
    const selection = window.getSelection()

    if (!selection || selection.rangeCount === 0) return

    const { startOffset: start, endOffset: finish } = selection.getRangeAt(0)
    const range =
      start > finish ? { finish: start, start: finish } : { start, finish }

    this.setState((prev: TextCategorizationState) => {
      const rangeList = Object.assign([], prev.rangeList)

      rangeList.forEach((r, num) => {
        if (r.id === selectionId) {
          if (
            range.start !== range.finish &&
            range.start >= 0 &&
            range.finish >= 0
          ) {
            r.range = range
          } else {
            rangeList.splice(num, 1)
          }
        }
      })

      rangeList.forEach((r, num) => {
        let counter = 0

        rangeList.forEach((localr) => {
          if (
            r.range.start === localr.range.start &&
            r.range.finish === localr.range.finish
          ) {
            if (counter === 0) {
              counter += 1
            } else {
              rangeList.splice(num, 1)
            }
          }
        })
      })

      return {
        rangeList,
        mouseDown: false,
        selectionId: null,
      }
    })

    clearHightlightedText()
  }

  generateNewRange() {
    const { activeCategory: currentCategory, activeGroup: currentGroup } =
      this.state
    const selectionId = uuidv4()
    const rangeItem = {
      category: currentCategory,
      selectionIdx: currentGroup,
      id: selectionId,
    }

    this.setState((prev: TextCategorizationState) => ({
      selectionId,
      rangeList: [...prev.rangeList, rangeItem],
      mouseDown: true,
    }))
  }

  handleSelection() {
    const selection = window.getSelection()
    const target = getSelectedNode()

    if (selection?.rangeCount || target === this.textItem) {
      this.generateNewRange()
    }
  }

  handleChooseCategory(activeCategory, activeGroup) {
    clearHightlightedText()

    this.setState({ activeCategory, activeGroup })
  }

  handleMark(status) {
    const { markTask, task } = this.props
    const { rangeList } = this.state
    const { labels: values } = task.output
    const res = {}

    if (
      (status === 'complete' && !rangeList.length) ||
      (status === 'noobjects' && rangeList.length)
    ) {
      this.setState({ marksError: true })
      return
    }

    values.forEach((v) => {
      res[v.value] = []
    })

    if (status === 'complete') {
      rangeList
        .filter((r) => r.range && r.range.start >= 0 && r.range.finish >= 0)
        .forEach((r) => res[r.category].push([r.range.start, r.range.finish]))
    }

    markTask(res, res, false)
  }

  handleSkip() {
    this.props.skipTask()
  }

  generateHighlitedRanges() {
    const { rangeList } = this.state
    const text = this.props.task.input.source[0]

    if (!rangeList.length) {
      return ''
    }

    const existedRanges = rangeList.filter(
      (r) => r.range && r.range.start >= 0 && r.range.finish >= 0,
    )
    const parentClientRect =
      this.textContainer && this.textContainer.getBoundingClientRect()

    return existedRanges.map((item) => (
      <TextCategorizationFragment
        key={item.id}
        id={item.id}
        range={item.range}
        text={text}
        index={Number.parseInt(item.selectionIdx)}
        parent={parentClientRect}
        onRemove={this.onFragmentRemove.bind(this)}
      />
    ))
  }

  generateInput() {
    const { activeGroup, activeCategory } = this.state
    const textValue = this.props.task.input.source[0]
    const textItemIndex = activeGroup !== null ? activeGroup + 1 : 0
    // const { rangeList } = this.state

    const textItemClassName = [
      'text-value-main',
      textItemIndex ? `text-value-main__selection-${textItemIndex}` : '',
    ].join(' ')

    return (
      <div className="image image-textcategorization col-4 col-sm-4 col-xs-2">
        <div className="text-value">
          <div
            className="text-value-wrapper"
            ref={(c) => {
              this.textContainer = c
            }}
          >
            <p
              className={textItemClassName}
              tabIndex={-1}
              ref={(c) => {
                this.textItem = c
              }}
            >
              {textValue}
            </p>
            {/* <TextAnnotator
              content={textValue}
              value={this.state.value}
              onChange={(value) => {
                console.log(
                  '🚀 ~ file: TextCategorization.tsx ~ line 345 ~ generateInput ~ value',
                  value,
                )

                return this.setState({
                  value,
                })
              }}
              getSpan={(span) => ({
                ...span,
                tag: null,
                color:
                  rainbow[Number.parseInt(activeCategory.split('lbl')[1]) - 1],
              })}
            /> */}
            {this.generateHighlitedRanges()}
          </div>
        </div>
      </div>
    )
  }

  generateLabelsList() {
    const { rangeList } = this.state
    // @ts-ignore
    return [...new Set(rangeList.map((r) => r.category))]
  }

  render() {
    const {
      t,
      tasks,
      skipTask,
      testLabel,
      caption,
      description,
      fullDescriptionPopup,
    } = this.props

    const skipButton =
      tasks.onboarding_status || isStudioMode ? (
        ''
      ) : (
        <span
          role="button"
          tabIndex={0}
          className="skip"
          onClick={() => skipTask()}
        >
          {t('task.button.skip')}
        </span>
      )

    const existedLabels = this.generateLabelsList()

    return (
      <Fragment>
        <div className="annotation row">
          <div className="image-wrapper">{this.generateInput()}</div>

          <div className="actions-wrapper">
            <div className="actions-outer">
              <div className="actions col-2 col-sm-4 col-xs-2">
                <div className="actions-inner">
                  {testLabel}
                  {caption}
                  {description}
                  <div className="gap-back sm-hidden" />
                  <div className="buttons-outer">
                    <TaskInputLabelsButtons
                      id={this.props.task.id}
                      groups={this.props.task.output.groups}
                      labels={this.props.task.output.labels}
                      labelsActive={[this.state.activeCategory]}
                      labelsExisted={existedLabels}
                      labelClickHandler={this.handleChooseCategory.bind(this)}
                      error={this.state.marksError}
                      errorEffectFinished={() => {
                        this.setState({
                          marksError: false,
                        })
                      }}
                    />

                    <div className="buttons">
                      <Bttn
                        type="white-on-gray"
                        onClick={(e) => {
                          e.preventDefault()
                          this.handleMark('complete')
                        }}
                      >
                        {t('task.button.complete')}
                      </Bttn>

                      <Bttn
                        type="bordered"
                        onClick={(e) => {
                          e.preventDefault()
                          this.handleMark('noobjects')
                        }}
                      >
                        {t('task.button.not_applicable')}
                      </Bttn>
                    </div>
                  </div>
                  <div className="second-actions">{skipButton}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {fullDescriptionPopup}
      </Fragment>
    )
  }
}

export default TextCategorization
