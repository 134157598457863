import React, { Component, SyntheticEvent } from 'react'
import Combokeys from 'combokeys'
import { v4 as uuidv4 } from 'uuid'
import {
  offset,
  getX,
  getY,
  setNoScroll,
  path2svg,
  taskPathToArray,
  denormalizeArray,
} from '../../../../../utils/common'
import config from '../../../../../config'
import { fixPolygonDirection } from '../../../../../utils/tasks'
import TaskSourceControls from '../../../components/TaskSourceControls/TaskSourceControls'
import FullscreenIcon from '../../../../Icons/FullscreenIcon/FullscreenIcon'
import CutAreaIcon from '../../../../Icons/CutAreaIcon/CutAreaIcon'
import ZoomInIcon from '../../../../Icons/ZoomInIcon/ZoomInIcon'
import ZoomOutIcon from '../../../../Icons/ZoomOutIcon/ZoomOutIcon'
import FullscreenCloseIcon from '../../../../Icons/FullscreenCloseIcon/FullscreenCloseIcon'
import debounce from 'lodash/debounce'

import '../style.scss'
import Button from '../../../../Button'
import Scroller from '../../../../Scroller'
import Tooltip from '../../../../Tooltip'
import { isStudioMode } from '../../../../../env'

export interface RlassoCoreProps {
  t: any
  container: string
  coreref?: any
  imageUrl?: string
  paths?: any
  label?: string
  idx?: number
  clearPaths?: any
  pathStyle: any
  onPathsUpdate: any
  normalizePoints: any
  normalizedPoints?: any
  imageRef: any
  coreMiddleRef: any
  mouseDownHandler: any
  innerFullscreenSize: any
  handleButtonToggleFullscreen: any
  isFullscreen?: boolean
  imageFullscreenSize: any
  fullscreenOffsetLeft: number
  fullscreenOffsetTop: number
  zoomRatioPercentage: number
  changeZoomRatio: any
  handleMark: any
}

export default class Core extends Component<RlassoCoreProps, any> {
  maxZoom: number
  minZoom: number
  zoomStep: number
  combokeys: Combokeys.Combokeys
  svgarea: any

  _onResize: () => void
  _mouseup: null | ((e?: SyntheticEvent) => void)
  __mouseup: null | ((e?: SyntheticEvent) => void)
  _mousemove: null | ((e?: SyntheticEvent) => void)
  __mousemove: null | ((e?: SyntheticEvent) => void)

  constructor(props) {
    super(props)

    this.state = {
      paths: this.props.paths || {},
      holeModeOn: false,
      hoverPathId: null,
      pathId: null,
      isFullscreenTooltipShown: false,
    }

    this.maxZoom = 2
    this.minZoom = 0.5
    this.zoomStep = 0.1

    this._onMouseMove = this._onMouseMove.bind(this)
    this._onMouseUp = this._onMouseUp.bind(this)
    this.combokeys = new Combokeys(document.documentElement)
  }

  componentWillMount() {
    this._onResize = debounce(this.setResolution.bind(this), 250)
    window.addEventListener('resize', this._onResize)
    document.addEventListener('mousemove', this._onMouseMove)
    document.addEventListener('mouseup', this._onMouseUp)
    document.addEventListener('touchmove', this._onMouseMove)
    document.addEventListener('touchend', this._onMouseUp)

    this.combokeys.bind('esc', () => {
      this.handleEscKeyPress()
    })
  }

  componentDidMount() {
    this.setResolution()
    this.setCombokeys()
  }

  componentWillReceiveProps(nextProps) {
    const equal =
      JSON.stringify(Object.keys(nextProps.paths)) ===
      JSON.stringify(Object.keys(this.props.paths))
    if (!equal) {
      this._mouseup = null
      this.setState({ paths: nextProps.paths })
    }

    const nextNP = nextProps.normalizedPoints
    const lastNP = this.props.normalizedPoints
    if (!lastNP || !nextNP) return

    if (
      nextNP &&
      Object.keys(nextNP).toString() !== Object.keys(lastNP).toString()
    ) {
      this.rerenderPath(nextNP)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onResize)
    document.removeEventListener('mousemove', this._onMouseMove)
    document.removeEventListener('mouseup', this._onMouseUp)
    document.removeEventListener('touchmove', this._onMouseMove)
    document.removeEventListener('touchend', this._onMouseUp)
    this.combokeys.detach()
  }

  setCombokeys() {
    const { fullscreen } = config.hotKeys

    this.combokeys.bind(fullscreen.key, () => {
      this.props.handleButtonToggleFullscreen(1, this.setResolution.bind(this))
    })
  }

  setResolution() {
    const { paths } = this.state
    Object.keys(paths).forEach((pathId) => {
      if (Object.prototype.hasOwnProperty.call(paths, pathId)) {
        this._fixPath(paths[pathId], true)
      }
    })
    this.setState({ paths })
  }

  rerenderPath(object) {
    const size = [
      this.svgarea.clientWidth || this.svgarea.parentNode.clientWidth,
      this.svgarea.clientHeight || this.svgarea.parentNode.clientHeight,
    ]

    const { paths } = this.state

    Object.keys(object).forEach((id) => {
      const current = object[id]
      const localPath = denormalizeArray(current, size)

      paths[id].path = localPath
      this.rebuildRects(localPath, id)
    })

    this.setState({ paths })
    this.props.onPathsUpdate(paths, null)
  }

  rebuildRects(path, id) {
    const d = path2svg(0, 0, path, false)
    const { paths } = this.state

    // if (!path.holeOf) {
    // 	this[id].querySelector('path').setAttribute('d', d.path)
    // }

    paths[id].path = path
    this.setState({ paths })
    this.props.onPathsUpdate(paths, null)
  }

  handleEscKeyPress() {
    const { pathId, paths } = this.state

    delete paths[pathId]
    this.setState({
      paths,
      pathId: null,
      holeModeOn: false,
      hoverPathId: null,
    })
  }

  _onMouseMove(e) {
    if (this._mousemove) {
      this._mousemove(e)
    }
  }

  _onMouseUp(e) {
    if (this._mouseup) {
      this._mouseup(e)
    }
  }

  closePath(pathId, event) {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }

    const { paths } = this.state

    if (pathId) {
      if (paths[pathId].path.length < 3) {
        this.deletePath(pathId, event)
      } else {
        paths[pathId].closed = true
        paths[pathId].createdAt = +new Date()
        paths[pathId].showDelete = true
        this._mousemove = null
        this._fixPath(paths[pathId])
        this.setState({ paths, pathId: null })

        const pathArray = taskPathToArray(paths[pathId].path)
        this.props.normalizePoints(pathArray, pathId)
      }
    }
  }

  _fixPath(path: any, reverse?: any) {
    const w = this.props.imageRef.current.offsetWidth
    const h = this.props.imageRef.current.offsetHeight
    let x1 = w + 1
    let y1 = h + 1
    let x2 = -1
    let y2 = -1
    for (let i = 0; i < path.path.length; i += 1) {
      if (reverse) {
        path.path[i].rx = Math.min(Math.max(path.path[i].rx, 0), 1)
        path.path[i].ry = Math.min(Math.max(path.path[i].ry, 0), 1)
        path.path[i].x = path.path[i].rx * w
        path.path[i].y = path.path[i].ry * h
      } else {
        path.path[i].x = Math.min(Math.max(path.path[i].x, 0), w)
        path.path[i].y = Math.min(Math.max(path.path[i].y, 0), h)
        path.path[i].rx = path.path[i].x / w
        path.path[i].ry = path.path[i].y / h
      }
      x1 = Math.min(x1, path.path[i].x)
      y1 = Math.min(y1, path.path[i].y)
      x2 = Math.max(x2, path.path[i].x)
      y2 = Math.max(y2, path.path[i].y)
    }
    if (reverse) {
      path.rx = Math.min(Math.max(path.rx, 0), 1)
      path.ry = Math.min(Math.max(path.ry, 0), 1)
      path.x = path.rx * w
      path.y = path.ry * h
    } else {
      path.x = Math.min(Math.max(path.x, 0), w)
      path.y = Math.min(Math.max(path.y, 0), h)
      path.rx = path.x / w
      path.ry = path.y / h
    }
    if (!path.closed) {
      x1 = Math.min(x1, path.x)
      y1 = Math.min(y1, path.y)
      x2 = Math.max(x2, path.x)
      y2 = Math.max(y2, path.y)
    }
    path.bboxX = x1
    path.bboxY = y1
    path.bboxWidth = x2 - x1
    path.bboxHeight = y2 - y1
    if (reverse) {
      path.bboxX = path.rBboxX * w
      path.bboxY = path.rBboxY * h
      path.bboxWidth = path.rBboxWidth * w
      path.bboxHeight = path.rBboxHeight * h
    } else {
      path.rBboxX = path.bboxX / w
      path.rBboxY = path.bboxY / h
      path.rBboxWidth = path.bboxWidth / w
      path.rBboxHeight = path.bboxHeight / h
    }

    if (path.closed) {
      const clockwise = !path.holeOf
      const _path = fixPolygonDirection(path.path, clockwise, (p) => [
        p.rx,
        p.ry,
      ])
      path.svg = path2svg(0, 0, _path, false)
    } else {
      const pathArray: any = [path]
      const p = [].concat(path.path, pathArray)
      path.svg = path2svg(0, 0, p, true)
    }
  }

  createPath(downEvent, selectAll = false) {
    const { onPathsUpdate, label, idx, pathStyle } = this.props
    const { paths, holeModeOn, hoverPathId } = this.state
    let { pathId } = this.state

    if (!label) {
      return
    }

    if (holeModeOn && !hoverPathId) {
      return
    }

    if (downEvent) {
      downEvent.stopPropagation()
      downEvent.preventDefault()
    }

    if (!this.props.isFullscreen) {
      setNoScroll(true)
    }

    const startX = downEvent ? getX(downEvent) : 0
    const startY = downEvent ? getY(downEvent) : 0

    const { top, left } = offset(this.props.imageRef.current)
    const x = startX - left
    const y = startY - top

    if (selectAll) {
      const { current } = this.props.imageRef
      const coords = [
        {
          x: 0,
          y: 0
        },
        {
          x: current.width,
          y: 0
        },
        {
          x: current.width,
          y: current.height
        },
        {
          x: 0,
          y: current.height
        }
      ]
      coords.forEach(({x, y}) => {
        if (pathId) {
          paths[pathId].path.push({ x, y })
        } else {
          pathId = uuidv4()
          paths[pathId] = {
            label,
            style: pathStyle,
            idx,
            showControls: false,
            path: [{ x, y }],
            x,
            y,
            bboxX: x,
            bboxY: y,
            bboxWidth: 0,
            bboxHeight: 0,
            holeOf: holeModeOn ? hoverPathId : null,
          }
        }
      })
      this.closePath(pathId, null);
      setNoScroll(false)
      onPathsUpdate(paths)
      this.props.mouseDownHandler(false)
    } else {
      if (pathId) {
        paths[pathId].path.push({ x, y })
      } else {
        pathId = uuidv4()
        paths[pathId] = {
          label,
          style: pathStyle,
          idx,
          showDelete: false,
          path: [{ x, y }],
          x,
          y,
          bboxX: x,
          bboxY: y,
          bboxWidth: 0,
          bboxHeight: 0,
          holeOf: holeModeOn ? hoverPathId : null,
        }
      }

      this._mousemove = (moveEvent) => {
        if (moveEvent) {
          moveEvent.preventDefault()
          moveEvent.stopPropagation()
        }

        if (!this.state.pathId) return

        const o = offset(this.props.imageRef.current)
        const _x = getX(moveEvent) - o.left
        const _y = getY(moveEvent) - o.top
        const _paths = this.state.paths
        const path = _paths[pathId]

        path.x = _x
        path.y = _y
        this._fixPath(path)

        if (path && !path.showControls) {
          this.switchPathControls(pathId, true)
        }

        this.setState({ paths: _paths })
      }

      this._mouseup = () => {
        setNoScroll(false)
        this._mouseup = null
        onPathsUpdate(paths)
        this.props.mouseDownHandler(false)
      }
    }

    this._fixPath(paths[pathId])
    this.setState({ pathId, paths })
  }

  deletePath(_pathId, e) {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    const { paths, pathId } = this.state
    const { onPathsUpdate } = this.props
    delete paths[_pathId]
    if (_pathId === pathId) {
      this.setState({
        paths,
        pathId: null,
        holeModeOn: false,
        hoverPathId: null,
      })
    } else {
      this.setState({
        paths,
        holeModeOn: false,
        hoverPathId: null,
      })
    }
    onPathsUpdate(paths)
  }

  movePoint(pathId, idx, e) {
    const { paths } = this.state
    const { onPathsUpdate, label } = this.props
    const movingPath = paths[pathId]

    if (!label || movingPath.label !== label) {
      this.props.mouseDownHandler(false)
      return
    }

    e.stopPropagation()
    e.preventDefault()

    this.props.mouseDownHandler(true)

    if (!movingPath.showControls) {
      this.switchPathControls(pathId, true)
    }

    if (!this.props.isFullscreen) {
      setNoScroll(true)
    }

    // movingPath.showDelete = false
    const startX = getX(e)
    const startY = getY(e)
    const x0 = movingPath.path[idx].x
    const y0 = movingPath.path[idx].y

    this.__mousemove = this._mousemove
    this.__mouseup = this._mouseup

    this._mousemove = (moveEvent) => {
      if (moveEvent) {
        moveEvent.preventDefault()
        moveEvent.stopPropagation()
      }

      const x = getX(moveEvent)
      const y = getY(moveEvent)
      movingPath.path[idx].x = x0 + x - startX
      movingPath.path[idx].y = y0 + y - startY
      movingPath.x = x0 + x - startX
      movingPath.y = y0 + y - startY
      this._fixPath(movingPath)
      if (movingPath && !movingPath.showControls) {
        this.switchPathControls(pathId, true)
      }
      paths[pathId] = movingPath
      this.setState({ paths })
    }
    this._mouseup = () => {
      // movingPath.showDelete = true
      setNoScroll(false)
      this._mousemove = null
      onPathsUpdate(paths)
      this._mousemove = this.__mousemove
      this._mouseup = this.__mouseup
    }
  }

  handleSvgAreaMouseup() {
    this.props.mouseDownHandler(false)
  }

  buttonToggleFullscreenHover(isFullscreenTooltipShown) {
    this.setState({ isFullscreenTooltipShown })
  }

  switchPathControls(pathId, isVisible) {
    const { paths } = this.state
    Object.keys(paths).forEach((rId) => {
      if (Object.prototype.hasOwnProperty.call(paths, rId)) {
        paths[rId].showControls = false
      }
    })
    paths[pathId].showControls = isVisible
    this.setState({ paths, hoverPathId: isVisible ? pathId : null })
  }

  undo(e) {
    const { paths, pathId } = this.state

    e.preventDefault()

    if (pathId === null) {
      let newest = 0
      let newestUuid

      Object.keys(paths).forEach((i) => {
        const current = paths[i].createdAt

        if (current > newest) {
          newest = current
          newestUuid = i
        }
      })

      delete paths[newestUuid]
    } else {
      delete paths[pathId]
    }

    this.props.onPathsUpdate(paths)
    this.setState({ paths, pathId: null })
  }

  clear(e) {
    const paths = {}
    const pathId = null

    e.preventDefault()

    this.setState({ paths, pathId })
    this.props.clearPaths()
  }

  toggleHoleMode(e) {
    e.preventDefault()
    this.setState({
      holeModeOn: !this.state.holeModeOn,
      hoverPathId: null,
    })
  }

  handleZoomButtonClick(type, callback) {
    const { zoomRatioPercentage, changeZoomRatio } = this.props

    const zoomRatio =
      type === 'up'
        ? zoomRatioPercentage + this.zoomStep
        : zoomRatioPercentage - this.zoomStep

    changeZoomRatio(parseFloat(zoomRatio.toFixed(1)), callback)
  }

  renderPathControls(pathId) {
    const { paths } = this.state
    const path = paths[pathId]
    const delSize = 9
    const crossRadius = delSize * (2 / 3)
    const crossLineSize = Math.sqrt((crossRadius * crossRadius) / 2)
    let del: any = ''
    if (path.showDelete) {
      const bbX = path.bboxX + path.bboxWidth / 2
      const bbY = path.bboxY + path.bboxHeight / 2
      del = (
        <g>
          <circle
            cx={bbX}
            cy={bbY}
            r={delSize}
            style={{ fill: path.style.stroke }}
          />
          <line
            x1={bbX - crossLineSize}
            y1={bbY - crossLineSize}
            x2={bbX + crossLineSize}
            y2={bbY + crossLineSize}
            style={{ stroke: 'white', strokeWidth: 2 }}
          />
          <line
            x1={bbX + crossLineSize}
            y1={bbY - crossLineSize}
            x2={bbX - crossLineSize}
            y2={bbY + crossLineSize}
            style={{ stroke: 'white', strokeWidth: 2 }}
          />
          <circle
            className="path_delete transparent"
            onMouseDown={this.deletePath.bind(this, pathId)}
            onTouchStart={this.deletePath.bind(this, pathId)}
            cx={bbX}
            cy={bbY}
            r={delSize}
          />
        </g>
      )
    }
    if (path.label !== this.props.label) {
      return <g>{del}</g>
    }

    const points: any[] = []
    Object.keys(paths).forEach((pId) => {
      const p = paths[pId]
      if (pId === pathId || p.holeOf === pathId) {
        p.path.forEach((point, idx) => {
          const close = idx === 0 && !p.closed
          const closeRadius = 6
          const defaultRadius = 3

          const pt = (
            <circle
              className={`point${close ? ' close' : ''}`}
              onTouchStart={
                close
                  ? this.closePath.bind(this, pId)
                  : this.movePoint.bind(this, pId, idx)
              }
              onMouseDown={
                close
                  ? this.closePath.bind(this, pId)
                  : this.movePoint.bind(this, pId, idx)
              }
              cx={point.x}
              cy={point.y}
              r={close ? closeRadius : defaultRadius}
              style={{ fill: p.style.stroke }}
              key={pId + idx}
            />
          )
          points.push(pt)
        })
      }
    })

    return (
      <g>
        {points}
        {del}
      </g>
    )
  }

  renderPath(pathId: any) {
    const { paths } = this.state
    const path = paths[pathId]

    if (!path) return ''

    const { bboxWidth, bboxHeight, bboxX, bboxY, idx, closed } = path
    const { stroke, strokeWidth, fontSize } = path.style

    const svgPaths = [path.svg.path]
    Object.keys(paths).forEach((pId) => {
      if (paths[pId].holeOf === pathId) {
        svgPaths.push(paths[pId].svg.path)
      }
    })

    return (
      <g
        onMouseEnter={this.switchPathControls.bind(this, pathId, true)}
        onMouseLeave={this.switchPathControls.bind(this, pathId, false)}
        key={pathId}
        ref={(c) => {
          this[pathId] = c
        }}
      >
        <path d={svgPaths.join(' ')} className="path" style={path.style} />

        <text
          x={bboxX + bboxWidth / 2}
          y={bboxY - strokeWidth + bboxHeight / 2 + fontSize / 2}
          fill={stroke}
          fontSize={fontSize}
          style={{ display: closed ? 'block' : 'none' }}
        >
          <tspan textAnchor="middle">{idx + 1}</tspan>
        </text>

        {path.showControls ? this.renderPathControls(pathId) : ''}
      </g>
    )
  }

  render() {
    const { imageUrl, t, zoomRatioPercentage } = this.props
    const { paths, pathId, holeModeOn } = this.state

    const pathsElements1: any[] = []
    const pathsElements2: any[] = []
    const pathsElements3: any[] = []
    Object.keys(paths).forEach((id) => {
      if (id === pathId || paths[id].holeOf) {
        return
      }
      if (paths[id].label !== this.props.label) {
        pathsElements1.push(this.renderPath(id))
      } else if (!paths[id].showControls) {
        pathsElements2.push(this.renderPath(id))
      } else {
        pathsElements3.push(this.renderPath(id))
      }
    })
    const pathsElements = pathsElements1.concat(pathsElements2, pathsElements3)

    if (pathId) {
      pathsElements.push(this.renderPath(pathId))
    }

    const outerSize = {
      width: document.body.offsetWidth,
      height: document.body.offsetHeight,
    }

    const buttonList: {
      position: 'right' | 'left'
      callback: (e: any) => void
      content: React.ReactNode
      classList?: string[]
      icon?: React.ReactNode
    }[] = [
      {
        position: 'left',
        callback: this.undo.bind(this),
        content: t('task.button.undo'),
      },
      {
        position: 'right',
        callback: this.clear.bind(this),
        content: t('task.button.clear'),
      },
    ]

    if (isStudioMode) {
      buttonList.push({
        position: 'right',
        callback: () => {
          this.createPath(null, true)
        },
        content: t('task.button.select_all'),
      })
    }

    return (
      <React.Fragment>
        <div className="core-middle" ref={this.props.coreMiddleRef}>
          <Button
            className="btn btn-dark-gray core-button-fullscreenClose"
            onClick={() => {
              this.props.handleButtonToggleFullscreen(
                1,
                this.setResolution.bind(this),
              )
            }}
          >
            <FullscreenCloseIcon />
          </Button>

          <div className="core-zoom">
            <Button
              className="btn btn-dark-gray core-button-zoom-in"
              onClick={() => {
                this.handleZoomButtonClick('up', this.setResolution.bind(this))
              }}
              disabled={zoomRatioPercentage >= this.maxZoom}
            >
              <ZoomInIcon />
            </Button>

            <Button
              className="btn btn-dark-gray core-button-zoom-out"
              onClick={() => {
                this.handleZoomButtonClick(
                  'down',
                  this.setResolution.bind(this),
                )
              }}
              disabled={zoomRatioPercentage <= this.minZoom}
            >
              <ZoomOutIcon />
            </Button>

            <span className="core-zoom-percentage">
              {`${Math.round(zoomRatioPercentage * 100)}%`}
            </span>
          </div>

          <div className="core-inner">
            <Scroller
              disabled={!this.props.isFullscreen}
              outerSize={outerSize}
              innerSize={this.props.innerFullscreenSize}
              className="core-scroller"
              fullscreenOffsetLeft={this.props.fullscreenOffsetLeft}
              fullscreenOffsetTop={this.props.fullscreenOffsetTop}
              scrollHeight={8}
            >
              <div
                className={this.props.container}
                ref={this.props.coreref}
                style={this.props.imageFullscreenSize}
              >
                <img
                  src={imageUrl}
                  alt=""
                  ref={this.props.imageRef}
                  className="image-value image-value-with-bottoms"
                />

                <svg
                  ref={(c) => {
                    this.svgarea = c
                  }}
                  className="svg-area"
                  onMouseDown={this.createPath.bind(this)}
                  onMouseUp={this.handleSvgAreaMouseup.bind(this)}
                  onTouchStart={this.createPath.bind(this)}
                  style={{
                    width:
                      (this.props.imageRef &&
                        this.props.imageRef.current &&
                        this.props.imageRef.current.width) ||
                      0,
                    height:
                      (this.props.imageRef &&
                        this.props.imageRef.current &&
                        this.props.imageRef.current.height) ||
                      0,
                  }}
                >
                  {pathsElements}
                </svg>

                <div className="svg-button-fullscreen">
                  <Tooltip
                    text={t('task.button.fullscreen')}
                    position="bottom-right"
                    show={this.state.isFullscreenTooltipShown}
                  >
                    <span
                      tabIndex={0}
                      role="button"
                      onClick={() => {
                        this.props.handleButtonToggleFullscreen(
                          1,
                          this.setResolution.bind(this),
                        )
                      }}
                      onMouseEnter={() => {
                        this.buttonToggleFullscreenHover(true)
                      }}
                      onMouseLeave={() => {
                        this.buttonToggleFullscreenHover(false)
                      }}
                    >
                      <FullscreenIcon />
                    </span>
                  </Tooltip>
                </div>
              </div>
            </Scroller>

            <TaskSourceControls
              buttonList={buttonList}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
