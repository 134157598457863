import Popup from '../Popup/Popup'
import React, { Component } from 'react'
import './style.scss'

const PopupId = 'error-popup'
class ErrorPopup extends Component<any, any> {
  static reloadPage() {
    document.location.reload(true)
  }

  componentDidMount() {
    const { error } = this.props
    if (error.message && error.status !== 403) {
      this.props.actions.showPopup(PopupId)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { error } = this.props
    const { error: errorNext } = nextProps

    if (
      errorNext.message &&
      errorNext.message !== error.message &&
      errorNext.status !== 403
    ) {
      this.props.actions.showPopup(PopupId)
    }

    if (!errorNext.message && error.message) {
      this.props.actions.hidePopup(PopupId)
    }

    if (errorNext.status === 401 && error.status !== 401) {
      this.props.actions.hidePopup(PopupId)
      this.props.actions.signOut()
    }
  }

  render() {
    const { error, actions, popup, t } = this.props
    return (
      <React.Fragment>
        {error.message && error.status !== 403 ? (
          <Popup
            handleClose={() => actions.errorHide()}
            className="error-popup"
            id={PopupId}
            actions={actions}
            popup={popup}
          >
            <div className="gap-5" />
            <div className="row">
              <div className="col-1 sm-hidden" />
              <div className="col-3 col-sm-4 col-xs-2">
                <div className="heading-2-type-1 font-medium">
                  Error {error.status}
                </div>
                <div className="text-4-type-2 font-regular">
                  {error.message}
                </div>
                <div className="buttons">
                  <span
                    role="button"
                    tabIndex={0}
                    className="btn"
                    onClick={() => {
                      ErrorPopup.reloadPage()
                    }}
                  >
                    {t('Reload page')}
                  </span>
                </div>
              </div>
            </div>
          </Popup>
        ) : null}
      </React.Fragment>
    )
  }
}

export default ErrorPopup
