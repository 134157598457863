import React from 'react'

const FullScreenIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="707.8 388.2 24 24">
      <circle cx="719.8" cy="400.2" r="12" fill="#444" />
      <path
        d="M713.8 394.2v6l6-6zm6 0l6 6v-6zm-6 12h6l-6-6zm12 0v-6l-6 6z"
        fill="currentColor"
      />
    </svg>
  )
}

export default FullScreenIcon
