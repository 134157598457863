import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import config from '../../config'
import './style.scss'

export interface WelcomeProps {
  app: any
}

const Welcome = (props: WelcomeProps) => {
  const { t } = useTranslation()
  const { app } = props
  const { isMobile } = app
  const headingClassName = isMobile ? 'text-1-type-2' : 'heading-2-type-1'

  return (
    <div className="row" data-testid="welcomePage">
      <div className="col-1 sm-hidden" />

      <div className="welcome col-3 col-sm-4 col-xs-2">
        <h1 className={`${headingClassName} font-medium`}>
          {t('welcome.title')}
        </h1>

        <div
          className="text-3-type-3 font-regular"
          dangerouslySetInnerHTML={{ __html: t('welcome.text') }}
        />

        <div className="gap-1" />

        <Link
          data-testid="startTestTaskButton"
          className="btn"
          to={config.routes.label}
        >
          {t('welcome.start')}
        </Link>
      </div>
    </div>
  )
}

export default connect((state) => state)(Welcome)
