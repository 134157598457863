import React, { Component } from 'react'
import Popup from '../../../Popup/Popup'

export interface TaskPopupProps {
  isActive: boolean
  actions: any
  children?: React.ReactNode
  classList?: string[]
  id?: string
}
class TaskPopup extends Component<TaskPopupProps, any> {
  render() {
    const {
      isActive,
      actions,
      children,
      classList = [],
      id = `popup-${Date.now()}`,
    } = this.props

    if (!children) {
      return ''
    }

    return (
      <Popup
        id={id}
        className={classList.join(' ')}
        popup={{ isActive, id }}
        actions={actions}
      >
        <div className="gap-5" />

        <div className="row">
          <div className="popup-content col-4 col-sm-4 col-xs-2">
            {children}
          </div>
        </div>
      </Popup>
    )
  }
}

export default TaskPopup
