const generatePayload = (o) => {
  if (o.type === 'string') {
    const { values } = o

    if (values) {
      return {
        values,
        payload: '',
        input: 'button',
      }
    }

    return {
      payload: '',
      values: null,
      input: 'text',
    }
  }

  if (o.type === 'array') {
    const { values } = o.items

    return {
      values,
      payload: [],
      input: 'button',
    }
  }

  if (o.type === 'map') {
    const { values } = o.keys
    const { type: itemsType } = o.items
    const payload = {}

    values.forEach((v) => {
      payload[v.value] = []
    })

    return {
      input: itemsType === 'string' ? 'text' : 'button',
      values,
      payload,
    }
  }

  return {}
}

export const assignmentsOutputAdapter = (output) => {
  const outputKey = Object.keys(output)[0]

  if (!outputKey) {
    return { success: false }
  }

  const outputSpec = output[outputKey]
  const { payload, input: type, values: labels } = generatePayload(outputSpec)

  const groups = outputSpec.groups instanceof Array ? outputSpec.groups : []

  const result = {
    labels,
    payload,
    type,
    groups,
    success: true,
  }

  return result
}

export const assignmentsInputAdapter = (input) => {
  const inputKeys = Object.keys(input)
  const textInputKey = 'text'
  const imageInputKey = 'image'
  const pdfInputKeys = 'pdf'
  // TODO: change any
  const result: {
    success: boolean
    type: string | null
    source: string[] | null
    text?: any[] | null
    caption?: any
    description?: any
    full_description?: any
  } = { success: false, type: null, source: null }

  if (
    inputKeys.includes(textInputKey) ||
    inputKeys.includes(pdfInputKeys) ||
    inputKeys.includes(imageInputKey)
  ) {
    if (inputKeys.includes(textInputKey)) {
      const textKeys = inputKeys.filter((k) => k.includes(textInputKey))

      result.type = textInputKey
      result.source = textKeys.map((k) => input[k].value)
      result.text = textKeys.map((k) => input[k].value)
    }

    if (inputKeys.includes(imageInputKey)) {
      const imageKeys = inputKeys.filter((k) => k.includes(imageInputKey))
      const textKeys = inputKeys.filter((k) => k.includes(textInputKey))

      result.type = imageInputKey
      result.source = imageKeys.map((k) => input[k].value)
      result.text = textKeys.map((k) => input[k].value)
    }

    if (inputKeys.includes(pdfInputKeys)) {
      const imageKeys = inputKeys.filter((k) => k.includes(pdfInputKeys))
      const textKeys = inputKeys.filter((k) => k.includes(textInputKey))

      result.type = pdfInputKeys
      result.source = imageKeys.map((k) => input[k].value)
      result.text = textKeys.map((k) => input[k].value)
    }

    result.success = true
    result.caption = input.caption
    result.description = input.description || null
    result.full_description = input.full_description || null
  }

  return result
}
