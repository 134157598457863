import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import showdown from 'showdown'
import Combokeys from 'combokeys'
import config from '../../config'
import { compileAssignmentLabels } from '../../utils/tasks'
import Raven from 'raven-js'
import TaskCaption from './components/TaskCaption/TaskCaption'
import TaskDescription from './components/TaskDescription/TaskDescription'
import TaskPopup from './components/TaskPopup/TaskPopup'
import Text from './components/Text/Text'
import TypeSwitcher from './components/Typeswitcher/Typeswitcher'
import * as userActions from '../User/actions'
import * as tasksActions from './actions'
import Timer from './timer'
import * as popupActions from '../Popup/actions'

import './style.scss'
import Bttn from '../Bttn'
import Spinner from '../Spinner'
import { ITasks } from '../../@types/commonTypes'
import { RouteComponentProps } from 'react-router'

const bodyClass = 'label-page'
const minBacklogLength = 2
const expiredPopupId = 'expiredPopupId'
const fullDescriptionPopupId = 'fullDescriptionPopupId'
const descriptionPopupTimeout = 3

export interface TaskProps extends RouteComponentProps {
  t: any
  app: any
  tasks: ITasks
  popup: any
  actions: any
  user: any
}

export interface TaskState {
  task: any
  expired: boolean
  isFullDescriptionPopupVisible: boolean
  isClosePopupButtonDisabled: boolean
  isFirstDescriptionShow: boolean
}

class Task extends Component<TaskProps, TaskState> {
  loadingID: number | null
  markdownConverter: showdown.Converter
  combokeys: Combokeys.Combokeys
  timer: Timer | null
  expirationTimeoutId?: number | null

  constructor(props) {
    super(props)
    this.state = {
      task: props.tasks.assignments[0],
      expired: false,
      isFullDescriptionPopupVisible: false,
      isClosePopupButtonDisabled: false,
      isFirstDescriptionShow: false,
    }

    this.loadingID = null
    this.markdownConverter = new showdown.Converter()
    this.combokeys = new Combokeys(document.documentElement)
    this.timer = null
  }

  componentDidMount() {
    const { actions, tasks } = this.props

    if (tasks.assignments.length === 0) {
      actions.getTasks()
    }

    document.body.classList.add(bodyClass)

    this.setCombokeys()
    this.timer = new Timer()
  }

  componentWillReceiveProps(nextProps) {
    const { tasks, history } = this.props

    const { tasks: tasksNext } = nextProps

    if (
      (tasksNext.assignments[0] && !tasks.assignments[0]) ||
      (tasksNext.assignments[0] &&
        tasks.assignments[0] &&
        tasksNext.assignments[0].id !== tasks.assignments[0].id)
    ) {
      this.setTask(tasksNext.assignments[0])

      if (tasksNext.expiration_timeout || tasksNext.expiration_timeout === 0) {
        if (this.expirationTimeoutId) {
          clearTimeout(this.expirationTimeoutId)
        }

        this.expirationTimeoutId = window.setTimeout(
          this.handleExpieredTask.bind(this),
          tasksNext.expiration_timeout * 1000,
        )
      }
    }

    if (!tasksNext.assignments[0] && tasks.assignments[0]) {
      if (tasksNext.isTasksLoading) {
        this.setState({ task: null })
      } else {
        history.push(
          tasksNext.onboarding_status
            ? config.routes.score
            : config.routes.notasks,
        )
        return
      }
    }

    if (
      !tasksNext.isTasksLoading &&
      tasks.isTasksLoading &&
      !tasksNext.assignments.length
    ) {
      const nextPath = tasksNext.onboarding_status
        ? config.routes.score
        : config.routes.notasks

      history.push(nextPath)
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.state.task && this.state.task.id !== undefined) {
      if (
        (!prevState.task && this.state.task.id) ||
        this.state.task.id !== prevState.task.id
      ) {
        this.timer && this.timer.restart()
      }
    }

    if (this.state.task && !this.state.task.success) {
      const { task } = this.state
      const { me: user } = this.props.user

      this.skipTask()

      Raven.captureMessage('Assignment was not parsed successfully', {
        level: 'warning',
        extra: { task, user },
      })
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(bodyClass)

    if (this.expirationTimeoutId) {
      clearTimeout(this.expirationTimeoutId)
    }

    this.expirationTimeoutId = null
    this.combokeys.detach()
    this.timer && this.timer.stop()
    this.props?.actions.resetTasks()
  }

  setCombokeys() {
    const { instruction } = config.hotKeys

    this.combokeys.bind(instruction.key, () => {
      const { isFullDescriptionPopupVisible, isFirstDescriptionShow } =
        this.state

      if (!isFirstDescriptionShow) {
        this.fullDescriptionPopupVisibility(!isFullDescriptionPopupVisible)
      }
    })
  }

  setTask(task) {
    const { output, input } = task
    const success = output.success && input.success

    if (this.loadingID) {
      clearTimeout(this.loadingID)
    }

    this.setState({ task: { ...task, success } }, () => {
      if (this.checkFullDescriptionVisibility()) {
        this.fullDescriptionPopupVisibility(true)
      }
    })
  }

  checkFullDescriptionVisibility() {
    const { task, isFirstDescriptionShow } = this.state
    const description =
      task.input && task.input.description ? task.input.description.value : null
    const ss = window.sessionStorage

    if (!description) {
      return false
    }

    if (ss.lastTaskDescription !== description) {
      this.setState({ isFirstDescriptionShow: true })
      ss.lastTaskDescription = description
      return true
    }

    if (isFirstDescriptionShow) {
      this.setState({ isFirstDescriptionShow: false })
    }

    return false
  }

  fullDescriptionPopupVisibility(isFullDescriptionPopupVisible) {
    if (this.timer) {
      if (isFullDescriptionPopupVisible) {
        this.timer.pause()
      } else {
        this.timer.play()
      }
    }

    this.setState({ isFullDescriptionPopupVisible }, () => {
      if (this.state.isFirstDescriptionShow) {
        this.setState({ isClosePopupButtonDisabled: true }, () => {
          setTimeout(() => {
            this.setState({
              isClosePopupButtonDisabled: false,
              isFirstDescriptionShow: false,
            })
          }, descriptionPopupTimeout * 1000)
        })
      }
    })
  }

  hideExpired() {
    this.setState({ expired: false }, () => {
      this.props.actions.hidePopup()
      this.props.actions.resetTasks()
      this.props.actions.getTasks()
    })
  }

  handleExpieredTask() {
    if (this.expirationTimeoutId) {
      clearTimeout(this.expirationTimeoutId)
      this.expirationTimeoutId = null
    }
    this.setState({ expired: true }, () => {
      this.props.actions.showPopup(expiredPopupId)
    })
  }

  markTask(payload, userData, skip) {
    this.loadingID = window.setTimeout(() => {
      this.setState({
        task: null,
      })
    }, 200)

    this.timer && this.timer.stop()
    const time = (this.timer && this.timer.getValue()) || 9999999

    this.props.actions.markTask(this.state.task, payload, userData, skip, time)

    if (this.props.tasks.assignments.length < minBacklogLength) {
      this.props.actions.getTasks()
    }
  }

  skipTask() {
    this.markTask(null, null, true)
  }

  generateDescriptionPopupLabels() {
    const { t } = this.props
    const { task } = this.state

    if (!task || !task.output || !task.output.labels) {
      return ''
    }

    const { labels, groups } = task.output
    const labelsWithDescription = labels
      ? labels.filter((l) => l.description).length
      : 0

    if (!labelsWithDescription) {
      return ''
    }

    const groupsList = compileAssignmentLabels(groups, labels)

    return (
      <div className="descriptionpopup-labels">
        <p className="text-3-type-1 labels-heading descriptionpopup-subhead">
          {t('task.heading.popup_labels')}
        </p>
        {groupsList.map((group, groupNum) => (
          <div
            className="descriptionpopup-buttonslist"
            key={group.group || groupNum}
          >
            {group.group ? (
              <p className="buttonslist-group">
                {groupNum + 1}. {group.group}
              </p>
            ) : (
              ''
            )}

            <div className="buttonslist-labelslist">
              {group.labels.map((label, labelNum) => (
                <p key={label.value} className="buttonslist-label col-2">
                  <Bttn disabled>
                    {labelNum + 1}. {label.text}
                  </Bttn>

                  {label.description ? (
                    <span className="buttonslist-label-description">
                      {label.description}
                    </span>
                  ) : (
                    ''
                  )}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { fullDescriptionPopupVisibility } = this
    const { task, isFirstDescriptionShow, isFullDescriptionPopupVisible } =
      this.state

    const { t, tasks, actions, app, popup } = this.props

    const { isMobile } = app
    const { resolvedTasks } = tasks

    if (!task) {
      return <Spinner className="task-spinner" />
    }

    const {
      caption: captionObj,
      description: descriptionObj,
      full_description: fullDescriptionObj,
    } = task.input

    const { value: taskCaption } = captionObj
    const { value: taskDescription } = descriptionObj || {}
    const { value: taskFullDescription } = fullDescriptionObj || {}

    const description = (
      <TaskDescription
        text={taskDescription}
        classList={['description', 'font-regular', 'text-4-type-2']}
        onMoreButtonClick={this.fullDescriptionPopupVisibility.bind(this)}
        t={t}
      />
    )

    const caption = (
      <TaskCaption
        text={taskCaption}
        classList={[isMobile ? 'text-3-type-1' : 'text-2-type-2']}
      />
    )

    const testLabel = tasks.onboarding_status ? (
      <div className="text-6-type-5 font-bold test">
        <span className="upper">{t('task.task_test')}</span>(
        <span data-testid="taskProgressCount">
          {tasks.onboarding_status.assignments_completed + 1}
        </span>
        /{tasks.onboarding_status.assignments_total})
      </div>
    ) : (
      ''
    )

    const fullDescriptionPopup = null
    // taskDescription !== '' ? (
    //   <TaskPopup
    //     actions={actions}
    //     isActive={this.state.isFullDescriptionPopupVisible}
    //     classList={['popup-task-fulldescription']}
    //     id={fullDescriptionPopupId}
    //   >
    //     <div className="descriptionpopup-header">{taskCaption}</div>

    //     <Text
    //       text={taskFullDescription || taskDescription}
    //       classList={['description', 'descriptionpopup-main']}
    //     />

    //     {this.generateDescriptionPopupLabels()}

    //     <div className="buttons buttons-pull-right">
    //       <Bttn
    //         disabled={this.state.isClosePopupButtonDisabled}
    //         onClick={(e) => {
    //           e.preventDefault()
    //           this.fullDescriptionPopupVisibility(false)
    //         }}
    //       >
    //         {t('task.button.got_it')}
    //       </Bttn>
    //     </div>
    //   </TaskPopup>
    // ) : null

    const expired = this.state.expired ? (
      <TaskPopup
        actions={actions}
        isActive={popup.isActive}
        classList={['popup-task-expired']}
        id={expiredPopupId}
      >
        <div className="text-2-type-2">{t('task.time_up')}</div>
        <div className="buttons buttons-pull-right">
          <span
            role="button"
            tabIndex={0}
            className="btn"
            onClick={this.hideExpired.bind(this)}
          >
            {t('task.button.got_it')}
          </span>
        </div>
      </TaskPopup>
    ) : (
      ''
    )

    const typeswitcherProps = {
      isMobile,
      testLabel,
      t,
      task,
      caption,
      description,
      fullDescriptionPopup,
      isFullDescriptionPopupVisible,
      fullDescriptionPopupVisibility,
      tasks,
      popup,
      actions,
      isFirstDescriptionShow,
      resolvedTasks,
      isMock: this.props.tasks.isMock,
      normalizePoints: this.props.actions.normalizePoints,
      markTask: this.markTask.bind(this),
      skipTask: this.skipTask.bind(this),
    }

    return (
      <div className='task-container'>
        <TypeSwitcher name={task.type} {...typeswitcherProps} />
        {expired}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  const actionList = Object.assign({}, popupActions, tasksActions, userActions)
  const actions = bindActionCreators(actionList, dispatch)

  return { actions }
}

export default withTranslation()(
  connect((state) => state, mapDispatchToProps)(Task),
)
