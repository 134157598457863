import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import * as authorizationActions from './actions'
import LogIn from './LogIn'
import SignUp from './SignUp'
import Recovery from './Recovery'
import config from '../../config'
import './style.scss'
import './authorization-sidebar.scss'
import RightSidebar from '../RightSidebar'

const getTab = (tabName: string, props: any) => {
  switch (tabName) {
    case config.authorizationTabs.login:
      return <LogIn {...props} />
    case config.authorizationTabs.signup:
      return <SignUp {...props} />
    case config.authorizationTabs.recovery:
      return <Recovery {...props} />
    default:
      return null
  }
}

export interface AuthorizationProps {
  t: any
  actions: any
  authorization: any
  user: any
}

class Authorization extends Component<AuthorizationProps> {
  componentDidUpdate(prevProps) {
    const { user, actions } = this.props
    const { user: prevUser } = prevProps
    if (user.me && !prevUser.me) {
      // open verification page
      actions.closeAuthSidebar()
    }
  }

  render() {
    const { actions, authorization, t } = this.props
    return (
      <RightSidebar
        isActive={authorization.activeTabName}
        handleClose={() => actions.closeAuthSidebar()}
        content={getTab(authorization.activeTabName, {
          actions,
          authorization,
          t,
        })}
        className="authorization"
      />
    )
  }
}

const mapStateToProps = (state) => ({
  authorization: state.authorization,
  user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    Object.assign({}, authorizationActions),
    dispatch,
  ),
})

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Authorization),
)
