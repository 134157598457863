export enum StatusCode {
  TosNotAccepted = 'TOS_NOT_ACCEPTED',
  UserIsBanned = 'USER_IS_BANNED',
  WrongCredentials = 'WRONG_CREDENTIALS',
  TokenExpired = 'TOKEN_EXPIRED',
  IncorrectLoginPassword = 'INCORRECT_LOGIN_PASSWORD',
}

const status: {
  [key: number]: {
    [key: number]: StatusCode
  }
} = {
  401: {
    10: StatusCode.WrongCredentials,
    12: StatusCode.TokenExpired,
    13: StatusCode.IncorrectLoginPassword,
  },
  403: {
    5: StatusCode.UserIsBanned,
    19: StatusCode.TosNotAccepted,
  },
}

export const getStatusText = (code?: number | null, id?: number | null) => {
  if (!code || !id || !status[code] || !status[code][id]) {
    return null
  }

  return status[code][id]
}
