import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect, RouteProps } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Combokeys from 'combokeys'
import Raven from 'raven-js'
import { StatusCode, getStatusText } from '../../status_codes'

import * as mediaQuery from '../MediaQuery/index'
import * as popupActions from '../Popup/actions'

import { selectorSupported } from '../../utils/common'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Help from '../Help/Help'
import * as appActions from './actions'
import * as userActions from '../User/actions'
import * as authorizationActions from '../Authorization/actions'
import * as helpActions from '../Help/actions'
import * as errorActions from '../Error/actions'
import {
  Enter,
  Profile,
  Task,
  Error,
  NotAvailable,
  Terms,
  Welcome,
  Score,
  CreatePassword,
  Authorization,
  NoTasks,
  Verification,
  ErrorPopup,
  Log,
} from '../../components'
import history from '../../browserHistory'
import config from '../../config'
import './style.scss'
import { Utils } from '../../utils/utils'
import RightSidebar from '../RightSidebar'
import Grid from '../Grid'

export interface AppProps {
  t: any
  actions: any
  app: any
  help: any
  user: any
  authorization: any
  error: any
  i18n: any
  popup: any
  history: any
}

class App extends Component<AppProps, any> {
  combokeys: Combokeys.Combokeys

  constructor(props) {
    super(props)
    if (Utils.isProduction()) {
      Raven.config(config.raven.dsn).install()
    }

    history.listen(() => {
      if (this.props.help.isActive) {
        this.props.actions.helpHide()
      }
      if (this.props.popup.isActive) {
        this.props.actions.hidePopup()
      }
    })

    this.state = {
      isScreenNotAvailable: process.env.REACT_APP_NOT_AVAILABLE,
    }

    this.combokeys = new Combokeys(document.documentElement)
  }

  componentWillMount() {
    this.setDeviceType()

    document.body.classList.add(`lang-${this.props.i18n.language}`)
  }

  componentDidMount() {
    const { authorization, actions } = this.props
    if (authorization.isAuth) {
      actions.fetchMe()
    }

    window.addEventListener('resize', this.handleResize.bind(this))

    this.props.i18n.on('languageChanged', (lang) => {
      const prevLang = lang === 'ru' ? 'en' : 'ru'
      document.body.classList.remove(`lang-${prevLang}`)
      document.body.classList.add(`lang-${lang}`)
    })

    this.combokeys.bind('alt+shift+h', this.inactiveApp.bind(this))

    if (selectorSupported('::-ms-clear')) {
      // It means, browser is probably IE or Edge
      document.body.classList.add('mode-IE')
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.authorization.isAuth && !this.props.authorization.isAuth) {
      this.props.actions.fetchMe()
    }

    if (
      nextProps.user.me &&
      nextProps.authorization.isAuth &&
      nextProps.user.me.locale !== nextProps.i18n.language
    ) {
      this.props.actions.changeMe({ locale: nextProps.i18n.language })
    }

    const { error } = this.props
    const { error: errorNext } = nextProps

    if (
      Utils.isProduction() &&
      errorNext.status &&
      !error.status &&
      errorNext.status >= 500
    ) {
      Raven.captureException(errorNext, {
        extra: { ...errorNext },
      })
    }

    if (
      !error.status &&
      errorNext.status &&
      !document.location.pathname.includes(config.routes.createPassword)
    ) {
      const errorType = getStatusText(errorNext.status, errorNext.id)

      if (
        errorType === StatusCode.TosNotAccepted &&
        !document.location.pathname.includes(config.routes.terms)
      ) {
        history.push(config.routes.terms)
      }

      if (errorType === StatusCode.TokenExpired) {
        this.props.actions.hidePopup()
        this.props.actions.signOut()
      }
    }
  }

  componentDidCatch(error, errorInfo) {
    const { me } = this.props.user
    if (Utils.isProduction()) {
      Raven.setUserContext({
        email: me ? me.email : '',
      })
      Raven.captureException(error, { extra: errorInfo })
    }
  }

  inactiveApp() {
    this.setState({
      isScreenNotAvailable: true,
    })
  }

  setDeviceType() {
    const isMobile = mediaQuery.is(mediaQuery.xs)
    if (this.props.app.isMobile !== isMobile) {
      this.props.actions.setIsMobile(isMobile)
    }
  }

  handleResize() {
    this.setDeviceType()
  }

  render() {
    const { t, user, help, error, actions, app, popup, authorization } =
      this.props

    const { isMobile } = app
    const { isAuth } = authorization

    const { isScreenNotAvailable } = this.state

    const isErrorPage = config.routes.error === document.location.pathname

    const isNotRenderHelp = [
      config.routes.default,
      config.routes.createPassword,
      config.routes.error,
    ].includes(document.location.pathname)

    const isBlurPage =
      help.isActive || popup.isActive || authorization.activeTabName
    const { routes } = config
    const { mocks } = routes

    const mockRoutes = [...Object.values(mocks)]

    return (
      <React.Fragment>
        <Header user={user} actions={actions} authorization={authorization} />
        <div className={`container-wrapper ${isBlurPage ? 'is-bg' : ''}`}>
          <div className="container">
            <div className="container-inner">
              {isScreenNotAvailable ? (
                <NormalRoute
                  path="/"
                  component={NotAvailable}
                  t={t}
                  isMobile={isMobile}
                />
              ) : (
                <Switch>
                  <LoginRoute
                    exact
                    isMobile={isMobile}
                    path="/"
                    isAuth={isAuth}
                    component={Enter}
                  />
                  <LoginRoute
                    path={routes.createPassword}
                    isAuth={isAuth}
                    isMobile={isMobile}
                    component={CreatePassword}
                  />
                  <Route path={routes.verification} component={Verification} />
                  <Route
                    path={routes.terms}
                    render={(props) => <Terms {...props} terms={{}} />}
                  />
                  <Route
                    path={routes.error}
                    render={(props) => <Error {...props} app={{}} />}
                  />
                  <PrivateRoute
                    path={routes.welcome}
                    isAuth={isAuth}
                    component={Welcome}
                  />
                  <PrivateRoute
                    path={routes.log}
                    isAuth={isAuth}
                    component={Log}
                  />
                  <PrivateRoute
                    path={routes.label}
                    isAuth={isAuth}
                    component={Task}
                  />
                  <PrivateRoute
                    path={routes.profile}
                    isAuth={isAuth}
                    component={Profile}
                  />
                  <PrivateRoute
                    path={routes.score}
                    isAuth={isAuth}
                    component={Score}
                  />
                  <PrivateRoute
                    path={routes.notasks}
                    isAuth={isAuth}
                    component={NoTasks}
                  />
                  {mockRoutes.map((r, i) => (
                    <PrivateRoute key={i} path={r} isAuth={isAuth} component={Task} />
                  ))}
                  <Route path="*" component={Error} />
                </Switch>
              )}
            </div>
          </div>
          {/* <Footer help={help} actions={actions} /> */}
        </div>

        <Authorization />

        {isNotRenderHelp ? (
          ''
        ) : (
          <RightSidebar
            isActive={this.props.help.isActive}
            handleClose={() => this.props.actions.helpHide()}
            content={
              <Help isMobile={isMobile} help={help} actions={actions} t={t} />
            }
            className="help"
          />
        )}
        {isErrorPage ? null : (
          <ErrorPopup popup={popup} error={error} actions={actions} t={t} />
        )}
        <Grid />
      </React.Fragment>
    )
  }
}

const PrivateRoute = ({
  component: Component,
  isAuth,
  ...rest
}: RouteProps & { isAuth: boolean }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuth ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect to={{ pathname: config.routes.default }} />
      )
    }
  />
)

const LoginRoute = ({ component: Component, isAuth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuth ? (
        <Redirect to={{ pathname: config.routes.label }} />
      ) : (
        <Component {...props} {...rest} />
      )
    }
  />
)

const NormalRoute = ({ component: Component, isAuth, ...rest }: any) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    Object.assign(
      {},
      popupActions,
      userActions,
      helpActions,
      errorActions,
      appActions,
      authorizationActions,
    ),
    dispatch,
  ),
})
export default withTranslation()(
  connect((state) => state, mapDispatchToProps)(App),
)
