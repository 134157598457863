import Button from '../Button'
import Form from '../Form'
import Field from '../Form/field'
import Input from '../Form/Input'
import React, { Component } from 'react'
import { Trans } from 'react-i18next'

import config from '../../config'
import '../User/style.scss'
import * as validation from '../Form/validate'

const isEngVersion = !window.location.hostname.includes('dbrain')
const logoSrc = isEngVersion ? '/images/handlHITL.svg' : '/images/dbrainHITL.svg'

export default class LogIn extends Component<any, any> {
  form: HTMLFormElement
  email: any
  password: any

  handleSubmit(event) {
    event.preventDefault()
    this.form.validateAll()
    const errorLength = this.form.getChildContext()._errors.length
    if (errorLength) {
      return
    }

    const user = this.form.getValues()
    delete user.errors
    user.username = user.username.toString().trim().toLowerCase()
    this.props.actions.login(user)
  }

  render() {
    const { authorization, actions, t } = this.props
    const errorMessage = authorization.login.error.message ? (
      <div className="authorization-form-error field-error">
        <Trans i18nKey={`login.error.${authorization.login.error.id}`}>
          {authorization.login.error.message}
        </Trans>
      </div>
    ) : (
      ''
    )

    return (
      <div className="authorization-form-wrapper">
        <img src={logoSrc} />
        <div className="gap-1" />
        <Form
          ref={(c) => {
            this.form = c
          }}
          className={
            authorization.login.isLoginRequest ? 'form-state-loading' : ''
          }
          onSubmit={this.handleSubmit.bind(this)}
        >
          {errorMessage}

          <Field className="field-login-password">
            <Input
              name="username"
              type="text"
              placeholder={t('login.email')}
              validations={[validation.required, validation.email]}
              ref={(c) => {
                this.email = c
              }}
            />
          </Field>

          <Field className="field-login-password">
            <Input
              name="password"
              type="password"
              placeholder={t('login.password')}
              validations={[validation.required, validation.gt]}
              ref={(c) => {
                this.password = c
              }}
            />
            <span
              className="restore-password-link font-medium"
              onClick={() => {
                actions.openAuthSidebar(config.authorizationTabs.recovery)
              }}
            >
              {t('login.forgot')}
            </span>
          </Field>

          <div className="buttons buttons-pull-left">
            <Button className="btn btn-login">{t('login.log_in')}</Button>
          </div>
        </Form>
      </div>
    )
  }
}
