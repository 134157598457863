import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import background from './img/login-bg.jpg'
import './style.scss'
import * as authorizationActions from '../Authorization/actions'
import LogIn from 'components/Authorization/LogIn'

const bodyClass = ['enter-page', 'fixed-container']

export interface EnterProps {
  t: any
  actions: any
  isMobile: boolean
  authorization: any
}

class Enter extends Component<EnterProps> {
  componentDidMount() {
    document.body.classList.add(...bodyClass)
  }

  componentWillUnmount() {
    document.body.classList.remove(...bodyClass)
  }

  render() {
    const { actions, authorization, t } = this.props
    return (
      <div className="enter-page--container">
        <div className="enter-page--left">
          <img src={background} className="enter-page--left-image" />
        </div>
        <div className="enter-page--right">
          <div className="enter-page--form-container">
            <LogIn authorization={authorization} actions={actions} t={t} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  authorization: state.authorization,

})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    Object.assign({}, authorizationActions),
    dispatch,
  ),
})

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Enter),
)
