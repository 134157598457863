import React from 'react'

const Logo = () => (
  <svg
    width="83"
    height="22"
    viewBox="0 0 83 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6986 21.691H14.0272V12.8584H4.68438V21.691H0V0H4.68438V8.6824H14.0272V0H18.6986V21.691Z"
      fill="#FAFAFA"
    />
    <path
      d="M45.6227 8.37013C45.7436 8.20704 45.9163 8.0096 46.1408 7.77355C46.3653 7.5375 46.8532 7.21562 47.6087 6.81218C48.3642 6.40875 49.1586 6.20703 49.9919 6.20703C51.7361 6.20703 53.1609 6.80789 54.2618 8.00532C55.3627 9.20703 55.911 10.8251 55.911 12.8594V21.692H51.7102V13.3272C51.7102 12.4002 51.4339 11.6491 50.8813 11.0826C50.3287 10.5117 49.6163 10.2285 48.7442 10.2285C47.79 10.2285 47.0345 10.5375 46.4689 11.1598C45.9033 11.7821 45.6227 12.6577 45.6227 13.7993V21.6963H41.4219V6.51175H45.6227V8.37013Z"
      fill="#FAFAFA"
    />
    <path
      d="M68.1617 6.74678C68.9173 7.11159 69.4526 7.46352 69.7678 7.80687L70.2384 8.36481V0H74.4392V21.691H70.2384V19.8326C70.1995 19.8927 70.1348 19.97 70.0484 20.0687C69.9621 20.1631 69.7807 20.3348 69.5001 20.5837C69.2195 20.8326 68.9173 21.0515 68.5935 21.2489C68.2697 21.4464 67.8465 21.618 67.3328 21.7725C66.8147 21.9227 66.2793 22 65.7181 22C63.7407 22 62.0224 21.2361 60.5674 19.7082C59.1125 18.1803 58.3828 16.3133 58.3828 14.103C58.3828 11.8927 59.1125 10.0258 60.5674 8.49785C62.0224 6.96995 63.7407 6.20601 65.7181 6.20601C66.5902 6.20601 67.4062 6.38627 68.1617 6.74678ZM69.1418 17.1116C69.8714 16.3262 70.2384 15.3219 70.2384 14.103C70.2384 12.8841 69.8714 11.8798 69.1418 11.0987C68.4121 10.3176 67.5314 9.92703 66.4952 9.92703C65.459 9.92703 64.5783 10.3176 63.8486 11.0987C63.119 11.8798 62.752 12.8798 62.752 14.103C62.752 15.3219 63.119 16.3262 63.8486 17.1116C64.5783 17.897 65.4633 18.2919 66.4952 18.2919C67.5314 18.2876 68.4121 17.897 69.1418 17.1116Z"
      fill="#FAFAFA"
    />
    <path d="M82.2216 21.691H78.0078V0H82.2216V21.691Z" fill="#FAFAFA" />
    <path
      d="M33.6525 6.50394V8.36231L33.1819 7.80438C32.8667 7.46103 32.3313 7.1091 31.5758 6.74429C30.8202 6.37948 30.0043 6.19922 29.1321 6.19922C27.1548 6.19922 25.4365 6.96317 23.9815 8.49106C22.5265 10.019 21.7969 11.8859 21.7969 14.0962C21.7969 16.3065 22.5265 18.1735 23.9815 19.7014C25.4365 21.2293 27.1548 21.9932 29.1321 21.9932C29.6891 21.9932 30.2288 21.916 30.7468 21.7658C31.2649 21.6155 31.6837 21.4396 32.0075 21.2421C32.3313 21.0447 32.6335 20.8215 32.9142 20.5769C33.1948 20.328 33.3761 20.1563 33.4625 20.0619C33.5488 19.9675 33.6136 19.8902 33.6525 19.8258V21.6842H37.8533V6.49965H33.6525V6.50394ZM32.5602 17.1091C31.8305 17.8945 30.9454 18.2894 29.9136 18.2894C28.8774 18.2894 27.9967 17.8945 27.267 17.1091C26.5374 16.3237 26.1704 15.3194 26.1704 14.1005C26.1704 12.8816 26.5374 11.8773 27.267 11.0962C27.9967 10.3151 28.8817 9.92454 29.9136 9.92454C30.9498 9.92454 31.8305 10.3151 32.5602 11.0962C33.2898 11.8773 33.6568 12.8773 33.6568 14.1005C33.6524 15.3194 33.2898 16.3194 32.5602 17.1091Z"
      fill="#FAFAFA"
    />
  </svg>
)

export default Logo
