export const SET_IS_AUTH = 'SET_IS_AUTH'
export const CLEAR_ME = 'CLEAR_ME'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR'
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const RECOVERY_REQUEST = 'RECOVERY_REQUEST'
export const RECOVERY_SUCCESS = 'RECOVERY_SUCCESS'
export const RECOVERY_ERROR = 'RECOVERY_ERROR'
export const CREATE_PASSWORD_ERROR = 'CREATE_PASSWORD_ERROR'
export const SET_ACTIVE_TAB_NAME = 'SET_ACTIVE_TAB_NAME'
export const CLEAR_ACTIVE_TAB_NAME = 'CLEAR_ACTIVE_TAB_NAME'
export const UPDATE_ME = 'UPDATE_ME'
