import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { err2str } from '../../utils/common'
import * as userActions from '../User/actions'
import * as authorizationActions from '../Authorization/actions'
import * as taskActions from '../Task/actions'
import config from '../../config'
import './style.scss'
import i18n from '../../i18n'
import AvatarIcon from '../../components/Icons/AvatarIcon/AvatarIcon'
import ButtonSubmit from '../Button/ButtonSubmit'
import Form from '../Form'
import Field from '../Form/field'
import Input from '../Form/Input'
import * as validation from '../Form/validate'
import * as popupActions from '../Popup/actions'

const userTesterStatus = (date) => {
  const betaStartDate = 1554249601000

  if (!date) {
    return 'profile.status.alpha'
  }

  return +new Date(date) > betaStartDate
    ? 'profile.status.beta'
    : 'profile.status.alpha'
}

const getDoneAsssignmentCount = (o) => {
  let count = 0

  if (o.general === null || o.onboarding === null) {
    return count
  }

  if (
    o &&
    o.general !== undefined &&
    o.general !== null &&
    o.general.assignments_done !== undefined
  ) {
    count += o.general.assignments_done
  }

  if (
    o &&
    o.onboarding !== undefined &&
    o.onboarding !== null &&
    o.onboarding.assignments_completed !== undefined
  ) {
    count += o.onboarding.assignments_completed
  }

  return count
}

export interface ProfileProps {
  t: any
  user: any
  app: any
  popup: any
  actions: any
}

export interface ProfileState {
  editing: boolean
}

class Profile extends Component<ProfileProps, ProfileState> {
  form: HTMLFormElement
  errors: any
  email: HTMLInputElement
  wallet: any

  constructor(props) {
    super(props)
    this.state = {
      editing: false,
    }
  }

  componentDidMount() {
    this.props.actions.fetchMe()
  }

  handleSubmit(event) {
    event.preventDefault()
    const formValues = this.form.getValues()
    const data: any = {}
    data.wallet = formValues.wallet
    this.props.actions
      .changeMe(data)
      .then(this.toggleEdit.bind(this))
      .catch((error) => {
        this.form.showError(
          this.errors,
          <div className="field-error">{err2str(error)}</div>,
        )
      })
  }

  toggleEdit() {
    this.setState((prevState) => ({
      ...prevState,
      editing: !prevState.editing,
    }))
    // TODO: wtf? there is no this.wallet initialization
    this.form.hideError(this.wallet)
  }

  signout() {
    this.props.actions.resetTasks()
    this.props.actions.signOut()
  }

  renderForm() {
    const { t } = this.props
    const { me } = this.props.user

    const button = this.state.editing ? (
      <div className="buttons">
        <ButtonSubmit className="btn-small">
          {t('profile.button.save')}
        </ButtonSubmit>

        <span
          role="button"
          tabIndex={0}
          className="link"
          onClick={() => this.toggleEdit()}
        >
          {t('profile.button.cancel')}
        </span>
      </div>
    ) : (
      ''
    )

    return (
      <Form
        ref={(c) => {
          this.form = c
        }}
        onSubmit={this.handleSubmit.bind(this)}
      >
        <Field>
          <Input
            type="hidden"
            className="form-control"
            name="errors"
            ref={(c) => {
              this.errors = c
            }}
          />
        </Field>

        <label htmlFor="email" className="label text-4-type-2">
          <span className="label-name">{t('profile.text.email')}:</span>

          <Field>
            <Input
              value={(me && me.email) || ''}
              type="text"
              name="email"
              validations={[validation.required, validation.email]}
              ref={(c) => {
                this.email = c
              }}
              size={45}
              disabled
              lang={i18n.language}
            />
          </Field>
        </label>
        {button}
      </Form>
    )
  }

  render() {
    const { t, app } = this.props
    const { isMobile } = app
    const { me } = this.props.user

    if (!me) {
      return null
    }

    const form = this.renderForm()

    const tasksDone = getDoneAsssignmentCount(me.statistics)

    return (
      <div className="profile-page" data-testid="profilePage">
        <div className="row">
          <div className="col-1 sm-hidden" />
          <div className="col-4 col-sm-4 col-xs-2">
            <div className="account">
              <span className="avatar">
                <AvatarIcon />
              </span>
              <div className="info">{form}</div>
            </div>
          </div>
        </div>

        <div className="row extra">
          <div className="col-1 sm-hidden" />
          <div className="col-1 col-sm-1 col-xs-1">
            <div className="text-5-type-1">{t('profile.text.tasks_done')}:</div>

            <div className="gap-back" />

            <div
              className={`line-2 ${
                isMobile ? 'text-4-type-2' : 'text-3-type-1'
              }`}
            >
              {tasksDone}
            </div>

            <Link
              className="link link-gray link-history text-5-type-1"
              to={config.routes.log}
            >
              {t('profile.button.recent_history')}
            </Link>
          </div>

          <div className="gap-back sm-hidden-up" />

          <div className="col-1 col-sm-1 col-xs-2">
            {me.createdAt ? (
              <Fragment>
                <div className="text-5-type-3">{t('profile.text.status')}:</div>

                <div className="gap-back" />

                <div
                  className={`line-2 ${
                    isMobile ? 'text-4-type-2' : 'text-3-type-1'
                  }`}
                >
                  {t(userTesterStatus(me.createdAt))}
                </div>
              </Fragment>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="row row-last">
          <div className="buttons buttons-signout">
            <span className="btn btn-transparent">
              <span
                data-testid="logoutButton"
                role="button"
                tabIndex={0}
                className="link link-signout"
                onClick={this.signout.bind(this)}
              >
                {t('profile.button.logout')}
              </span>
            </span>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    Object.assign(
      {},
      popupActions,
      userActions,
      taskActions,
      authorizationActions,
    ),
    dispatch,
  ),
})
export default withTranslation()(
  connect((state) => state, mapDispatchToProps)(Profile),
)
