import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Trans, withTranslation } from 'react-i18next'
import { StatusCode, getStatusText } from '../../status_codes'
import config from '../../config'
import * as errorActions from './actions'
import * as authorizationActions from '../Authorization/actions'
import history from '../../browserHistory'
import './style.scss'
import * as popupActions from '../Popup/actions'

const bodyClass = 'red-bg'

export interface ErrorProps {
  t: any
  app: any
  error: any
  actions: any
}

class Error extends Component<ErrorProps, any> {
  public static defaultProps = {
    error: null,
  }

  componentDidMount() {
    const { error } = this.props
    const redirect = error && error.redirect

    if (redirect) {
      setTimeout(() => {
        this.props.actions.errorHide()
        history.push(redirect)
      }, 2000)
    } else {
      document.body.classList.add(bodyClass)
      const type = getStatusText(error.status, error.id)

      if (error && type === StatusCode.UserIsBanned) {
        this.props.actions.clearAuthorization()
      }

      this.props.actions.hidePopup()
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(bodyClass)
  }

  renderButtons() {
    const { t, error } = this.props
    const type = getStatusText(error.status, error.id)

    if (error && type === StatusCode.UserIsBanned) {
      return null
    }

    return (
      <div className="buttons">
        <Link className="btn btn-white-on-red" to={config.routes.label}>
          {t('error.button.goto_label')}
        </Link>

        <a
          className="link link-white"
          rel="noopener noreferrer"
          target="_blank"
          href={t('error.song_link')}
        >
          {t('error.button.listen_music')}
        </a>
      </div>
    )
  }

  renderTitle() {
    const { t, error } = this.props
    let title = (error && error.title) || (
      <span dangerouslySetInnerHTML={{ __html: t('error.title_notfound') }} />
    )
    const type = getStatusText(error.status, error.id)

    if (error && type === StatusCode.UserIsBanned) {
      title = (
        <div>
          <Trans i18nKey="error.title_banned">
            You’re banned{' '}
            <span role="img" aria-label="pensive face">
              😔
            </span>
          </Trans>
        </div>
      )
    }

    return title
  }

  renderMessage() {
    const { t, error } = this.props
    let message = (error && error.message) || t('error.message_notfound')
    const type = getStatusText(error.status, error.id)

    if (error && type === StatusCode.UserIsBanned) {
      message = (
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: t('error.message_banned'),
            }}
          />
          <br />
          <br />
          <Trans i18nKey="error.message_banned_bottom">
            Not agree? Write us{' '}
            <a href={`mailto:${config.emails.help}`}>{config.emails.help}</a>
          </Trans>
        </div>
      )
    }

    return message
  }

  render() {
    const { app } = this.props
    const { isMobile } = app

    const buttons = this.renderButtons()
    const title = this.renderTitle()
    const message = this.renderMessage()

    return (
      <div className="row">
        <div className="col-1 sm-hidden" />
        <div className="error-page col-4 col-sm-4 col-xs-2">
          <h1 className={isMobile ? 'text-1-type-2' : 'heading-2-type-1'}>
            {title}
          </h1>

          <div className="message text-3-type-3">{message}</div>

          {buttons}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    Object.assign({}, errorActions, authorizationActions, popupActions),
    dispatch,
  ),
})

export default withTranslation()(
  connect((state) => state, mapDispatchToProps)(Error),
)
