import React from 'react'

export default function FullscreenCloseIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.92893 7.34313L7.34315 5.92892L13 11.5858L18.6569 5.92892L20.0711 7.34313L14.4142 13L20.0711 18.6568L18.6569 20.0711L13 14.4142L7.34315 20.0711L5.92893 18.6568L11.5858 13L5.92893 7.34313Z"
        fill="currentColor"
      />
    </svg>
  )
}
