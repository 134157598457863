import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import popup from '../components/Popup/reducers'

import app from '../components/App/reducers'
import help from '../components/Help/reducers'
import user from '../components/User/reducers'
import authorization from '../components/Authorization/reducers'
import tasks from '../components/Task/reducers'
import terms from '../components/Terms/reducers'
import error from '../components/Error/reducers'

const createRootReducer = (history) =>
  combineReducers({
    app,
    help,
    user,
    terms,
    authorization,
    error,
    popup,
    tasks,
    router: connectRouter(history),
  })

export default createRootReducer
