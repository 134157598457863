import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Trans, withTranslation } from 'react-i18next'
import './style.scss'

export interface FAQProps {
  t: any
  app: any
}

// TODO: remove unused component
class FAQ extends Component<FAQProps> {
  static blocks() {
    return [
      {
        key: 'labeling',
        count: 10,
      },
      {
        key: 'profile',
        count: 2,
      },
    ]
  }

  static scrollTo(id) {
    document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  // check array descructuring in [...Array(block.count).keys()].map((position) =>
  static renderList(t) {
    return FAQ.blocks().map((block) => (
      <div className="faq-block" id={`block-${block.key}`} key={block.key}>
        <span id={block.key} className="faq-block-anchor" />

        <p className="faq-block-heading">
          <Trans i18nKey={`faq.list.${block.key}.title`} />
        </p>

        {
          // @ts-ignore
          [...Array(block.count).keys()].map((position) => (
            <div className="faq-item" key={`${block.key}_${position}`}>
              <div className="font-medium faq-item-title text-4-type-2">
                <Trans
                  i18nKey={`faq.list.${block.key}.item-${position}.title`}
                />
              </div>

              <div
                className="text-4-type-2"
                dangerouslySetInnerHTML={{
                  __html: t(`faq.list.${block.key}.item-${position}.text`),
                }}
              />
            </div>
          ))
        }
      </div>
    ))
  }

  render() {
    const { t, app } = this.props
    const { isMobile } = app
    const list = FAQ.renderList(t)

    return (
      <div className="faq-page row font-regular">
        <div className="col-1 sm-hidden">
          <div className="left-navigation">
            <div className="left-navigation-inner">
              {FAQ.blocks().map((block) => (
                <Fragment key={block.key}>
                  <span
                    className="font-medium text-5-type-4 left-navigation-item"
                    id={`nav-${block.key}`}
                    onClick={() => {
                      FAQ.scrollTo(block.key)
                    }}
                  >
                    <Trans i18nKey={`faq.list.${block.key}.title`} />
                  </span>

                  <div className="gap-back" />
                </Fragment>
              ))}
            </div>
          </div>
        </div>

        <div className="col-4 col-sm-4 col-xs-2">
          <div className="gap-3" />

          <h1
            className={`font-medium ${
              isMobile ? 'text-1-type-1' : 'heading-2-type-1'
            }`}
          >
            {t('faq.title')}
          </h1>

          <div className="gap-4" />

          <div className="faq-list">{list}</div>
        </div>

        <div className="col-1" />
      </div>
    )
  }
}

export default withTranslation()(connect((state) => state)(FAQ))
