import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'
import Combokeys from 'combokeys'
import config from '../../config'
import DropdownMenu from './components/DropdownMenu/DropdownMenu'
import Cross from './components/Cross/Cross'
import LogoHitl from '../Icons/LogoIcon/LogoIcon'
import Avatar from '../Icons/AvatarIcon/AvatarIcon'
import './style.scss'
import Bttn from '../Bttn'
import LogoDbrain from 'components/Icons/LogoIcon/LogoDbrain'

export interface HeaderProps extends WithTranslation {
  actions: any
  t: any
  user: any
  authorization: any
}

export interface HeaderState {
  mobileMenuOpen: boolean
}
const isEngVersion = !window.location.hostname.includes('dbrain')
class Header extends PureComponent<HeaderProps, HeaderState> {
  combokeys: Combokeys.Combokeys

  constructor(props) {
    super(props)

    this.state = {
      mobileMenuOpen: false,
    }

    this.combokeys = new Combokeys(document.documentElement)
  }

  componentWillUnmount() {
    this.combokeys.detach()
  }

  generateProfile() {
    if (!this.props.user.me) {
      return ''
    }

    const { pathname } = document.location
    const activeClassname =
      pathname === config.routes.profile ? 'heading-menu-item-active' : ''

    return (
      <Link
        className={[
          'heading-menu-item',
          'heading-menu-item-profile',
          activeClassname,
        ].join(' ')}
        to={config.routes.profile}
        onClick={this.closeMobileMenu.bind(this)}
      >
        <div className="heading-avatar">
          <Avatar />
        </div>
      </Link>
    )
  }

  generateMenuContent() {
    const { isAuth } = this.props.authorization
    const { pathname } = document.location
    const className = 'heading-menu-item'
    const profile = this.generateProfile()

    if (isAuth) {
      return (
        <div className="heading-mobile-menu-content">
          <Link
            to={config.routes.label}
            className={[
              className,
              pathname === config.routes.label ? `${className}-active` : '',
            ].join(' ')}
            onClick={this.closeMobileMenu.bind(this)}
          >
            <Trans i18nKey="header.link.tasks" />
          </Link>
          {profile}
        </div>
      )
    }

    return null
  }

  closeMobileMenu() {
    const { mobileMenuOpen } = this.state

    if (mobileMenuOpen) {
      this.setState({
        mobileMenuOpen: false,
      })
    }
  }

  handleMobileMenu() {
    this.setState((prev) => ({
      mobileMenuOpen: !prev.mobileMenuOpen,
    }))
  }

  render() {
    const { t, actions, authorization } = this.props
    const menuContent = this.generateMenuContent()

    const buttonLogin = (
      <Bttn
        size="small"
        type="black-on-gray"
        onClick={(e) => {
          e.preventDefault()
          this.closeMobileMenu()
          actions.openAuthSidebar(config.authorizationTabs.login)
        }}
      >
        {t('header.button.log_in')}
      </Bttn>
    )
    const { isAuth } = authorization
    if (!isAuth) return null

    return (
      <div className="heading">
        <Link
          to={config.routes.label}
          className="heading-logo"
          onClick={this.closeMobileMenu.bind(this)}
        >
          {isEngVersion ? <LogoHitl /> : <LogoDbrain />}
        </Link>

        <div className="heading-menu">
          <nav className="heading-menu-inner">{menuContent}</nav>
        </div>

        <div className="heading-extra">
          {authorization.isAuth ? null : buttonLogin}

          <Cross
            isOpen={this.state.mobileMenuOpen}
            onClick={this.handleMobileMenu.bind(this)}
          />
        </div>

        <DropdownMenu
          className="heading-mobile-menu"
          isOpen={this.state.mobileMenuOpen}
        >
          {menuContent}
        </DropdownMenu>
      </div>
    )
  }
}

export default withTranslation()(Header)
// export default
