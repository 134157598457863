import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import config from '../../config'
import * as userActions from '../User/actions'
import i18n from '../../i18n'
import './style.scss'

export interface ScoreProps {
  t: any
  app: any
  dispatch: any
  user: any
}

class Score extends Component<ScoreProps> {
  componentWillMount() {
    this.props.dispatch(userActions.fetchMe())
  }

  render() {
    const { t, app } = this.props
    const { isMobile } = app
    const training =
      this.props.user.me &&
      this.props.user.me.statistics &&
      this.props.user.me.statistics.onboarding
    const successRate = training
      ? `${(training.success_rate * 100).toFixed(1)}%`
      : ''
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-1 sm-hidden" />
          <div className="score-page col-4 col-sm-4 col-xs-2">
            <h1 className={isMobile ? 'text-1-type-2' : 'heading-2-type-1'}>
              {t('Your score')} {successRate}
            </h1>
            <div className="description font-regular text-3-type-3">
              {i18n.language === 'en' ? (
                <React.Fragment>
                  Well done! We&rsquo;ll notify you when Beta with new Tasks
                  is&nbsp;out. Please, share the feedback&nbsp;at{' '}
                  <a href={`mailto:${config.emails.hello}`}>
                    {config.emails.hello}
                  </a>
                  . Join the talk on AI,{' '}
                  <a href="https://facebook.com/dbrainio">
                    <span className="icon icon-facebook" />{' '}
                    <span className="link-text">like</span>
                  </a>{' '}
                  and{' '}
                  <a href="https://twitter.com/dbrainio">
                    <span className="icon icon-twitter" />{' '}
                    <span className="link-text">follow</span>
                  </a>{' '}
                  us.
                </React.Fragment>
              ) : (
                <React.Fragment>
                  Отлично! Мы&nbsp;сообщим, когда выйдет бета версия
                  с&nbsp;новыми заданиями. Оставьте ваш отзыв о&nbsp;проекте,
                  напишите нам на&nbsp;почту{' '}
                  <a href={`mailto:${config.emails.hello}`}>
                    {config.emails.hello}
                  </a>
                  , присединяйтесь к&nbsp;нашему чату про&nbsp;AI, ставьте лайк
                  и&nbsp;подписывайтесь на&nbsp;нас&nbsp;в&nbsp;
                  <a href="https://facebook.com/dbrainio">
                    <span className="icon icon-facebook" />
                  </a>{' '}
                  и{' '}
                  <a className="nowrap" href="https://twitter.com/dbrainio">
                    <span className="icon icon-twitter" />
                  </a>
                  .
                </React.Fragment>
              )}
            </div>
            <div className="gap-1" />
            <div className="gap-half sm-hidden" />
            <div className="buttons">
              <Link className="btn" to={config.routes.profile}>
                {t('Go to profile')}
              </Link>
              <a
                href="http://t.me/dbrainchat"
                rel="noopener noreferrer"
                target="_blank"
                className="link"
              >
                {t('Join Telegram chat')}
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(connect((state) => state)(Score))
