import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import * as termsActions from './actions'
import config from '../../config'
import Field from '../Form/field'
import Form from '../Form'
import ButtonSubmit from '../Button/ButtonSubmit'
import { Checkbox, CheckboxTheme } from '@dbrainio/shared-ui'
import style from './Terms.module.css'
import classNames from 'classnames'

export interface TermsProps {
  t: any
  terms: any
  actions: any
}

export interface TermsState {
  termsAccepted: boolean
}

class Terms extends Component<TermsProps, TermsState> {
  constructor(props: TermsProps) {
    super(props)

    this.state = {
      termsAccepted: true,
    }
  }

  componentWillReceiveProps(nextProps: TermsProps) {
    if (!this.props.terms.completed && nextProps.terms.completed) {
      window.location.href = config.routes.profile
    }
  }

  updateTermsStatus(value: boolean) {
    this.setState({ termsAccepted: value })
  }

  agreeWithTerms(e: any) {
    e.preventDefault()

    const user = { tos_accepted: this.state.termsAccepted }
    this.props.actions.confirmTerms(user)
  }

  render() {
    const { t } = this.props

    return (
      <div className={style.row}>
        <div className="row">
          <h1 className="font-medium heading-2-type-2">{t('terms.title')}</h1>

          <div className="gap-1" />

          <div
            className={classNames(
              'font-regular text-3-type-1',
              style.termsDescription,
            )}
          >
            {t('terms.description_pre')}&nbsp;
            <a target="_blank" href={config.links.TOS}>
              {t('terms.description')}
            </a>
            .
          </div>

          <Form>
            <Field className={style.checkbox}>
              <Checkbox
                theme={CheckboxTheme.Black}
                onChange={this.updateTermsStatus.bind(this)}
                isChecked={this.state.termsAccepted}
                label={t('terms.acceptance')}
              />
            </Field>

            <ButtonSubmit
              id="submit-button"
              className="btn"
              onClick={this.agreeWithTerms.bind(this)}
            >
              {t('terms.button')}
            </ButtonSubmit>

            {this.props.terms.error ? (
              <p className={style.termsErrorBottom}>{t('terms.error')}</p>
            ) : (
              ''
            )}
          </Form>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(Object.assign({}, termsActions), dispatch),
})

export default withTranslation()(
  connect((state) => state, mapDispatchToProps)(Terms),
)
