import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import config from '../../config'
import './style.scss'

const bodyClass = ['red-bg', 'not-available']

export interface NotAvailableProps {
  app: any
  t: any
}

class NotAvailable extends Component<NotAvailableProps> {
  componentDidMount() {
    document.body.classList.add(...bodyClass)
  }

  componentWillUnmount() {
    document.body.classList.remove(...bodyClass)
  }

  render() {
    const { app, t } = this.props
    const { isMobile } = app

    return (
      <div className="row">
        <div className="col-1 sm-hidden" />
        <div className="col-4 col-sm-4 col-xs-2">
          <h1
            className={`font-medium ${
              isMobile ? 'text-1-type-2' : 'heading-2-type-1'
            }`}
            dangerouslySetInnerHTML={{
              __html: t('unavailable.title'),
            }}
          />

          <div className="social">
            <a
              className="icon tg"
              href={config.links.social.telegram}
              target="_blank"
              rel="noopener noreferrer"
            >
              Telegram
            </a>

            <a
              className="icon fb"
              href={config.links.social.facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>

            <a
              className="icon tw"
              href={config.links.social.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(connect((state) => state)(NotAvailable))
