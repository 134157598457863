import React, { Component } from 'react'
import {
  Label,
  Aabb,
  Ocr,
  Rlasso,
  OcrMultiple,
  LabelMultiple,
  TextCategorization,
  BboxExtended,
} from '../../types'

const getType = (
  type: string,
):
  | 'label'
  | 'aabb'
  | 'ocr'
  | 'ocrmultiple'
  | 'rlasso'
  | 'bboxExtended'
  | 'labels'
  | 'text_categorization'
  | string => {
  switch (type) {
    case undefined:
    case null:
    case 'multilabel':
    case 'multilabel_group':
      return 'label'
    case 'segmentation':
      return 'rlasso'
    case 'bbox_extended':
      return 'bboxExtended'
    case 'ocrs':
      return 'ocrmultiple'
    default:
      return type
  }
}

export interface TypeSwitcherProps {
  name: string
}

interface Props {
  name?:
    | 'multilabel'
    | 'multilabel_group'
    | 'segmentation'
    | 'ocrmultiple'
    | 'bbox_extended'
    | 'ocrs'
    | null
  tasks: any
  t: any
  task: any
  markTask: any
  skipTask: any
  testLabel?: React.ReactNode
  fullDescriptionPopup?: React.ReactNode
  caption?: React.ReactNode
  description?: React.ReactNode
  isFirstDescriptionShow: boolean
  resolvedTasks: any[]
  actions: any
  normalizePoints: any
}

export default function Typeswitcher(props: Props) {
  const { name, ...innerProps } = props

  const type = getType(name)

  if (type === 'label') {
    return <Label {...innerProps} />
  }
  if (type === 'aabb') {
    return <Aabb {...innerProps} />
  }
  if (type === 'ocr') {
    return <Ocr {...innerProps} />
  }
  if (type === 'ocrmultiple') {
    return <OcrMultiple {...innerProps} />
  }
  if (type === 'rlasso') {
    return <Rlasso {...innerProps} />
  }
  if (type === 'bboxExtended') {
    return <BboxExtended {...innerProps} />
  }
  if (type === 'labels') {
    return <LabelMultiple {...innerProps} />
  }
  if (type === 'text_categorization') {
    return <TextCategorization {...innerProps} />
  }

  return null
}
