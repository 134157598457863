import * as types from './constants'

const initialState = {
  id: null,
  isActive: false,
}

export default function reducers(state = initialState, action: any) {
  switch (action.type) {
    case types.HIDE_POPUP:
      return { ...state, isActive: false, id: initialState.id }
    case types.SHOW_POPUP:
      return { ...state, isActive: true, id: action.id }
    default:
      return state
  }
}
