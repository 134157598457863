import * as api from './api'
import * as types from './constants'
import { err2str } from '../../utils/common'
import history from '../../browserHistory'
import config from '../../config'

const getResponseId = (o) => {
  if (o.response && o.response.data && o.response.data.id) {
    return o.response.data.id
  }

  return null
}

export const setIsAuth = (isAuth) => (dispatch) =>
  dispatch({
    type: types.SET_IS_AUTH,
    isAuth,
  })

export const login = (user) => (dispatch) => {
  dispatch({
    type: types.LOGIN_REQUEST,
  })

  return api
    .login(user)
    .then(() => {
      dispatch({
        type: types.SET_IS_AUTH,
        isAuth: true,
      })
    })
    .catch((error) => {
      dispatch({
        type: types.LOGIN_ERROR,
        error: {
          message: err2str(error),
          id: getResponseId(error),
        },
      })
    })
}
export const signUp = (user) => (dispatch) => {
  dispatch({
    type: types.SIGNUP_REQUEST,
  })

  return api
    .signup(user)
    .then((data) => {
      dispatch({
        type: types.SIGNUP_SUCCESS,
        me: data.data.user,
      })
      history.push(config.routes.verification)
    })
    .catch((error) => {
      dispatch({
        type: types.SIGNUP_ERROR,
        error: {
          message: err2str(error),
          id: getResponseId(error),
        },
      })
    })
}

export const sendRecoveryEmail = (args) => (dispatch) => {
  dispatch({
    type: types.RECOVERY_REQUEST,
  })

  return api
    .sendRecoveryEmail(args)
    .then(() => {
      dispatch({
        type: types.RECOVERY_SUCCESS,
        email: args.email,
      })
    })
    .catch((error) => {
      dispatch({
        type: types.RECOVERY_ERROR,
        error: {
          message: err2str(error),
          id: getResponseId(error),
        },
      })
    })
}

export const createPassword = (args) => (dispatch) =>
  api
    .createPassword(args)
    .then(() => {
      dispatch({
        type: types.SET_IS_AUTH,
        isAuth: true,
      })
    })
    .catch((error) => {
      dispatch({
        type: types.CREATE_PASSWORD_ERROR,
        error: {
          message: err2str(error),
          id: getResponseId(error),
        },
      })
    })

export const verification = (code) => (dispatch) =>
  api
    .verification(code)
    .then(() => {
      api.me().then((data) => {
        dispatch({
          type: types.SET_IS_AUTH,
          isAuth: true,
        })
        dispatch({
          type: types.UPDATE_ME,
          me: data.data.user,
        })
      })
    })
    .catch(() => {
      history.push(config.routes.default)
    })

export const signOut = () => (dispatch) => {
  api.clearAuthorization()
  dispatch({
    type: types.CLEAR_ME,
  })
  history.push(config.routes.default)
}

export const clearAuthorization = () => (dispatch) => {
  api.clearAuthorization()
  dispatch({
    type: types.CLEAR_ME,
  })
}

export const openAuthSidebar = (name) => ({
  type: types.SET_ACTIVE_TAB_NAME,
  name,
})

export const closeAuthSidebar = () => ({
  type: types.CLEAR_ACTIVE_TAB_NAME,
})
