import React, { Component } from 'react'
import { Trans, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as authorizationActions from '../Authorization/actions'
import history from '../../browserHistory'
import config from '../../config'
import './style.scss'

export interface VerificationProps {
  t: any
  app: any
  actions: any
  location: any
  user: any
}

class Verification extends Component<VerificationProps> {
  componentWillMount() {
    const { location, actions, user } = this.props

    let code = null
    if (location.search.includes('code')) {
      code = location.search.split('=')[1]
      actions.verification(code)
    }

    if (!code && !user.me) {
      history.push(config.routes.default)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.me && nextProps.user.me.status === 'active') {
      history.push(config.routes.welcome)
    }
  }

  render() {
    const { t, user, app } = this.props
    const { isMobile } = app

    if (!user.me) {
      return true
    }

    const mail = user.me.email
    const headingClassName = `${
      isMobile ? 'text-1-type-1' : 'heading-2-type-1'
    } font-medium`

    return (
      <div className="row">
        <div className="col-1 sm-hidden" />
        <div className="col-4 col-sm-4 col-xs-2 verification">
          <h1 className={headingClassName}>{t('verify.title')}</h1>

          <div className="desc">
            <Trans i18nKey="verify.text">
              An email with a link has been sent to <b>{{ mail }}</b>, you know
              what to do.
            </Trans>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    Object.assign({}, authorizationActions),
    dispatch,
  ),
})
export default withTranslation()(
  connect((state) => state, mapDispatchToProps)(Verification),
)
