import React, { PureComponent } from 'react'
import './style.scss'

export interface CrossProps {
  onClick: () => void
  isOpen: boolean
}

class Cross extends PureComponent<CrossProps> {
  render() {
    return (
      <button
        onClick={(e) => {
          e.preventDefault()
          this.props.onClick()
        }}
        className={[
          'heading-extra-mobile-caller',
          'hamburger',
          this.props.isOpen ? 'enabled' : 'disabled',
        ].join(' ')}
      >
        <span className="hamburger-line" />
        <span className="hamburger-line" />
      </button>
    )
  }
}

export default Cross
