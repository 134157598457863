import axios, { AxiosRequestConfig } from 'axios'
import history from '../../browserHistory'
import { isTouchDevice, getDeviceType } from '../../utils/common'
import config from '../../config'

axios.defaults.headers.common['dbr-device-width'] = window.innerWidth
axios.defaults.headers.common['dbr-device-type'] = getDeviceType()
axios.defaults.headers.common['dbr-device-touch'] = isTouchDevice()
axios.defaults.headers.common['Accept-Language'] = navigator.languages.includes(
  'ru-RU',
)
  ? 'ru-RU'
  : navigator.language

const setToken = (token: string) => {
  localStorage.setItem('token', token)
}

const setExpire = (expire: string) => {
  localStorage.setItem('expire', expire)
}

const getToken = (): string | null => localStorage.getItem('token')

const getExpire = (): string | null => localStorage.getItem('expire')

const delToken = (): void => localStorage.removeItem('token')

const delExpire = (): void => localStorage.removeItem('expire')

function isExpired() {
  if (!getToken()) {
    return true
  }
  const getExpireValue = getExpire()
  if (!getExpireValue) {
    return true
  }

  return new Date(getExpireValue) < new Date()
}

export const updateAuthHeader = () => {
  if (!isExpired()) {
    axios.defaults.headers.common.Authorization = `Bearer ${getToken()}`
  }
}

export const isLoggedIn = (): boolean => {
  updateAuthHeader()
  return !!getToken() && !isExpired()
}

export const refreshToken = () => {
  if (!isLoggedIn()) {
    history.push(config.routes.default)
  } else {
    return axios
      .get(`${config.api.web}/refresh_token`)
      .then((data) => {
        setToken(data.data.token)
        setExpire(data.data.expire)
        updateAuthHeader()
      })
      .catch(() => {
        history.push(config.routes.default)
      })
  }
  return true
}

export function login(user) {
  const { solution, captchaId, ...userInfo } = user
  return axios
    .post(
      `${config.api.web}/login?captcha_id=${captchaId}&solution=${solution}`,
      userInfo,
    )
    .then((data) => {
      setToken(data.data.token)
      setExpire(data.data.expire)
      updateAuthHeader()
    })
}

export const signup = (user) => axios.post(`${config.api.web}/users`, user)

export const clearAuthorization = () => {
  delToken()
  delExpire()
  delete axios.defaults.headers.common.Authorization
}

export const me = (args?: AxiosRequestConfig) =>
  axios.get(`${config.api.web}/users/me`, args)

export const changeMe = (user) => axios.put(`${config.api.web}/users/me`, user)

export const sendRecoveryEmail = (args?: AxiosRequestConfig) =>
  axios.post(`${config.api.web}/reset_password`, args)

export const createPassword = (args?: AxiosRequestConfig) =>
  axios.post(`${config.api.web}/confirm_reset_password`, args).then((data) => {
    setToken(data.data.token)
    setExpire(data.data.expire)
    updateAuthHeader()
  })

export const verification = (code) =>
  axios.post(`${config.api.web}/activate_account`, { code }).then((data) => {
    setToken(data.data.token)
    setExpire(data.data.expire)
    updateAuthHeader()
  })

export function getUserStat(userId) {
  const limit = 50
  return axios.get(`${config.api.web}/users/${userId}/stats?limit=${limit}`)
}

updateAuthHeader()
