import React from 'react'

const HelpFilledIcon = () => {
  return (
    <svg
      version="1.2"
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 18 18"
      xmlSpace="preserve"
    >
      <g>
        <circle fill="currentColor" cx="9" cy="9" r="9" />
      </g>
      <g>
        <path fill="#FAFBFB" d="M9.8,13.2H8.2V7.3h1.6V13.2z" />
      </g>
      <circle fill="#FAFBFB" cx="9" cy="5.3" r="0.9" />
    </svg>
  )
}

export default HelpFilledIcon
