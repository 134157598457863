import React from 'react'

const activeClassName = '_spinner_active'

export interface SpinnerProps {
  className?: string
  size?: [number, number]
  isStart?: boolean
}

export default class Spinner extends React.PureComponent<SpinnerProps> {
  longest: any
  cont: any
  componentDidMount() {
    this.longest.addEventListener('animationend', () => {
      this.handleAnimationIterationEnd()
    })
  }

  componentWillUnmount() {
    this.longest = null
  }

  handleAnimationIterationEnd() {
    const { isStart } = this.props

    if (isStart) {
      this.cont.classList.remove(activeClassName)
      setTimeout(() => {
        this.cont.classList.add(activeClassName)
      }, 100)
    }
  }

  render() {
    const { size = [48, 48], isStart = true, className } = this.props

    const additionalClassName = className ? className : ''
    const startClassName = isStart ? activeClassName : ''

    return (
      <div className={`_spinner-wrapper ${additionalClassName}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          ref={(c) => {
            this.cont = c
          }}
          className={`_spinner ${startClassName}`}
          style={{ width: size[0], height: size[1] }}
        >
          <path d="M36,3.22,32,10.1a16.17,16.17,0,0,0-8-2.19V0a22.88,22.88,0,0,1,6.21.82A23.08,23.08,0,0,1,36,3.22Z" />
          <path d="M44.78,12l-6.92,4A15.7,15.7,0,0,0,32,10.1l4-6.88A23.91,23.91,0,0,1,44.78,12Z" />
          <path d="M40,24v-.09a15.81,15.81,0,0,0-.54-4A15.41,15.41,0,0,0,37.86,16l6.92-4a22.93,22.93,0,0,1,2.4,5.79A22.88,22.88,0,0,1,48,24Z" />
          <path d="M48,24a22.83,22.83,0,0,1-.82,6.21A22.78,22.78,0,0,1,44.78,36l-7-4a15.62,15.62,0,0,0,1.61-3.83A16.09,16.09,0,0,0,40,24Z" />
          <path d="M44.78,36A22.8,22.8,0,0,1,41,41a23,23,0,0,1-5,3.82l-4.06-7a16.31,16.31,0,0,0,3.31-2.51A16,16,0,0,0,37.78,32Z" />
          <path d="M36,44.79a23.05,23.05,0,0,1-5.79,2.39A22.88,22.88,0,0,1,24,48V39.91a16.25,16.25,0,0,0,7.94-2.14Z" />
          <path d="M24,39.91V48a23,23,0,0,1-6.22-.82A22.83,22.83,0,0,1,12,44.79l4-7A16.38,16.38,0,0,0,24,39.91Z" />
          <path d="M16,37.79l-4,7A22.53,22.53,0,0,1,7,41a22.53,22.53,0,0,1-3.82-5l7-4a16.07,16.07,0,0,0,2.55,3.3A15.61,15.61,0,0,0,16,37.79Z" />
          <path d="M10.17,32l-7,4a22.78,22.78,0,0,1-2.4-5.79A22.82,22.82,0,0,1,0,24H8a16.1,16.1,0,0,0,2.2,8Z" />
          <path d="M10.09,16A16.32,16.32,0,0,0,8,23.91V24H0A23.87,23.87,0,0,1,3.21,12Z" />
          <path
            ref={(c) => {
              this.longest = c
            }}
            d="M16,10.08a16,16,0,0,0-3.34,2.55A16.23,16.23,0,0,0,10.09,16L3.21,12A23,23,0,0,1,7,7a22.8,22.8,0,0,1,5-3.81Z"
          />
          <path d="M24,0V7.91h0a16.22,16.22,0,0,0-4.14.56A15.9,15.9,0,0,0,16,10.08L12,3.22A22.86,22.86,0,0,1,17.78.82,23,23,0,0,1,24,0Z" />
        </svg>
      </div>
    )
  }
}
