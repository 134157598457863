import * as types from './constants'

export const hidePopup = () => ({
  type: types.HIDE_POPUP,
})

export const showPopup = (id: any) => ({
  type: types.SHOW_POPUP,
  id,
})
