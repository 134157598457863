import React, { Component, SyntheticEvent } from 'react'
import Combokeys from 'combokeys'
import { v4 as uuidv4 } from 'uuid'
import {
  offset,
  getX,
  getY,
  setNoScroll,
  path2svg,
} from '../../../../../utils/common'
import config from '../../../../../config'
import { fixPolygonDirection } from '../../../../../utils/tasks'
import TaskSourceControls from '../../../components/TaskSourceControls/TaskSourceControls'
import FullscreenIcon from '../../../../Icons/FullscreenIcon/FullscreenIcon'
import ZoomInIcon from '../../../../Icons/ZoomInIcon/ZoomInIcon'
import ZoomOutIcon from '../../../../Icons/ZoomOutIcon/ZoomOutIcon'
import FullscreenCloseIcon from '../../../../Icons/FullscreenCloseIcon/FullscreenCloseIcon'
import debounce from 'lodash/debounce'

import '../style.scss'
import Button from '../../../../Button'
import Scroller from '../../../../Scroller'

export interface BboxExtendedCoreProps {
  t: any
  container: string
  coreref?: any
  imageUrl?: string
  paths?: any
  label?: string
  index?: number
  clearPaths?: any
  pathStyle: any
  onPathsUpdate: any
  imageRef: any
  coreMiddleRef: React.RefObject<HTMLDivElement>
  innerFullscreenSize: any
  isFullscreen?: boolean
  imageFullscreenSize: any
  fullscreenOffsetLeft: number
  fullscreenOffsetTop: number
  zoomRatioPercentage: number
  changeZoomRatio: any
  handleCanvasMouseDown: (isMouseDown: boolean) => void
  handleButtonToggleFullscreen: (
    zoomRatioPercentage: number,
    fullscreenCallback: () => void,
  ) => void
}

export interface ExtendedBboxCoreState {
  paths:
    | {
        [key: string]: RlassoPath
      }
    | {}
  hoverPathId: string | null
  currentlyBeingCreatedPathId: string | null
}

export interface RlassoNode {
  // relative x. is like 0.35175574204946997. between 0 and 1
  rx: number
  // relative y. between 0 and 1
  ry: number
  x: number
  y: number
}

export interface RlassoSVG {
  height: number
  width: number
  x: number
  y: number
  path: string
}

export interface RlassoPath {
  // bbox* used for centering delete icon and text (rect number)
  bboxHeight: number
  bboxWidth: number
  bboxX: number
  bboxY: number
  startX: number
  startY: number
  // try to remove rx, ry, x, y here
  rx: number
  ry: number
  x: number
  y: number
  showControls: boolean
  showDelete: boolean
  isCreated: boolean
  createdAt: number
  index: number
  label: string
  style: React.CSSProperties
  svg: RlassoSVG
  path: RlassoNode[]
}

export default class BboxExtendedCore extends Component<
  BboxExtendedCoreProps,
  ExtendedBboxCoreState
> {
  maxZoom: number
  minZoom: number
  zoomStep: number
  combokeys: Combokeys.Combokeys

  onResize: () => void
  mouseup: null | (() => void)
  mousemove: null | ((e: MouseEvent) => void)

  constructor(props) {
    super(props)

    this.state = {
      // paths: this.props.paths || {},
      paths: this.props.paths || {},
      hoverPathId: null,
      currentlyBeingCreatedPathId: null,
    }

    this.maxZoom = 2
    this.minZoom = 0.5
    this.zoomStep = 0.1

    this.combokeys = new Combokeys(document.documentElement)
  }

  componentWillMount() {
    this.onResize = debounce(this.setResolution, 250)
    window.addEventListener('resize', this.onResize)
    document.addEventListener('mousemove', this.onMouseMove)
    document.addEventListener('mouseup', this.onMouseUp)
    document.addEventListener('touchmove', this.onMouseMove)
    document.addEventListener('touchend', this.onMouseUp)

    this.combokeys.bind('esc', () => {
      this.handleEscKeyPress()
    })
  }

  // if task changed to next then clear current paths
  componentWillReceiveProps(nextProps: BboxExtendedCoreProps) {
    if (
      Object.keys(nextProps.paths).length !==
      Object.keys(this.state.paths).length
    ) {
      this.setState({
        paths: nextProps.paths,
      })
    }
  }

  componentDidMount() {
    this.setResolution()
    this.setCombokeys()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
    document.removeEventListener('mouseup', this.onMouseUp)
    document.removeEventListener('touchend', this.onMouseUp)

    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('touchmove', this.onMouseMove)
    this.combokeys.detach()
  }

  setCombokeys() {
    const { fullscreen } = config.hotKeys

    this.combokeys.bind(fullscreen.key, () => {
      this.props.handleButtonToggleFullscreen(1, this.setResolution)
    })
  }

  // set resolution on mount, zoom in & out
  setResolution = () => {
    const { paths } = this.state
    Object.keys(paths).forEach((id) => this.fixPath(paths[id], true))

    this.setState({ paths })
  }

  handleEscKeyPress() {
    const { currentlyBeingCreatedPathId, paths } = this.state
    if (!currentlyBeingCreatedPathId) return

    delete paths[currentlyBeingCreatedPathId]
    this.setState({
      paths: paths,
      currentlyBeingCreatedPathId: null,
      hoverPathId: null,
    })
  }

  onMouseMove = (e: MouseEvent) => {
    if (this.mousemove) {
      this.mousemove(e)
    }
  }

  onMouseUp = (e) => {
    if (this.mouseup) {
      this.mouseup()
    }
  }

  endCreatingBbox = (
    pathId: string,
    event?:
      | React.TouchEvent<SVGCircleElement>
      | React.MouseEvent<SVGCircleElement, MouseEvent>,
  ) => {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }

    const { paths } = this.state

    paths[pathId].isCreated = true
    paths[pathId].createdAt = +new Date()
    paths[pathId].showDelete = true
    this.mousemove = null
    this.fixPath(paths[pathId])
    this.setState({ paths, currentlyBeingCreatedPathId: null })
  }

  fixPath = (box: RlassoPath, reverse?: any) => {
    // image demensions on the screen
    const { offsetWidth: imageOffsetWidth, offsetHeight: imageOffsetHeight } =
      this.props.imageRef.current

    let x1 = imageOffsetWidth + 1
    let y1 = imageOffsetHeight + 1
    let x2 = -1
    let y2 = -1
    for (let i = 0; i < box.path.length; i += 1) {
      if (reverse) {
        // works in zoom mode on zoom in/out
        box.path[i].x = box.path[i].rx * imageOffsetWidth
        box.path[i].y = box.path[i].ry * imageOffsetHeight
      } else {
        // limit point coordinates with image dimensions
        box.path[i].x = Math.min(Math.max(box.path[i].x, 0), imageOffsetWidth)
        box.path[i].y = Math.min(Math.max(box.path[i].y, 0), imageOffsetHeight)
        box.path[i].rx = box.path[i].x / imageOffsetWidth
        box.path[i].ry = box.path[i].y / imageOffsetHeight
      }

      x1 = Math.min(x1, box.path[i].x)
      y1 = Math.min(y1, box.path[i].y)
      x2 = Math.max(x2, box.path[i].x)
      y2 = Math.max(y2, box.path[i].y)
    }

    // next part creates bbox for centering
    // maybe they are unused so try to remove rx, ry, x, y here
    if (reverse) {
      box.rx = Math.min(Math.max(box.rx, 0), 1)
      box.ry = Math.min(Math.max(box.ry, 0), 1)
      box.x = box.rx * imageOffsetWidth
      box.y = box.ry * imageOffsetHeight
    } else {
      box.x = Math.min(Math.max(box.x, 0), imageOffsetWidth)
      box.y = Math.min(Math.max(box.y, 0), imageOffsetHeight)
      box.rx = box.x / imageOffsetWidth
      box.ry = box.y / imageOffsetHeight
    }

    if (!box.isCreated) {
      x1 = Math.min(x1, box.x)
      y1 = Math.min(y1, box.y)
      x2 = Math.max(x2, box.x)
      y2 = Math.max(y2, box.y)
    }

    box.bboxX = x1
    box.bboxY = y1
    box.bboxWidth = x2 - x1
    box.bboxHeight = y2 - y1

    const polygonFixedPath = fixPolygonDirection(box.path, null, (p) => [
      p.rx,
      p.ry,
    ])
    box.svg = path2svg(0, 0, polygonFixedPath, false)
  }

  startCreatingBbox = (
    e:
      | React.MouseEvent<SVGSVGElement, MouseEvent>
      | React.TouchEvent<SVGSVGElement>,
  ) => {
    const { label, index, pathStyle } = this.props
    const { paths } = this.state
    let { currentlyBeingCreatedPathId } = this.state

    if (!label) {
      return
    }

    e.stopPropagation()
    e.preventDefault()

    setNoScroll(true)

    const startX = getX(e)
    const startY = getY(e)

    const { top, left } = offset(this.props.imageRef.current)
    const x = startX - left
    const y = startY - top

    currentlyBeingCreatedPathId = uuidv4()

    paths[currentlyBeingCreatedPathId] = {
      label,
      style: pathStyle,
      index,
      isCreated: false,
      showDelete: false,
      startX: x,
      startY: y,
      path: [
        { x, y },
        { x: x + 30, y },
        { x: x + 30, y: y - 30 },
        { x, y: y - 30 },
      ],
      x,
      y,
      bboxX: x,
      bboxY: y,
      bboxWidth: 0,
      bboxHeight: 0,
    }

    this.fixPath(paths[currentlyBeingCreatedPathId])
    this.setState({ currentlyBeingCreatedPathId, paths })

    this.mousemove = this.handleMouseMoveWhenCreatePoint
    this.mouseup = this.handleMouseUp
  }

  handleMouseMoveWhenCreatePoint = (e: MouseEvent) => {
    const { currentlyBeingCreatedPathId, paths } = this.state
    const { imageRef } = this.props

    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (!currentlyBeingCreatedPathId) return

    const o = offset(imageRef.current)
    const _x = getX(e) - o.left
    const _y = getY(e) - o.top
    const currentPath = paths[currentlyBeingCreatedPathId]
    const { startX, startY } = currentPath

    currentPath.x = _x
    currentPath.y = _y
    const newPath = [
      { x: startX, y: startY },
      { x: _x, y: startY },
      { x: _x, y: _y },
      { x: startX, y: _y },
    ]

    paths[currentlyBeingCreatedPathId].path = newPath

    this.fixPath(currentPath)

    if (currentPath && !currentPath.showControls) {
      this.switchPathControls(currentlyBeingCreatedPathId, true)
    }

    this.setState({ paths })
  }

  handleMouseMoveWhenMovePoint = (
    movingPath: any,
    index: number,
    startX: number,
    startY: number,
    pathId: string,
  ) => {
    const { paths } = this.state

    const x0 = movingPath.path[index].x
    const y0 = movingPath.path[index].y

    return (moveEvent) => {
      if (moveEvent) {
        moveEvent.preventDefault()
        moveEvent.stopPropagation()
      }

      const x = getX(moveEvent)
      const y = getY(moveEvent)
      movingPath.path[index].x = x0 + x - startX
      movingPath.path[index].y = y0 + y - startY
      movingPath.x = x0 + x - startX
      movingPath.y = y0 + y - startY
      this.fixPath(movingPath)
      if (movingPath && !movingPath.showControls) {
        this.switchPathControls(pathId, true)
      }
      paths[pathId] = movingPath
      this.setState({ paths })
    }
  }

  handleMouseUp = () => {
    const { onPathsUpdate } = this.props
    const { paths, currentlyBeingCreatedPathId } = this.state

    if (currentlyBeingCreatedPathId) {
      this.endCreatingBbox(currentlyBeingCreatedPathId)
    }

    setNoScroll(false)
    this.mousemove = null
    this.mouseup = null
    onPathsUpdate(paths)
  }

  deletePath = (
    pathIdToDelete,
    e:
      | React.MouseEvent<SVGCircleElement, MouseEvent>
      | React.TouchEvent<SVGCircleElement>,
  ) => {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    const { paths, currentlyBeingCreatedPathId } = this.state
    const { onPathsUpdate } = this.props
    delete paths[pathIdToDelete]

    if (pathIdToDelete === currentlyBeingCreatedPathId) {
      this.setState({
        paths,
        currentlyBeingCreatedPathId: null,
        hoverPathId: null,
      })
    } else {
      this.setState({
        paths,
        hoverPathId: null,
      })
    }
    onPathsUpdate(paths)
  }

  startRotate(pathId: string, e, direction: 'clockwise' | 'anticlockwise') {
    const { paths } = this.state
    const { label, handleCanvasMouseDown } = this.props
    const movingPath = paths[pathId]

    if (!label || movingPath.label !== label) {
      handleCanvasMouseDown(false)
      return
    }

    e.stopPropagation()
    e.preventDefault()

    handleCanvasMouseDown(true)

    if (!movingPath.showControls) {
      this.switchPathControls(pathId, true)
    }

    setNoScroll(true)

    this.mousemove = this.handleMouseMoveRotate(movingPath, pathId, direction)
    this.mouseup = this.handleMouseUp
  }

  handleMouseMoveRotate = (
    movingPath: any,
    pathId: string,
    direction: 'clockwise' | 'anticlockwise',
  ) => {
    const { paths } = this.state
    return (moveEvent) => {
      if (moveEvent) {
        moveEvent.preventDefault()
        moveEvent.stopPropagation()
      }

      const x0 = movingPath.path[0].x
      const y0 = movingPath.path[0].y

      // https://math.stackexchange.com/questions/270194/how-to-find-the-vertices-angle-after-rotation
      const step = direction === 'clockwise' ? 0.01 : -0.01
      var c = Math.cos(step)
      var s = Math.sin(step)

      const cx = movingPath.bboxX + movingPath.bboxWidth / 2
      const cy = movingPath.bboxY + movingPath.bboxHeight / 2

      const x1 =
        (movingPath.path[0].x - cx) * c - (movingPath.path[0].y - cy) * s + cx
      const y1 =
        (movingPath.path[0].x - cx) * s + (movingPath.path[0].y - cy) * c + cy
      const x2 =
        (movingPath.path[1].x - cx) * c - (movingPath.path[1].y - cy) * s + cx
      const y2 =
        (movingPath.path[1].x - cx) * s + (movingPath.path[1].y - cy) * c + cy
      const x3 =
        (movingPath.path[2].x - cx) * c - (movingPath.path[2].y - cy) * s + cx
      const y3 =
        (movingPath.path[2].x - cx) * s + (movingPath.path[2].y - cy) * c + cy
      const x4 =
        (movingPath.path[3].x - cx) * c - (movingPath.path[3].y - cy) * s + cx
      const y4 =
        (movingPath.path[3].x - cx) * s + (movingPath.path[3].y - cy) * c + cy

      movingPath.path[0].x = x1
      movingPath.path[0].y = y1
      movingPath.path[1].x = x2
      movingPath.path[1].y = y2
      movingPath.path[2].x = x3
      movingPath.path[2].y = y3
      movingPath.path[3].x = x4
      movingPath.path[3].y = y4

      this.fixPath(movingPath)
      if (movingPath && !movingPath.showControls) {
        this.switchPathControls(pathId, true)
      }
      paths[pathId] = movingPath
      this.setState({ paths })
    }
  }

  rotatePath = (
    pathIdToRotate,
    e?:
      | React.MouseEvent<SVGTextElement, MouseEvent>
      | React.TouchEvent<SVGTextElement>,
  ) => {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    const { paths } = this.state

    const movingPath = paths[pathIdToRotate]

    var c = Math.cos(0.05)
    var s = Math.sin(0.05)

    const cx = movingPath.bboxX + movingPath.bboxWidth / 2
    const cy = movingPath.bboxY + movingPath.bboxHeight / 2

    const x1 =
      (movingPath.path[0].x - cx) * c - (movingPath.path[0].y - cy) * s + cx
    const y1 =
      (movingPath.path[0].x - cx) * s + (movingPath.path[0].y - cy) * c + cy
    const x2 =
      (movingPath.path[1].x - cx) * c - (movingPath.path[1].y - cy) * s + cx
    const y2 =
      (movingPath.path[1].x - cx) * s + (movingPath.path[1].y - cy) * c + cy
    const x3 =
      (movingPath.path[2].x - cx) * c - (movingPath.path[2].y - cy) * s + cx
    const y3 =
      (movingPath.path[2].x - cx) * s + (movingPath.path[2].y - cy) * c + cy
    const x4 =
      (movingPath.path[3].x - cx) * c - (movingPath.path[3].y - cy) * s + cx
    const y4 =
      (movingPath.path[3].x - cx) * s + (movingPath.path[3].y - cy) * c + cy

    movingPath.path[0].x = x1
    movingPath.path[0].y = y1
    movingPath.path[1].x = x2
    movingPath.path[1].y = y2
    movingPath.path[2].x = x3
    movingPath.path[2].y = y3
    movingPath.path[3].x = x4
    movingPath.path[3].y = y4
    this.fixPath(movingPath)

    paths[pathIdToRotate] = movingPath

    this.setState({ paths })
  }

  movePoint(pathId, index, e) {
    const { paths } = this.state
    const { label, handleCanvasMouseDown } = this.props
    const movingPath = paths[pathId]

    if (!label || movingPath.label !== label) {
      handleCanvasMouseDown(false)
      return
    }

    e.stopPropagation()
    e.preventDefault()

    handleCanvasMouseDown(true)

    if (!movingPath.showControls) {
      this.switchPathControls(pathId, true)
    }

    setNoScroll(true)
    const startX = getX(e)
    const startY = getY(e)

    this.mousemove = this.handleMouseMoveWhenMovePoint(
      movingPath,
      index,
      startX,
      startY,
      pathId,
    )
    this.mouseup = this.handleMouseUp
  }

  handleCanvasMouseup = () => {
    this.props.handleCanvasMouseDown(false)
  }

  switchPathControls = (pathId: string, isVisible: boolean) => {
    const { paths } = this.state
    Object.keys(paths).forEach((rId) => {
      if (Object.prototype.hasOwnProperty.call(paths, rId)) {
        paths[rId].showControls = false
      }
    })
    paths[pathId].showControls = isVisible
    this.setState({ paths, hoverPathId: isVisible ? pathId : null })
  }

  undo = (e) => {
    e.preventDefault()

    const { paths, currentlyBeingCreatedPathId } = this.state

    if (currentlyBeingCreatedPathId === null) {
      let newest = 0
      let newestUuid

      Object.keys(paths).forEach((id) => {
        const current = paths[id].createdAt

        if (current > newest) {
          newest = current
          newestUuid = id
        }
      })

      delete paths[newestUuid]
    } else {
      delete paths[currentlyBeingCreatedPathId]
    }

    this.props.onPathsUpdate(paths)
    this.setState({ paths, currentlyBeingCreatedPathId: null })
  }

  clear = (e) => {
    e.preventDefault()
    this.setState({ paths: {}, currentlyBeingCreatedPathId: null })
    this.props.clearPaths()
  }

  handleZoomButtonClick(type, callback) {
    const { zoomRatioPercentage, changeZoomRatio } = this.props

    const zoomRatio =
      type === 'up'
        ? zoomRatioPercentage + this.zoomStep
        : zoomRatioPercentage - this.zoomStep

    changeZoomRatio(parseFloat(zoomRatio.toFixed(1)), callback)
  }

  renderCrossDelete = (path, pathId) => {
    const delSize = 9
    const bbX = path.bboxX + path.bboxWidth / 2
    const bbY = path.bboxY + path.bboxHeight / 2
    const crossRadius = delSize * (2 / 3)
    const crossLineSize = Math.sqrt((crossRadius * crossRadius) / 2)

    return (
      <g>
        <circle
          cx={bbX}
          cy={bbY}
          r={delSize}
          style={{ fill: path.style.stroke }}
        />
        <line
          x1={bbX - crossLineSize}
          y1={bbY - crossLineSize}
          x2={bbX + crossLineSize}
          y2={bbY + crossLineSize}
          style={{ stroke: 'white', strokeWidth: 2 }}
        />
        <line
          x1={bbX + crossLineSize}
          y1={bbY - crossLineSize}
          x2={bbX - crossLineSize}
          y2={bbY + crossLineSize}
          style={{ stroke: 'white', strokeWidth: 2 }}
        />
        <circle
          className="path_delete transparent"
          onMouseDown={(e: React.MouseEvent<SVGCircleElement, MouseEvent>) =>
            this.deletePath(pathId, e)
          }
          onTouchStart={(e: React.TouchEvent<SVGCircleElement>) =>
            this.deletePath(pathId, e)
          }
          cx={bbX}
          cy={bbY}
          r={delSize}
        />
      </g>
    )
  }

  renderRotate = (path: RlassoPath, pathId) => {
    const delSize = 9
    const bbX = path.bboxX + path.bboxWidth / 2
    const bbY = path.bboxY + path.bboxHeight / 2
    const crossRadius = delSize * (2 / 3)

    return (
      <g>
        {['⟳', '⟲'].map((sign: string, index: number) => (
          <text
            key={sign + index}
            onMouseDown={(e: React.MouseEvent<SVGTextElement, MouseEvent>) =>
              this.startRotate(
                pathId,
                e,
                index === 0 ? 'clockwise' : 'anticlockwise',
              )
            }
            onTouchStart={(e: React.TouchEvent<SVGTextElement>) =>
              this.startRotate(
                pathId,
                e,
                index === 0 ? 'clockwise' : 'anticlockwise',
              )
            }
            x={bbX + (index === 0 ? 20 : -20)}
            y={bbY + 4}
            fill={'#fff'}
            style={{
              fontWeight: 'bold',
              fontSize: 18,
              cursor: 'pointer',
            }}
            textAnchor="middle"
          >
            {sign}
          </text>
        ))}
      </g>
    )
  }

  renderPathControls(pathId) {
    const { paths } = this.state
    const currentPath = paths[pathId]

    if (!currentPath.isCreated) return

    let del: React.ReactNode = ''
    let rotate: React.ReactNode = ''

    if (currentPath.showDelete) {
      del = this.renderCrossDelete(currentPath, pathId)
    }

    if (currentPath.showDelete) {
      rotate = this.renderRotate(currentPath, pathId)
    }

    if (currentPath.label !== this.props.label) {
      return del
    }

    const xResize = Math.min(10, currentPath.width / 10)
    const yResize = Math.min(10, currentPath.height / 10)
    const xyResize = Math.min(xResize, yResize)

    const resizeControls = (
      <g>
        <line
          onMouseDown={(e) => this.resizeRect(pathId, 'top', e)}
          onTouchStart={(e) => this.resizeRect(pathId, 'top', e)}
          x1={currentPath.path[0].x}
          y1={currentPath.path[0].y}
          x2={currentPath.path[1].x}
          y2={currentPath.path[1].y}
          stroke="transparent"
          strokeWidth="4px"
          className="n-resize"
        />
        <line
          onMouseDown={(e) => this.resizeRect(pathId, 'right', e)}
          onTouchStart={(e) => this.resizeRect(pathId, 'right', e)}
          x1={currentPath.path[1].x}
          y1={currentPath.path[1].y}
          x2={currentPath.path[2].x}
          y2={currentPath.path[2].y}
          stroke="transparent"
          strokeWidth="4px"
          className="e-resize"
        />
        <line
          onMouseDown={(e) => this.resizeRect(pathId, 'bottom', e)}
          onTouchStart={(e) => this.resizeRect(pathId, 'bottom', e)}
          x1={currentPath.path[2].x}
          y1={currentPath.path[2].y}
          x2={currentPath.path[3].x}
          y2={currentPath.path[3].y}
          stroke="transparent"
          strokeWidth="4px"
          className="s-resize"
        />
        <line
          onMouseDown={(e) => this.resizeRect(pathId, 'left', e)}
          onTouchStart={(e) => this.resizeRect(pathId, 'left', e)}
          x1={currentPath.path[3].x}
          y1={currentPath.path[3].y}
          x2={currentPath.path[0].x}
          y2={currentPath.path[0].y}
          stroke="transparent"
          strokeWidth="4px"
          className="w-resize"
        />
      </g>
    )

    const points = currentPath.path.map((point, index) => {
      const defaultRadius = 5

      const pt = (
        <g key={pathId + index}>
          <circle
            className={`point${currentPath.isCreated ? ' close' : ''}`}
            onTouchStart={this.movePoint.bind(this, pathId, index)}
            onMouseDown={this.movePoint.bind(this, pathId, index)}
            cx={point.x}
            cy={point.y}
            r={defaultRadius}
            style={{ fill: currentPath.style.stroke }}
            key={pathId + index}
          />
        </g>
      )
      return pt
    })
    return (
      <g>
        {resizeControls}
        {points}
        {del}
        {rotate}
      </g>
    )
  }

  moveRect = (
    pathId: string,
    e:
      | React.MouseEvent<SVGGElement, MouseEvent>
      | React.TouchEvent<SVGGElement>,
  ) => {
    const { paths } = this.state
    const movingRect: RlassoPath = paths[pathId]
    const { label } = this.props

    if (!label || movingRect.label !== label) {
      return
    }

    e.stopPropagation()
    e.preventDefault()

    // this.props.mouseDownHandler(true)

    // if (!movingRect.showControls) {
    //     this.switchRectControls(rectId, true)
    // }

    setNoScroll(true)
    // movingRect.showDelete = false
    // this.setState({ currentRectId: rectId })

    this.mousemove = (moveEvent) => {
      moveEvent.preventDefault()
      moveEvent.stopPropagation()

      movingRect.path[0].x = movingRect.path[0].x + moveEvent.movementX
      movingRect.path[0].y = movingRect.path[0].y + moveEvent.movementY
      movingRect.path[1].x = movingRect.path[1].x + moveEvent.movementX
      movingRect.path[1].y = movingRect.path[1].y + moveEvent.movementY
      movingRect.path[2].x = movingRect.path[2].x + moveEvent.movementX
      movingRect.path[2].y = movingRect.path[2].y + moveEvent.movementY
      movingRect.path[3].x = movingRect.path[3].x + moveEvent.movementX
      movingRect.path[3].y = movingRect.path[3].y + moveEvent.movementY

      this.fixPath(movingRect)
      paths[pathId] = movingRect
      this.setState({ paths })
      this.props.onPathsUpdate && this.props.onPathsUpdate(paths, null)
    }

    this.mouseup = this.handleMouseUp
  }

  resizeRect = (
    pathId: string,
    direction: 'top' | 'bottom' | 'left' | 'right',
    e:
      | React.MouseEvent<SVGGElement, MouseEvent>
      | React.TouchEvent<SVGGElement>,
  ) => {
    const { paths } = this.state
    const movingRect: RlassoPath = paths[pathId]

    const { label } = this.props

    if (!label || movingRect.label !== label) {
      return
    }

    e.stopPropagation()
    e.preventDefault()

    setNoScroll(true)
    movingRect.showDelete = false

    this.mousemove = (moveEvent) => {
      moveEvent.preventDefault()
      moveEvent.stopPropagation()

      if (direction === 'top') {
        movingRect.path[0].x = movingRect.path[0].x + moveEvent.movementX
        movingRect.path[0].y = movingRect.path[0].y + moveEvent.movementY
        movingRect.path[1].x = movingRect.path[1].x + moveEvent.movementX
        movingRect.path[1].y = movingRect.path[1].y + moveEvent.movementY
      }

      if (direction === 'right') {
        movingRect.path[1].x = movingRect.path[1].x + moveEvent.movementX
        movingRect.path[1].y = movingRect.path[1].y + moveEvent.movementY
        movingRect.path[2].x = movingRect.path[2].x + moveEvent.movementX
        movingRect.path[2].y = movingRect.path[2].y + moveEvent.movementY
      }

      if (direction === 'bottom') {
        movingRect.path[2].x = movingRect.path[2].x + moveEvent.movementX
        movingRect.path[2].y = movingRect.path[2].y + moveEvent.movementY
        movingRect.path[3].x = movingRect.path[3].x + moveEvent.movementX
        movingRect.path[3].y = movingRect.path[3].y + moveEvent.movementY
      }

      if (direction === 'left') {
        movingRect.path[3].x = movingRect.path[3].x + moveEvent.movementX
        movingRect.path[3].y = movingRect.path[3].y + moveEvent.movementY
        movingRect.path[0].x = movingRect.path[0].x + moveEvent.movementX
        movingRect.path[0].y = movingRect.path[0].y + moveEvent.movementY
      }

      this.fixPath(movingRect)
      paths[pathId] = movingRect
      this.setState({ paths })
      this.props.onPathsUpdate && this.props.onPathsUpdate(paths, null)
    }

    this.mouseup = this.handleMouseUp
  }

  renderPath(pathId: any) {
    const { paths } = this.state
    const path = paths[pathId]
    const current = path.label === this.props.label

    if (!path) return ''

    const { bboxWidth, bboxHeight, bboxX, bboxY, index, isCreated } = path
    const { stroke, strokeWidth, fontSize } = path.style

    return (
      <g
        onMouseEnter={this.switchPathControls.bind(this, pathId, true)}
        onMouseLeave={this.switchPathControls.bind(this, pathId, false)}
        key={pathId}
        className={current ? 'current' : ''}
        onMouseDown={(e) => this.moveRect(pathId, e)}
        onTouchStart={(e) => this.moveRect(pathId, e)}
        style={{ cursor: 'move' }}
      >
        <path d={path.svg.path} className="path" style={path.style} />/
        {/* 
                TODO: check error in console 
                <text
                    x={bboxX + bboxWidth / 2}
                    y={bboxY - strokeWidth + bboxHeight / 2 + fontSize / 2}
                    fill={stroke}
                    fontSize={fontSize}
                    style={{ display: isCreated ? 'block' : 'none' }}
                >
                    <tspan textAnchor="middle">{index + 1}</tspan>
                </text> */}
        {path.showControls ? this.renderPathControls(pathId) : ''}
        {this.renderPathControls(pathId)}
      </g>
    )
  }

  render() {
    const { imageUrl, t, zoomRatioPercentage } = this.props
    const { paths } = this.state

    const pathsElements = Object.keys(paths).map((id) => this.renderPath(id))

    const outerSize = {
      width: document.body.offsetWidth,
      height: document.body.offsetHeight,
    }

    return (
      <>
        <div className="core-middle" ref={this.props.coreMiddleRef}>
          <Button
            className="btn btn-dark-gray core-button-fullscreenClose"
            onClick={() => {
              this.props.handleButtonToggleFullscreen(1, this.setResolution)
            }}
          >
            <FullscreenCloseIcon />
          </Button>

          <div className="core-zoom">
            <Button
              className="btn btn-dark-gray core-button-zoom-in"
              onClick={() => {
                this.handleZoomButtonClick('up', this.setResolution)
              }}
              disabled={zoomRatioPercentage >= this.maxZoom}
            >
              <ZoomInIcon />
            </Button>

            <Button
              className="btn btn-dark-gray core-button-zoom-out"
              onClick={() => {
                this.handleZoomButtonClick('down', this.setResolution)
              }}
              disabled={zoomRatioPercentage <= this.minZoom}
            >
              <ZoomOutIcon />
            </Button>

            <span className="core-zoom-percentage">
              {`${Math.round(zoomRatioPercentage * 100)}%`}
            </span>
          </div>

          <div className="core-inner">
            <Scroller
              disabled={!this.props.isFullscreen}
              outerSize={outerSize}
              innerSize={this.props.innerFullscreenSize}
              className="core-scroller"
              fullscreenOffsetLeft={this.props.fullscreenOffsetLeft}
              fullscreenOffsetTop={this.props.fullscreenOffsetTop}
              scrollHeight={8}
            >
              <div
                className={this.props.container}
                ref={this.props.coreref}
                style={this.props.imageFullscreenSize}
              >
                <img
                  src={imageUrl}
                  ref={this.props.imageRef}
                  className="image-value image-value-with-bottoms"
                />

                <svg
                  className="svg-area"
                  onTouchStart={this.startCreatingBbox}
                  onMouseDown={this.startCreatingBbox}
                  onMouseUp={this.handleCanvasMouseup}
                  style={{
                    width:
                      (this.props.imageRef &&
                        this.props.imageRef.current &&
                        this.props.imageRef.current.width) ||
                      0,
                    height:
                      (this.props.imageRef &&
                        this.props.imageRef.current &&
                        this.props.imageRef.current.height) ||
                      0,
                  }}
                >
                  {pathsElements}
                </svg>

                <div className="svg-button-fullscreen">
                  <span
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                      this.props.handleButtonToggleFullscreen(
                        1,
                        this.setResolution,
                      )
                    }}
                  >
                    <FullscreenIcon />
                  </span>
                </div>
              </div>
            </Scroller>

            <TaskSourceControls
              buttonList={[
                {
                  position: 'right',
                  callback: this.undo,
                  content: t('task.button.undo'),
                },
                {
                  position: 'right',
                  callback: this.clear,
                  content: t('task.button.clear'),
                },
              ]}
            />
          </div>
        </div>
      </>
    )
  }
}
