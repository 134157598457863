import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { normalizeTimeDate, capitalize } from '../../utils/common'
import * as userActions from '../User/actions'
import './style.scss'
import Spinner from '../Spinner'

const from = new Date(2018, 8, 15)

export interface LogProps {
  t: any
  app: any
  user: any
  actions: any
}

export interface LogState {
  parsed: boolean
  stat: any[]
}

class Log extends Component<LogProps, LogState> {
  constructor(props) {
    super(props)

    this.state = {
      stat: [],
      parsed: false,
    }
  }

  componentDidMount() {
    if (this.props.user.me !== null && this.props.user.me.id !== undefined) {
      this.props.actions.getUserStat(this.props.user.me.id)
    } else {
      this.props.actions.fetchMe()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.user.me !== null &&
      this.props.user.me.id !== undefined &&
      nextProps.user.stat === undefined &&
      !nextProps.user.statsFetchProcess
    ) {
      this.props.actions.getUserStat(nextProps.user.me.id)
    }

    if (
      this.props.user.statsFetchProcess &&
      !nextProps.user.statsFetchProcess &&
      nextProps.user.stat
    ) {
      this.generateStats(nextProps.user.stat)
    }
  }

  generateStats(list) {
    const stat: any = []

    list.forEach((item) => {
      if (new Date(item.completed_at) > from) stat.push(item)
    })

    this.setState({ stat, parsed: true })
  }

  generateTable() {
    const { t } = this.props
    const { stat } = this.state

    if (!stat.length) {
      return (
        <p className="text-4-type-1 table__nodata">
          {t('recent_history.no_data')}
        </p>
      )
    }

    return (
      <Fragment>
        <div className="gap-1" />
        <div className="gap-half sm-hidden" />

        <div className="table table-wide">
          <div className="table-row table-row-header">
            <div className="col-1 text-5-type-2">
              {t('recent_history.field.task_id')}
            </div>

            <div className="col-1 text-5-type-2">
              {t('recent_history.field.status')}
            </div>

            <div className="col-1 text-5-type-2">
              {t('recent_history.field.paid')}
            </div>

            <div className="col-1 text-5-type-2">
              {t('recent_history.field.date')}
            </div>
          </div>

          {stat.map((item) => {
            const ts = normalizeTimeDate(item.completed_at)
            const dateTime: any[] = []

            if (ts.prefix) {
              const txt = capitalize(t(`common.days.${ts.prefix}`))

              dateTime.push(<span key={ts.prefix}>{txt}, </span>)
            } else {
              dateTime.push(
                <span key={`${item.id}-month-${ts.month}`}>
                  {capitalize(t(`common.months.${ts.month}`).substr(0, 3))}
                </span>,
                <span key={`${item.id}-day-${ts.day}`}> {ts.day}, </span>,
              )
            }

            dateTime.push(
              <span key={`${item.id}-hour-${ts.hours}`}>{ts.hours}</span>,
              <span key="separator">:</span>,
              <span key={`${item.id}-minute-${ts.minutes}`}>{ts.minutes}</span>,
            )

            return (
              <div
                key={`${item.id}-${item.completed_at}`}
                className="table-row table-row-body"
              >
                <div className="col-1 text-4-type-2 font-regular">
                  {item.id}
                </div>

                <div className="col-1 text-4-type-2 font-regular">
                  <span className={`color-status-${item.status}`}>
                    {t(`recent_history.status.${item.status}`)}
                  </span>
                </div>

                <div className="col-1 text-4-type-2 font-regular">
                  {item.reward}
                </div>

                <div className="col-1 text-4-type-2 font-regular color-grey">
                  {dateTime}
                </div>
              </div>
            )
          })}
        </div>
      </Fragment>
    )
  }

  render() {
    const { app, t } = this.props
    const { parsed } = this.state
    const { isMobile } = app

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-1 sm-hidden" />
          <div className="score-page col-4 col-sm-4 col-xs-2">
            {!parsed ? (
              <Spinner />
            ) : (
              <Fragment>
                <h1
                  className={`${
                    isMobile ? 'text-1-type-2' : 'heading-2-type-2'
                  } font-medium`}
                >
                  {t('recent_history.title')}
                </h1>

                {this.generateTable()}
              </Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, userActions), dispatch),
})

export default withTranslation()(
  connect((state) => state, mapDispatchToProps)(Log),
)
