import React, { Component, Fragment } from 'react'
import Combokeys from 'combokeys'
import config from '../../../../config'
import TaskInputLabelsButtons from '../../components/TaskInputLabelsButtons/TaskInputLabelsButtons'

import './style.scss'
import Bttn from '../../../Bttn'
import { isStudioMode } from 'env'

export interface LabelMultipleProps {
  tasks: any
  t: any
  task: any
  markTask: any
  skipTask: any
  testLabel?: React.ReactNode
  fullDescriptionPopup?: React.ReactNode
  caption?: React.ReactNode
  description?: React.ReactNode
}

class LabelMultiple extends Component<LabelMultipleProps, any> {
  combokeys: Combokeys.Combokeys
  groupTimeout: number | null

  constructor(props) {
    super(props)

    this.state = {
      activeValues: [],
    }

    this.combokeys = new Combokeys(document.documentElement)
    this.groupTimeout = null
  }

  componentDidMount() {
    this.setComboKeys()
    document.body.classList.add('tool-multilabel')
  }

  componentWillReceiveProps(next) {
    if (this.props.task.id !== next.task.id) {
      this.setState({ activeValues: [] })
    }
  }

  componentWillUnmount() {
    this.combokeys.detach()
    document.body.classList.remove('tool-multilabel')
  }

  setComboKeys() {
    const { skipTask } = this.props

    const { skip, complete, no_objects: noObjects } = config.hotKeys

    this.combokeys.bind(skip.key, () => {
      skipTask()
    })

    this.combokeys.bind(complete.key, () => {
      this.handleMark('complete')
    })

    this.combokeys.bind(noObjects.key, () => {
      this.handleMark('noobjects')
    })

    this.combokeys.bind(skip.key, () => {
      this.handleSkip()
    })
  }

  handleChooseCategory(newValue) {
    this.setState((prev) => {
      const activeValues = Object.assign([], prev.activeValues)

      if (activeValues.includes(newValue)) {
        activeValues.forEach((value, valuePosition) => {
          if (value === newValue) {
            activeValues.splice(valuePosition, 1)
          }
        })
      } else {
        activeValues.push(newValue)
      }

      return { activeValues }
    })
  }

  handleMark(status) {
    const { markTask } = this.props
    const { activeValues } = this.state

    if (
      (status === 'complete' && !activeValues.length) ||
      (status === 'noobjects' && activeValues.length)
    ) {
      this.setState({ marksError: true })
      return
    }

    if (status === 'noobjects') {
      markTask([], [], false)
    }

    if (status === 'complete') {
      markTask(activeValues, activeValues, false)
    }
  }

  handleSkip() {
    this.props.skipTask()
  }

  render() {
    const {
      t,
      tasks,
      markTask,
      skipTask,
      testLabel,
      caption,
      description,
      fullDescriptionPopup,
    } = this.props

    const skipButton =
      tasks.onboarding_status || isStudioMode ? (
        ''
      ) : (
        <span
          role="button"
          tabIndex={0}
          className="skip"
          onClick={() => skipTask()}
        >
          {t('task.button.skip')}
        </span>
      )

    return (
      <Fragment>
        <div className="annotation row">
          <div className="image-wrapper">
            <div className="image col-4 col-sm-4 col-xs-2">
              <img
                className="image-value"
                src={this.props.task.input.source[0]}
                alt=""
              />
            </div>
          </div>

          <div className="actions-wrapper">
            <div className="actions-outer">
              <div className="actions col-2 col-sm-4 col-xs-2">
                <div className="actions-inner">
                  {testLabel}
                  {caption}
                  {description}
                  <div className="gap-back sm-hidden" />
                  <div className="buttons-outer">
                    <TaskInputLabelsButtons
                      id={this.props.task.id}
                      groups={this.props.task.output.groups}
                      labels={this.props.task.output.labels}
                      labelsActive={this.state.activeValues}
                      labelClickHandler={this.handleChooseCategory.bind(this)}
                      error={this.state.marksError}
                      errorEffectFinished={() => {
                        this.setState({
                          marksError: false,
                        })
                      }}
                    />

                    <div className="buttons">
                      <Bttn
                        type="white-on-gray"
                        onClick={(e) => {
                          e.preventDefault()
                          this.handleMark('complete')
                        }}
                      >
                        {t('task.button.complete')}
                      </Bttn>

                      <Bttn
                        type="bordered"
                        onClick={(e) => {
                          e.preventDefault()
                          this.handleMark('noobjects')
                        }}
                      >
                        {t('task.button.not_applicable')}
                      </Bttn>
                    </div>
                  </div>
                  <div className="second-actions">{skipButton}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {fullDescriptionPopup}
      </Fragment>
    )
  }
}

export default LabelMultiple
