class Timer {
  startTime: number | null
  stopTime: number | null
  pausedTimes: any[]

  constructor() {
    this.startTime = null
    this.stopTime = null
    this.pausedTimes = []
  }

  getValue() {
    try {
      if (this.stopTime && this.startTime) {
        const fullTime = this.stopTime - this.startTime
        const intermidiate = this.pausedTimes
          .map((t) => t[1] - t[0])
          .reduce((a, b) => a + b, 0)
        const res = fullTime - intermidiate

        return res
      } else {
        return 9999999
      }
    } catch (e) {
      return 9999999
    }
  }

  pause() {
    const current = [+new Date()]
    const latest = this.pausedTimes.slice(this.pausedTimes.length - 1)

    if (latest.length === 2 || latest.length === 0) {
      this.pausedTimes.push(current)
    } else {
      console.warn("~~~ timer can't execute pause")
    }
  }

  play() {
    const current = +new Date()
    const latest = this.pausedTimes.splice(this.pausedTimes.length - 1)

    if (latest.length === 1) {
      latest.push(current)
      this.pausedTimes.push(latest)
    } else {
      console.warn("~~~ timer can't execute play")
    }
  }

  restart() {
    this.stop()
    this.start()
  }

  start() {
    this.stopTime = null
    this.pausedTimes = []
    this.startTime = +new Date()
  }

  stop() {
    this.stopTime = +new Date()
  }
}

export default Timer
