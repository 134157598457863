import React, { Component } from 'react'
import Combokeys from 'combokeys'
import { idx2color, isTouchDevice } from '../../../../utils/common'
import { fixPolygonDirection } from '../../../../utils/tasks'
import config from '../../../../config'
import TaskInputLabelsButtons from '../../components/TaskInputLabelsButtons/TaskInputLabelsButtons'
import { collectUniqIdxes } from '../../api'
import Core from './BboxExtendedCore/BboxExtendedCore'
import './style.scss'
import Effects from '../../../Effects'
import Bttn from '../../../Bttn'

const guideBodyClass = 'body-state-guide-open'
const guideLSKey = 'rect-lasso-guide-viewed'
const guidePopupId = 'guidePopupId'

const maxInnerFullscreenSize = 90000

export interface RlassoProps {
  t: any
  actions: any
  markTask: any
  skipTask: any
  tasks: any
  task: any
  testLabel?: React.ReactNode
  caption?: React.ReactNode
  description?: React.ReactNode
  fullDescriptionPopup?: React.ReactNode
  isFirstDescriptionShow: boolean
}

class BboxExtended extends Component<RlassoProps, any> {
  imageRef: React.RefObject<HTMLImageElement>
  coreMiddleRef: React.RefObject<any>
  combokeys: Combokeys.Combokeys

  constructor(props) {
    super(props)
    this.state = {
      paths: {},
      idx: null,
      category: null,
      existedIdxes: [],
      marksError: false,
      coreError: false,
      isFullscreen: false,
      isCanvasMouseDown: false,
      innerFullscreenSize: {
        width: maxInnerFullscreenSize,
        height: maxInnerFullscreenSize,
      },
      imageFullscreenSize: { width: 'auto', height: 'auto' },
      fullscreenOffsetLeft: 0,
      fullscreenOffsetTop: 0,
      zoomRatioPercentage: 1,
    }

    this.imageRef = React.createRef()
    this.coreMiddleRef = React.createRef()
    this.combokeys = new Combokeys(document.documentElement)
  }

  componentDidMount() {
    const { labels } = this.props.task.output

    this.setComboKeys()

    if (labels.length === 1) {
      this.handleChooseCategory(labels[0].value, 0)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { labels } = nextProps.task.output

    if (this.props.task.id !== nextProps.task.id) {
      this.setPaths(nextProps.task.userData)
    }

    if (this.state.isFullscreen) {
      this.handleZoomChange(this.state.zoomRatioPercentage)
    }

    if (labels.length === 1) {
      this.setState({ idx: 0, category: labels[0].value })
    }

    if (nextProps.isFirstDescriptionShow) {
      this.setState({ isFullscreen: false })
    }
  }

  componentWillUnmount() {
    this.combokeys.detach()
  }

  setComboKeys() {
    const { complete, no_objects: noObjects, skip } = config.hotKeys

    this.combokeys.bind(complete.key, () => {
      this.handleMark('complete')
    })

    this.combokeys.bind(noObjects.key, () => {
      this.handleMark('noobjects', {})
    })

    this.combokeys.bind(skip.key, () => {
      this.handleSkip()
    })
  }

  setPaths(paths) {
    this.setState({ paths: paths || {}, category: null, idx: null })
  }

  hideGuide() {
    document.body.classList.remove(guideBodyClass)
    this.props.actions.hidePopup()
    localStorage.setItem(guideLSKey, '1')
  }

  showGuide() {
    document.body.classList.add(guideBodyClass)
    this.props.actions.showPopup(guidePopupId)
  }

  handleChooseCategory = (newCategory, newIndex) => {
    const { idx, category, paths } = this.state
    const o: any = {}
    const pathKeys = Object.keys(paths)
    const parsedIndex = parseInt(newIndex, 10)

    // const isSomeOpened = pathKeys.some((k) => !paths[k].closed)

    // if (isSomeOpened) {
    //     return
    // }

    if (parsedIndex === idx && newCategory === category) {
      o.idx = null
      o.category = null
    } else {
      o.idx = parsedIndex
      o.category = newCategory
    }

    this.setState({ ...o })
  }

  handleMark(type: string, _paths?: any) {
    const paths = _paths || this.state.paths
    const { task } = this.props
    const { category } = this.state
    const payload = {}
    const existedIdxes = []

    if (type === 'complete' && Object.keys(paths).length === 0) {
      const errorType = category ? 'coreError' : 'marksError'

      this.setState({ [errorType]: true })

      return
    }

    if (type === 'noobjects' && Object.keys(this.state.paths).length !== 0) {
      this.setState({ marksError: true })
      return
    }

    task.output.labels.forEach((item) => {
      payload[item.value] = []
    })

    const fix = (x) => Math.min(Math.max(0, x), 1)

    Object.keys(paths).forEach((key) => {
      const path = paths[key]

      Object.keys(paths).forEach((_key) => {
        const _path = paths[_key]
        const coordinates = fixPolygonDirection(
          _path.path.map((point) => [fix(point.rx), fix(point.ry)]),
          false,
        )
      })

      const coordinates = fixPolygonDirection(
        path.path.map((point) => [fix(point.rx), fix(point.ry)]),
        true,
      )
      payload[path.label].push([coordinates.concat([coordinates[0]])])
    })

    this.setState({ existedIdxes })
    this.props.markTask(payload, paths)
  }

  handleSkip() {
    this.props.skipTask()
  }

  handlePathsUpdate(paths) {
    const existedIdxes = paths ? collectUniqIdxes(paths) : []
    this.setState({ paths, existedIdxes })
  }

  clearPaths() {
    const paths = {}
    const existedIdxes = []
    this.setState({ paths, existedIdxes })
  }

  // hiding all buttons on mouse down, i.e on change point
  handleCanvasMouseDown = (isCanvasMouseDown: boolean) => {
    if (isCanvasMouseDown !== this.state.isCanvasMouseDown) {
      this.setState({ isCanvasMouseDown })
    }
  }

  handleZoomChange(ratio: any, callback?: any) {
    const innerFullscreenSize: any = {}
    const imageFullscreenSize: any = {}

    let fullscreenOffsetLeft = 0
    let fullscreenOffsetTop = 0

    if (this.state.isFullscreen) {
      const { offsetWidth, offsetHeight } = document.body
      const { current } = this.imageRef

      if (current) {
        const currentWidth =
          (current.naturalWidth || current.offsetWidth) * ratio
        const currentHeight =
          (current.naturalHeight || current.offsetHeight) * ratio

        innerFullscreenSize.width = offsetWidth + currentWidth
        innerFullscreenSize.height = offsetHeight + currentHeight
        fullscreenOffsetLeft = currentWidth / 2
        fullscreenOffsetTop = currentHeight / 2
        imageFullscreenSize.width = currentWidth
        imageFullscreenSize.height = currentHeight
      }
    } else {
      innerFullscreenSize.width = maxInnerFullscreenSize
      innerFullscreenSize.height = maxInnerFullscreenSize
    }

    this.setState(
      {
        innerFullscreenSize,
        imageFullscreenSize,
      },
      () => {
        if (callback) callback()

        this.setState({
          fullscreenOffsetLeft,
          fullscreenOffsetTop,
        })
      },
    )
  }

  handleButtonToggleFullscreen(zoomRatioPercentage, fullscreenCallback) {
    this.setState(
      (prev) => ({
        isFullscreen: !prev.isFullscreen,
        zoomRatioPercentage,
      }),
      () => {
        this.handleZoomChange(zoomRatioPercentage, fullscreenCallback)
      },
    )
  }

  changeZoomRatio(zoomRatioPercentage, callback) {
    this.setState({ zoomRatioPercentage }, () => {
      this.handleZoomChange(zoomRatioPercentage, callback)
    })
  }

  generateMarkupButtons() {
    const { labels: values } = this.props.task.output
    const { category } = this.state

    const buttons = values.map((item, i) => {
      const idx = i + 1
      const existsClassName = this.state.existedIdxes.includes(idx)
        ? 'exists'
        : ''
      const pressedClassName = item.value === category ? 'pressed' : ''
      const text = isTouchDevice() ? item.text : `${idx}. ${item.text}`
      return (
        <Bttn
          key={idx}
          className={`${existsClassName} ${pressedClassName} rainbow`}
          onClick={(e) => {
            e.preventDefault()
            this.handleChooseCategory(idx, item.value)
          }}
        >
          {text}
        </Bttn>
      )
    })

    return (
      <Effects
        transition="shakeShort"
        transitionFinish={() => {
          this.setState({ marksError: false })
        }}
        init={this.state.marksError}
      >
        <div className="buttons marks">{buttons}</div>
      </Effects>
    )
  }

  generateExistedLabels() {
    const { paths } = this.state
    const rectsKeys = Object.keys(paths)
    const values: any[] = []

    rectsKeys.forEach((k) => {
      values.push(paths[k].label)
    })
    // @ts-ignore
    return [...new Set(values)]
  }

  renderFooterButtons = () => {
    const { t } = this.props

    return (
      <div className="buttons">
        <Bttn
          type="white-on-gray"
          onClick={(e) => {
            e.preventDefault()
            this.handleMark('complete')
          }}
        >
          {t('task.button.complete')}
        </Bttn>

        <Bttn
          type="bordered"
          onClick={(e) => {
            e.preventDefault()
            this.handleMark('noobjects', {})
          }}
        >
          {t('task.button.no_objects')}
        </Bttn>
      </div>
    )
  }

  renderSkipButton = () => {
    const {
      tasks: { onboarding_status },
      t,
    } = this.props

    return onboarding_status ? (
      ''
    ) : (
      <div className="second-actions">
        <span
          role="button"
          tabIndex={0}
          className="skip"
          onClick={() => this.handleSkip()}
        >
          {t('task.button.skip')}
        </span>
      </div>
    )
  }
  render() {
    const { task, testLabel, t, caption, description, fullDescriptionPopup } =
      this.props

    const { category, isFullscreen, isCanvasMouseDown } = this.state

    const buttonContainerDownClassName =
      isFullscreen && isCanvasMouseDown && category ? 'hide' : ''

    const lasso = (
      <div className="core-wrapper">
        <Effects
          transition="shakeShort"
          transitionFinish={() => {
            this.setState({ coreError: false })
          }}
          init={this.state.coreError}
        >
          <Core
            container="svg_container"
            t={t}
            imageUrl={task.input.source[0]}
            imageRef={this.imageRef}
            coreMiddleRef={this.coreMiddleRef}
            onPathsUpdate={this.handlePathsUpdate.bind(this)}
            clearPaths={this.clearPaths.bind(this)}
            label={this.state.category}
            index={this.state.idx}
            pathStyle={this.state.idx === null ? {} : idx2color(this.state.idx)}
            paths={this.state.paths}
            isFullscreen={isFullscreen}
            innerFullscreenSize={this.state.innerFullscreenSize}
            handleCanvasMouseDown={this.handleCanvasMouseDown}
            handleButtonToggleFullscreen={this.handleButtonToggleFullscreen.bind(
              this,
            )}
            imageFullscreenSize={this.state.imageFullscreenSize}
            fullscreenOffsetLeft={this.state.fullscreenOffsetLeft}
            fullscreenOffsetTop={this.state.fullscreenOffsetTop}
            zoomRatioPercentage={this.state.zoomRatioPercentage}
            changeZoomRatio={this.changeZoomRatio.bind(this)}
          />
        </Effects>
      </div>
    )

    const annotationFullscreenClassName = isFullscreen
      ? 'annotation-fullscreen'
      : ''

    const existedLabels = this.generateExistedLabels()

    return (
      <React.Fragment>
        <div className={`annotation row ${annotationFullscreenClassName}`}>
          <div className="image-wrapper">
            <div className="image col-4 col-sm-4 col-xs-2">{lasso}</div>
          </div>

          <div className="actions-wrapper">
            <div className="actions-outer">
              <div className="actions col-2 col-sm-4 col-xs-2">
                <div className="actions-inner">
                  {testLabel}
                  {caption}
                  {description}
                  <div className="gap-back sm-hidden" />
                  <div
                    className={`buttons-outer ${buttonContainerDownClassName}`}
                  >
                    <TaskInputLabelsButtons
                      id={this.props.task.id}
                      groups={this.props.task.output.groups}
                      labels={this.props.task.output.labels}
                      labelsExisted={existedLabels}
                      labelsActive={[this.state.category]}
                      labelClickHandler={this.handleChooseCategory}
                      error={this.state.marksError}
                      errorEffectFinished={() => {
                        this.setState({
                          marksError: false,
                        })
                      }}
                    />
                    {this.renderFooterButtons()}
                    {this.renderSkipButton()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {fullDescriptionPopup}
      </React.Fragment>
    )
  }
}

export default BboxExtended
