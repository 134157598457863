import React from 'react'
import { control } from 'react-validation'
import styles from './input.module.scss'
import classNames from 'classnames'
export interface InputProps {
  name?: string
  className?: string
  type: string
  placeholder?: string
  validations?: any[]
  error: React.ReactNode
  onChange: (val: any) => void
  value: any
  isLowConfidence: boolean
  isChanged?: boolean
  isUsed?: boolean
  label: string
  onHover: (hoveredItemName: string | null) => void;
  page_num?: number
  id?: string
}

export type InputState = any

class Input extends React.Component<InputProps, InputState> {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value || '',
    }
  }
  static defaultProps = {
    onHover: () => { },
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({
        value: nextProps.value || '',
      })
    }
  }

  handleChange(event) {
    if (this.props.onChange) {
      this.props.onChange(event)
    } else {
      this.setState({ value: event.target.value })
    }
  }

  render() {
    const { isChanged, isUsed, error, value, onChange, label, ...inputProps } =
      this.props
    const labelExists = label !== ''
    const isInvalid = isChanged && isUsed && error
    const input = (
      <input
        onMouseEnter={() => {
          return this.props.onHover(this.props.id)
        }}
        onMouseLeave={() => this.props.onHover(null)}
        {...inputProps}
        value={this.state.value}
        onChange={this.handleChange.bind(this)}
        className={classNames(
          this.props.className,
          isInvalid && 'is-invalid-input',
          styles.inputField,
          this.props.isLowConfidence && styles.danger
        )}
      />
    )

    const inputBlock = labelExists ? (
      <label >
        <div className={styles.inputBlock}>
          <div className={styles.inputLabel}>{label}</div>
          {input}
        </div>
      </label >
    ) : (
      input
    )

    return (
      <React.Fragment>
        {inputBlock}
        {isChanged && isUsed && error ? (
          <div className="field-error">{error}</div>
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

export default control(Input)
