import React, { useEffect, useRef } from 'react'
import style from './Bbox.module.scss'
import classNames from 'classnames'

export interface BboxProps {
  isActive?: boolean
  coordinates: number[] // [x1,y1,x2,y2]
  containerClientHeight: number
  containerClientWidth: number
  imgHeight: number
  imgWidth: number
  isEdited?: boolean
}

const Bbox = (props: BboxProps) => {
  const { containerClientHeight, containerClientWidth, imgHeight, imgWidth } = props
  const [rx1, ry1, rx2, ry2, rx3, ry3] = props.coordinates
  const targetRef = useRef<null | HTMLDivElement>(null);

  const width = Math.abs(
    containerClientWidth * rx2 - containerClientWidth * rx1,
  ) / imgWidth
  const height = Math.abs(
    containerClientHeight * ry1 - containerClientHeight * ry3,
  ) / imgHeight

  const offsetX = Math.round(containerClientWidth * rx1 / imgWidth)
  const offsetY = Math.round(containerClientHeight * ry1 / imgHeight)

  useEffect(() => {
    if (targetRef.current && props.isActive)
      targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [props.isActive]);

  return <div
    ref={targetRef}
    className={classNames(
      style.root,
      props.isActive && style.active,
      props.isEdited && style.edited
    )}
    style={{
      width,
      height,
      top: offsetY,
      left: offsetX,
    }}
  ></div>
}

export default Bbox
