import React, { Component } from 'react'
import { Trans } from 'react-i18next'
import config from '../../config'
import { isOsx } from '../../utils/common'
import './style.scss'

const os = isOsx() ? 'osx' : 'win'

export interface HelpProps {
  t: any
  isMobile: boolean
  actions: any
  help: any
}

export default class Help extends Component<HelpProps> {
  componentWillMount() {
    const { t, isMobile } = this.props
    this.props.actions.helpSetContent(helpContent(t, isMobile))
  }

  hide() {
    this.props.actions.helpHide()
  }

  render() {
    const help = this.props.help.help || ''
    return <div>{help}</div>
  }
}

const KeyButton = (o) => {
  const classNameSize = o.size
    ? `key-buttons-item__size-${o.size}`
    : 'key-buttons-item__size-1'

  return <div className={`key-buttons-item ${classNameSize}`}>{o.children}</div>
}

const KeyText = (o) => (
  <div className="key-buttons-text" {...o}>
    {o.children}
  </div>
)

const KeyTitle = (o) => (
  <span className="key-titles-item" {...o}>
    {o.children}
  </span>
)

const KeyGroup = (o) => <div className="key-group">{o.children}</div>

const helpContent = (t, isMobile) => (
  <React.Fragment>
    <h1
      className={`${isMobile} ? 'text-1-type-1' : 'heading-2-type-1' font-medium`}
    >
      <Trans i18nKey="guide.title" />
    </h1>

    <h2 className="text-3-type-1">
      <Trans i18nKey="guide.subtitle-1" />
    </h2>

    <div className="help-group">
      {Object.keys(config.hotKeys).map((key) => {
        const k = config.hotKeys[key]

        return (
          <KeyGroup key={k.value}>
            <div className="key-buttons">
              {k.view.map((view) =>
                view.type === 'button' ? (
                  <KeyButton key={view.text[os]} size={view.size}>
                    {view.text[os]}
                  </KeyButton>
                ) : (
                  <KeyText key={view.text}>
                    <Trans i18nKey={`hotkeys.${view.text}`} />
                  </KeyText>
                ),
              )}
            </div>

            <div className="key-titles">
              <KeyTitle key={k.value}>
                <Trans i18nKey={`hotkeys.${k.value}`} />
              </KeyTitle>
            </div>
          </KeyGroup>
        )
      })}
    </div>
  </React.Fragment>
)
