import React, { Component } from 'react'

import './style.scss'
import { Button } from '@dbrainio/shared-ui'
export interface TaskSourceControlsProps {
  buttonList: {
    position: 'right' | 'left'
    callback: (e: any) => void
    content: React.ReactNode
    classList?: string[]
    icon?: React.ReactNode
  }[]
}

class TaskSourceControls extends Component<TaskSourceControlsProps> {
  render() {
    console.log(this.props)
    const { buttonList } = this.props

    const left: JSX.Element[] = []
    const right: JSX.Element[] = []

    buttonList.forEach((b, idx) => {
      const element = (
        <Button
          key={idx}
          className={`link ${b.classList ? b.classList.join(' ') : ''}`}
          onClick={b.callback}
        >
          {b.icon || ''}
          {b.content}
        </Button>
      )

      if (b.position === 'left') left.push(element)
      if (b.position === 'right') right.push(element)
    })

    return (
      <div className="svg-bottom">
        <div className="links">
          <div className="links-left">{left}</div>
          <div className="links-right">{right}</div>
        </div>
      </div>
    )
  }
}

export default TaskSourceControls
