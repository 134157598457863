import React, { Component, Fragment } from 'react'
import CloseIcon from '../../../Icons/CloseIcon/CloseIcon'

import './style.scss'

export interface TextCategorizationFragmentProps {
  range: any
  text?: string
  id?: string
  index: number
  parent: any
  onRemove: any
}

class TextCategorizationFragment extends Component<
  TextCategorizationFragmentProps,
  any
> {
  fake: HTMLElement | null

  constructor(props) {
    super(props)

    this.state = {
      top: null,
      left: null,
    }
  }

  componentDidMount() {
    this.updatePosition()

    window.addEventListener('resize', this.updatePosition)
  }

  componentWillUnmount(): void {
    window.addEventListener('resize', this.updatePosition)
  }

  updatePosition() {
    if (this.fake) {
      const { top: fTop, left: fLeft } = this.fake.getBoundingClientRect()
      const { top: pTop, left: pLeft } = this.props.parent

      this.setState({
        top: fTop - pTop,
        left: fLeft - pLeft,
      })
    }
  }

  render() {
    const { text, index, id, range, onRemove } = this.props

    const { top, left } = this.state

    const normalizedIndex = index + 1

    return (
      <Fragment>
        <p className="text-value-range">
          {text && text.slice(0, range.start)}

          <span
            className={`text-value-range__selection text-value-range__idx-${normalizedIndex}`}
            style={{ border: '1px solid #000' }}
          >
            {text && text.slice(range.start, range.finish)}

            <strong
              className="text-value-range__closefaker"
              ref={(c) => {
                this.fake = c
              }}
            />
          </span>

          {text && text.slice(range.finish)}
        </p>

        <button
          className={`text-value-close text-value-close__idx-${normalizedIndex}`}
          style={{ top, left }}
          onClick={() => {
            onRemove(id)
          }}
        >
          <CloseIcon />
        </button>
      </Fragment>
    )
  }
}

export default TextCategorizationFragment
