import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Trans, withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import config from '../../config'
import history from '../../browserHistory'
import * as tasksActions from '../Task/actions'

import './style.scss'

const bodyClass = 'no-tasks-page'

export interface NoTasksProps {
  t: any
  lng?: string
  app: any
  actions: any
  tasks: any
}

class NoTasks extends Component<NoTasksProps> {
  componentDidMount() {
    document.body.classList.add(bodyClass)

    if (this.props.tasks.assignments.length) {
      history.push(config.routes.label)
    } else {
      this.props.actions.getTasks()
    }
  }

  componentWillReceiveProps(nextProps) {
    const { tasks } = this.props
    const { tasks: tasksNext } = nextProps

    if (tasksNext.assignments.length && !tasks.assignments.length) {
      history.push(config.routes.label)
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(bodyClass)
  }

  render() {
    const { t, app, lng } = this.props
    const { isMobile } = app

    const facebook = t('notasks.icon.facebook')
    const twitter = t('notasks.icon.twitter')

    return (
      <Fragment>
        <div className="row">
          <div className="col-1 sm-hidden" />

          <div className="score-page col-4 col-sm-4 col-xs-2">
            <h1
              className={
                isMobile
                  ? 'text-1-type-2 font-medium'
                  : 'heading-2-type-1 font-medium'
              }
            >
              {t('notasks.title')}
            </h1>

            <div className="description font-regular text-3-type-3">
              {/* <Trans
                i18nKey="notasks.description"
                // facebook={facebook}
                // twitter={twitter}
              >
                We are preparing new labeling tasks. When job is on, we notify
                in our Telegram chat. For more updates follow us on
                <a href={config.links.social.facebook}>{{ facebook }}</a>
                and
                <a href={config.links.social.twitter}>{{ twitter }}</a>.
              </Trans> */}
            </div>

            <div className="gap-1" />
            <div className="gap-half sm-hidden" />

            <div className="buttons">
              <Link className="btn" to='/'>
                {t('notasks.button.requestTask')}
              </Link>

              {/* <a
                className="btn btn-border-blue"
                href={config.links.telegram.chat[lng || 'ru']}
                target="_blank"
              >
                {t('notasks.button.join_tg')}
              </a> */}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, tasksActions), dispatch),
})
export default withTranslation()(
  connect((state) => state, mapDispatchToProps)(NoTasks),
)
