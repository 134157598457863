import React, { Component } from 'react'
import ReactDOM from 'react-dom'

const bodyClassName = 'overflow-hidden'

export interface PopupProps {
  children: React.ReactNode
  handleClose?: () => void
  id: string
  className?: string
  popup: any
  actions?: any
}

export default class Popup extends Component<PopupProps> {
  componentWillReceiveProps(nextProps: any) {
    if (nextProps.popup.isActive && !this.props.popup.isActive) {
      document.body.classList.add(bodyClassName)
    } else if (!nextProps.popup.isActive && this.props.popup.isActive) {
      document.body.classList.remove(bodyClassName)
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(bodyClassName)
  }

  close() {
    this.props.actions.hidePopup()
    if (typeof this.props.handleClose === 'function') {
      this.props.handleClose()
    }
  }

  render() {
    const el = document.querySelector('#popup-root')

    const { handleClose, className = '', popup, id } = this.props

    const isShowPopup = popup.isActive && popup.id === id

    if (!el) return null
    return ReactDOM.createPortal(
      <div
        className={`
					popup-container
					${isShowPopup ? 'popup-state-open' : ''}
				`}
      >
        <div className="popup-wrapper">
          <div className={`popup ${className}`}>
            <div className="popup-content">{this.props.children}</div>
          </div>
          {handleClose ? (
            <span
              role="button"
              tabIndex={0}
              className="icon icon-close"
              onClick={this.close.bind(this)}
            />
          ) : (
            ''
          )}
        </div>
      </div>,
      el,
    )
  }
}
