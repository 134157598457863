import { SHOW_REQUEST_ERROR } from '../Error/constants'
import { parseRequestError } from '../../utils/common'
import {
  assignmentsOutputAdapter,
  assignmentsInputAdapter,
} from '../../utils/adapters'

import * as api from './api'
import * as types from './constants'

function warmUpImages(tasksMap, dispatch) {
  tasksMap.forEach((task) => {
    if (
      task.output &&
      task.output.type === 'image' &&
      task.output.source.length
    ) {
      task.output.source.forEach((image) => {
        const i = new Image()

        i.onerror = () => {
          dispatch({
            type: types.FAIL_LOAD_TASK,
            id: task.id,
          })

          api.fail(task)
        }

        i.src = image
      })
    }
  })
}

const prepareAssignments = (assignments) =>
  assignments.map((assignment) => ({
    ...assignment,
    input: assignmentsInputAdapter(assignment.input),
    output: assignmentsOutputAdapter(assignment.output),
  }))

export const getTasks = () => (dispatch) => {
  dispatch({ type: types.GET_TASKS_REQUEST })

  return api
    .getTask()
    .then((data: any) => {
      const o = {
        assignments: [],
        expiration_timeout: null,
        onboarding_status: null,
      }

      if (data.status === 200 || data.isMock) {
        o.assignments = prepareAssignments(data.data.assignments)
        o.expiration_timeout = data.data.expiration_timeout
        o.onboarding_status = data.data.onboarding_status

        warmUpImages(data.data.assignments, dispatch)
      }

      dispatch({
        type: types.GET_TASKS_SUCCESS,
        isMock: data.data.is_mock || false,
        ...o,
      })
    })
    .catch((error) => {
      dispatch({
        type: types.GET_TASKS_ERROR,
      })

      dispatch({
        type: SHOW_REQUEST_ERROR,
        ...parseRequestError(error),
      })

      return true
    })
}

export const resetTasks = () => (dispatch) => {
  dispatch({
    type: types.RESET_TASKS,
  })
}

export const loadPrevTask = () => (dispatch) => {
  dispatch({
    type: types.LOAD_PREV_TASK,
  })
}

export const markTask = (task, payload, userData, skip, time) => (dispatch) => {
  dispatch({
    type: types.MARK_TASK_REQUEST,
    task,
    userData,
  })

  return api
    .mark(task, payload, skip, time)
    .then(() => {
      dispatch({
        type: types.MARK_TASK_SUCCESS,
      })
    })
    .catch((error) => {
      dispatch({
        type: SHOW_REQUEST_ERROR,
        ...parseRequestError(error),
      })
      return true
    })
}

export const normalizePoints = (array, selectedPath) => (dispatch) => {
  dispatch({
    type: types.POINTS_NORMALIZER_REQUEST,
  })

  api
    .normalizePoints(array)
    .then((response) => {
      const { data } = response

      if (data && data.statusCode !== undefined && data.statusCode === 200) {
        return data.body.points
      }

      throw new Error('')
    })
    .then((points) => {
      dispatch({
        type: types.POINTS_NORMALIZER_SUCCESS,
        normalizedPaths: { [selectedPath]: points },
      })
    })
    .catch(() => {
      dispatch({
        type: types.POINTS_NORMALIZER_FAILED,
      })
    })
}
