import React, { PureComponent } from 'react'

import './style.scss'

export interface TaskCaptionProps {
  text?: string
  classList: string[]
}

class TaskCaption extends PureComponent<TaskCaptionProps> {
  render() {
    const { text, classList } = this.props

    if (text === '') return ''

    const currentClassList = ['caption', ...classList].join(' ')

    return (
      <div className={currentClassList}>
        <p>{text}</p>
      </div>
    )
  }
}

export default TaskCaption
