import Tooltip from '../../../Tooltip'
import React, { Component } from 'react'
import IconFullDescription from '../../../Icons/HelpFilledIcon/HelpFilledIcon'

import Text from '../Text/Text'

import './style.scss'

export interface TaskDescriptionProps {
  text?: string
  classList?: string[]
  onMoreButtonClick?: (param: boolean) => void
  t: any
}
export interface TaskDescriptionState {
  isTooltipShown: boolean
}

class TaskDescription extends Component<
  TaskDescriptionProps,
  TaskDescriptionState
> {
  constructor(props) {
    super(props)

    this.state = {
      isTooltipShown: false,
    }
  }

  tooltipVisibility(isTooltipShown) {
    this.setState({ isTooltipShown })
  }

  generateMoreButton() {
    const { t } = this.props
    const { isTooltipShown } = this.state

    return (
      <Tooltip
        text={t('task.button.help')}
        position="bottom"
        show={isTooltipShown}
      >
        <span
          className="link fulldescrtiption-link"
          tabIndex={0}
          role="button"
          onClick={() => {
            this.props.onMoreButtonClick && this.props.onMoreButtonClick(true)
          }}
          onMouseEnter={() => {
            this.tooltipVisibility(true)
          }}
          onMouseLeave={() => {
            this.tooltipVisibility(false)
          }}
        >
          <span className="description-icon-full">
            <IconFullDescription />
          </span>
        </span>
      </Tooltip>
    )
  }

  render() {
    const { text, classList = [] } = this.props

    if (text === '') return ''

    return (
      <div className={classList.join(' ')}>
        <Text text={text} /> {this.generateMoreButton()}
      </div>
    )
  }
}

export default TaskDescription
